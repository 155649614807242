// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ActionMeta, SingleValue} from 'react-select';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {IconPhoto} from '@modules/Core/components/base/Icons/Icons';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {_SelectValue, Select} from '@modules/Core/components/base/inputs/Select';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {WYSIWYG} from '@modules/Core/components/base/inputs/WYSIWYG';
import {Typography} from '@modules/Core/components/base/Typography';
import {MultiImageUpload, UploadedImage} from '@modules/Core/components/base/uploads/MultiImageUpload';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {formatDateTime} from '@modules/Core/util/dates';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {isSuperAdmin} from '@modules/Core/util/rolesAndPermissionsUtil';
import {ADD_JOURNAL_XP_DUMMY_KEY} from '@modules/Gamification/config/constants';
import {getAllCategories} from '@modules/Profile/services/journalsApi';
import {_Journal} from '@modules/Profile/types/journal.model';
import {addNewJournal, updateJournal} from '@modules/Profile/util/journalUtil';
import ExperienceCount from '@modules/Statistics/components/general/ExperienceKeyCount';
import {useTeams} from '@modules/Team/hooks/teams';
import {trans} from '@modules/Translations/util/i18n';

interface _CreateJournalFormProps {
  isOpen: boolean;
  onClose: (confirmed?: boolean) => void;
  onUpdateJournals?: (selectedGroupCategory: string, selectedCategory: string | null) => void;
  selectedJournal?: _Journal | null;
  selectedGroupCategory?: string | null;
  teamId?: string | null;
  filterCategory?: string | null;
  selectedGroup?: string | null;
  metadata?: Record<string, any> | null;
  exerciseView?: boolean;
}

interface _ShortJournal {
  id: string;
  category: string;
  journal: string;
}

const CreateJournalForm: React.FC<_CreateJournalFormProps> = ({
  isOpen,
  onClose,
  onUpdateJournals,
  selectedJournal,
  selectedGroupCategory,
  teamId,
  filterCategory,
  selectedGroup,
  metadata,
  exerciseView,
}) => {
  const [teams] = useTeams();
  const snackbar = useSnackbar();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [journalCategories, setJournalCategories] = useState<Record<string, _ShortJournal[]> | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(selectedJournal?.category ?? null);
  const [selectedCategoryGroup, setSelectedCategoryGroup] = useState<string | null>(null);
  const [journalNote, setJournalNote] = useState<string | null>(selectedJournal?.journal ?? null);
  const [journalTitle, setJournalTitle] = useState<string>(selectedJournal?.title ?? '');
  const [categoryDisabled, setCategoryDisabled] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [targetUserEmail, setTargetUserEmail] = useState<string>('');
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);
  const isAdmin = isSuperAdmin();
  const [allowInsertImages, setAllowInsertImages] = useState<boolean>(false);
  const [shareWithTeam, setShareWithTeam] = useState<boolean>(false);

  useEffect(() => {
    void getJournalCategories();
    if (isOpen) {
      if (selectedJournal) {
        setSelectedCategory(selectedJournal.category);
        setSelectedCategoryGroup(selectedJournal.journal_group);
        setJournalNote(selectedJournal.journal ?? '');
        setJournalTitle(selectedJournal.title ?? '');
        setSelectedTeam(selectedJournal.team_id ?? '');
        // Set target user email if available in the journal metadata
        setTargetUserEmail(selectedJournal.metadata?.targetUserEmail || '');
        // Parse uploaded images from journal metadata if available
        if (selectedJournal.metadata?.images) {
          setUploadedImages(selectedJournal.metadata.images);
        } else {
          setUploadedImages([]);
        }
        // Set share with team if available in the journal metadata
        setShareWithTeam(selectedJournal.metadata?.shareWithTeam || false);
      } else {
        // Set default category to Personal Journal if no journal is selected
        setSelectedCategory('personal_journal');
        setSelectedCategoryGroup('personal');
        setJournalNote('');
        setJournalTitle('');
        setSelectedTeam('');
        setTargetUserEmail('');
        setUploadedImages([]);
        setShareWithTeam(false);
      }
    }
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clear the interval when the component is unmounted or the dialog is closed
    return () => clearInterval(intervalId);
  }, [isOpen, selectedJournal]);

  useEffect(() => {
    let selectedGroupName = '';
    let selectedCategoryName = '';
    if (selectedGroupCategory) {
      const selectedGroupCategoryNames = selectedGroupCategory.split('.');
      selectedGroupName = selectedGroupCategoryNames[0];
      selectedCategoryName = selectedGroupCategoryNames[1] !== 'undefined' ? selectedGroupCategoryNames[1] : '';
      setSelectedCategory(selectedCategoryName);
      setSelectedCategoryGroup(selectedGroupName);
    }
    if (teamId) {
      setSelectedTeam(teamId);
    }
    if (filterCategory) {
      setSelectedCategory(filterCategory);
    }
    if (selectedGroup) {
      if (!filterCategory) {
        setSelectedCategory('');
      }
      setSelectedCategoryGroup(selectedGroup);
    }
    // We disable ONLY when metadata and a filter are present.
    // As metadata is strictly connected to the journal category, we need to make sure that the category is selected.
    setCategoryDisabled(Boolean(selectedJournal || selectedCategoryName) && Boolean(metadata));
  }, [selectedGroupCategory, journalCategories, teamId, filterCategory, selectedGroup, selectedJournal]);

  async function getJournalCategories(): Promise<void> {
    try {
      const response = await getAllCategories();
      setJournalCategories(response?.data as Record<string, _ShortJournal[]>);
    } catch (err) {
      console.log('Error:', err);
    }
  }

  const handleCategoryChange = (event: SingleValue<_SelectValue>): void => {
    if (!event) return;
    setSelectedCategory(String(event.value));
    setSelectedTeam('');
    const groupValue = (event as unknown as {group?: string}).group;
    setSelectedCategoryGroup(groupValue ?? '');
  };

  const handleTeamChange = (event: SingleValue<_SelectValue>): void => {
    if (!event) return;
    setSelectedTeam(String(event.value));
  };

  const handleNoteChange = (event: React.ChangeEvent<{value: string}>): void => {
    setJournalNote(event.target.value);
  };

  const handleImagesUploaded = (images: UploadedImage[]): void => {
    // This function will be called both when new images are added and when existing images are removed
    setUploadedImages(images);
  };

  // Helper function to insert an image into the WYSIWYG editor
  const insertImageIntoEditor = (imageUrl: string): void => {
    const imageHtml = `<img src="${imageUrl}" alt="Journal image" data-journal-image="true" style="max-width: 100%; margin: 10px 0;" />`;
    setJournalNote(prevNote => (prevNote ? prevNote + imageHtml : imageHtml));
  };

  async function addNote(submitData: Record<string, string>): Promise<void> {
    const journalMetadata = {
      ...metadata,
      images: uploadedImages,
      shareWithTeam,
    };

    const success = await addNewJournal(
      submitData.selectedCategory,
      submitData.selectedTeam,
      submitData.journalNote,
      journalMetadata,
      selectedCategoryGroup ?? '',
      submitData.journalTitle,
      isAdmin ? submitData.targetUserEmail : undefined
    );

    if (success) {
      onClose?.(true);
      if (onUpdateJournals && selectedCategoryGroup) {
        onUpdateJournals(selectedCategoryGroup, selectedCategory);
      }
      snackbar.success(trans(exerciseView ? 'journal.journal_exercises.success' : 'journal.success'));
      reloadContentEngine();
    }
  }

  async function updateNote(submitData: Record<string, string>): Promise<void> {
    if (!selectedJournal) {
      return;
    }

    // Prepare metadata with uploaded images and target user email if admin
    const updatedMetadata = {
      ...selectedJournal.metadata,
      images: uploadedImages,
      shareWithTeam,
      ...(isAdmin && submitData.targetUserEmail ? {targetUserEmail: submitData.targetUserEmail} : {}),
    };

    const success = await updateJournal(
      selectedJournal.id,
      submitData.journalNote,
      submitData.selectedCategory,
      submitData.journalTitle,
      updatedMetadata,
      submitData.selectedTeam
    );
    if (success) {
      onClose?.(true);
      if (onUpdateJournals && selectedCategoryGroup) {
        onUpdateJournals(selectedCategoryGroup, selectedCategory);
      }
      snackbar.success(trans('journal.updated'));
    }
  }

  const handleSubmit = (): void => {
    if (!selectedCategory || !journalNote) return;
    const submitData = {
      selectedCategory,
      selectedTeam: selectedTeam ?? '',
      journalNote,
      journalTitle,
      targetUserEmail,
    };
    if (selectedJournal) {
      void updateNote(submitData);
    } else {
      void addNote(submitData);
    }
  };

  return (
    <Dialog
      open={isOpen}
      size="lg"
      onClose={onClose}
      onCancel={onClose}
      dusk="journal_form_component"
      title={selectedJournal ? trans('journal.edit_title') : trans('journal.add_title')}
      titleSuffix={<ExperienceCount xpKey={ADD_JOURNAL_XP_DUMMY_KEY} />}
      showCloseButton
      footerVerticalAlign="center"
      buttons={[
        {
          label: selectedJournal ? trans('journal.edit_btn') : trans('journal.add_btn'),
          variant: 'primary',
          onClick: handleSubmit,
          disabled: !selectedCategory || !journalNote || (selectedCategoryGroup?.startsWith('team_') && !selectedTeam),
        },
      ]}
      footerElements={
        <Typography className="justify-start" variant="body">
          {formatDateTime(currentTime)}
        </Typography>
      }
    >
      {isAdmin && false && (
        <>
          <Input
            label={trans('journal.target_user_email')}
            placeholder={trans('journal.enter_target_user_email')}
            value={targetUserEmail}
            onChange={(value: string) => setTargetUserEmail(value)}
            helpLabel={trans('roles.only_for_super_admin')}
          />
          <Input
            label={trans('journal.team_id')}
            placeholder={trans('journal.enter_team_id')}
            value={selectedTeam ?? ''}
            onChange={(value: string) => setSelectedTeam(value)}
            helpLabel={trans('roles.only_for_super_admin')}
          />
        </>
      )}
      <Select
        options={Object.entries(journalCategories ?? {})
          .map(([group, groupCategories]) =>
            groupCategories.map(category => ({
              value: category.category,
              group,
              label: `${trans(`journal.groups.${group}`)} - ${trans(`journal.categories.${group}.${category.category}`)}`,
            }))
          )
          .reduce((acc, val) => acc.concat(val), [])}
        onChange={handleCategoryChange}
        value={
          selectedCategory
            ? {
                value: selectedCategory,
                label: `${trans('journal.groups.personal')} - ${trans(`journal.categories.personal.${selectedCategory}`)}`,
              }
            : undefined
        }
        disabled={categoryDisabled}
        label={trans('journal.category_label')}
        placeholder={trans('journal.select_category')}
      />
      {selectedCategoryGroup?.startsWith('team_') && (
        <>
          <Select
            label={trans('journal.team_filter_label')}
            placeholder={trans('journal.select_team')}
            options={teams?.map(team => {
              return {
                value: team.id,
                label: team.name,
              };
            })}
            onChange={handleTeamChange}
            value={
              selectedTeam
                ? {
                    value: selectedTeam,
                    label: teams?.find(team => team.id === selectedTeam)?.name,
                  }
                : undefined
            }
          />
          {selectedTeam && (
            <div className="-mt-1">
              <CheckBox
                active={shareWithTeam}
                onChange={setShareWithTeam}
                label={trans('journal.share_with_team')}
                color="dark"
              />
            </div>
          )}
        </>
      )}
      <Input
        label={trans('journal.title_label')}
        placeholder={trans('journal.enter_title')}
        value={journalTitle}
        onChange={(value: string) => setJournalTitle(value)}
      />
      <div className="w-full">
        <WYSIWYG
          id="journalNote"
          label={trans('base.message')}
          value={journalNote}
          onChange={setJournalNote}
          dusk="journalNote"
        />
      </div>

      {/* Image Upload Section */}
      <div>
        <MultiImageUpload
          onImagesUploaded={handleImagesUploaded}
          maxFiles={10}
          showPreview
          previewSize="medium"
          className="mb-4"
          initialImages={uploadedImages}
        />

        {/* Quick Insert Buttons for Images */}
        {allowInsertImages && uploadedImages.length > 0 && (
          <div className="mt-4">
            <Typography variant="body2" bold className="mb-2">
              {trans('upload.journal_insert_images')}
            </Typography>
            <div className="flex flex-wrap gap-2">
              {uploadedImages.map((image, index) => (
                <button
                  key={image.id}
                  type="button"
                  onClick={() => insertImageIntoEditor(image.original)}
                  className="px-3 py-1.5 bg-blue100 text-blue700 rounded-md hover:bg-blue200 transition-colors flex items-center gap-1"
                >
                  <IconPhoto size="sm" />
                  {trans('upload.journal_insert_image')} #{index + 1}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default CreateJournalForm;
