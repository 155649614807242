// @ts-nocheck
/* eslint-disable */

import React from 'react';
import ProfileJournals from '@modules/Profile/components/journals/ProfileJournals';

interface ProfileJournalItemProps {
  category?: string;
  view?: string;
  showEntriesForOthers?: boolean;
}

export const ProfileJournalItem: React.FC<ProfileJournalItemProps> = ({
  category,
  view,
  showEntriesForOthers = false,
}) => <ProfileJournals filterCategory={category} view={view} showEntriesForOthers={showEntriesForOthers} />;
