// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {usePathname} from 'next/navigation';
import {isActiveRoute} from '@app/_core/util/routesHelper';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconTaskCompleter} from '@modules/Core/components/base/Icons/Icons';
import {logger} from '@modules/Core/util/Logger';
import {PAYMENT_FLOW} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {useProfile} from '@modules/Profile/hooks/profile';
import {hasActiveMembership} from '@modules/Profile/util/membershipsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _UpgradePlanButtonProps {
  id?: string;
  label?: string;
  withIcon?: boolean;
  width?: string;
  onClick?: () => void;
  source?: 'profile' | 'institution' | null;
  size?: 'xs' | 'sm';
}

const UpgradePlanButton: React.FC<_UpgradePlanButtonProps> = ({
  id,
  label,
  withIcon = true,
  onClick,
  width = 'relative-col-width-3',
  source = 'institution',
  size = 'sm',
}) => {
  const navigateToFlow = useNavigateToFlow();
  const {currentProfile} = useProfile();
  const [hasPaidPlan, setHasPaidPlan] = useState(false);
  const calculated = useRef<boolean>(false);
  const pathname = usePathname();

  useEffect(() => {
    setHasPaidPlan(hasActiveMembership(currentProfile, source));
    calculated.current = true;
    logger.debug('UpgradePlanButton: Rendering', {currentProfile, source, hasPaidPlan});
  }, [currentProfile, source]);

  const handleCLick = (): void => {
    if (onClick) {
      onClick();
      return;
    }
    navigateToFlow(PAYMENT_FLOW);
  };

  if (isActiveRoute(pathname, PAYMENT_FLOW)) {
    return null;
  }

  if (hasPaidPlan || !currentProfile || !calculated.current) {
    return null;
  }

  return (
    <Button
      id={id}
      onClick={handleCLick}
      variant="brand"
      width={width}
      iconElement={withIcon && <IconTaskCompleter size="md" color="fill-white500" />}
      size={size}
    >
      {label ?? trans('subscription.upgrade_plan')}
    </Button>
  );
};

export default UpgradePlanButton;
