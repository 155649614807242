// @ts-nocheck
/* eslint-disable */

import React, {ReactElement, ReactNode, useContext, useRef} from 'react';
import {Chip} from '@modules/Core/components/base/Chip';
import {IconCancel} from '@modules/Core/components/base/Icons/Icons';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Typography} from '@modules/Core/components/base/Typography';
import {trans} from '@modules/Translations/util/i18n';
import DatePickerContent from './DatePickerContent';
import DatePickerProvider, {DatePickerContext} from './DatePickerProvider';
import {IOptions} from './options';

export interface IDatePickerProps {
  dates?: Date[];
  setDates?: (dates: Date[]) => void;
  children?: ReactElement | ReactNode;
  options?: IOptions;
  show: boolean;
  setShow: (show: boolean) => void;
  classNames?: string;
  description?: string;
  showFooter?: boolean;
}

const DatePicker = ({
  description,
  dates,
  children,
  options,
  setDates,
  classNames,
  show,
  setShow,
  showFooter,
}: IDatePickerProps): JSX.Element => (
  <DatePickerProvider options={options} dates={dates} setDates={setDates} show={show} setShow={setShow}>
    <DatePickerMain description={description} showFooter={showFooter}>
      {children}
    </DatePickerMain>
  </DatePickerProvider>
);

const DatePickerMain = ({
  description,
  children,
  showFooter = true,
}: {
  description?: string;
  children?: ReactElement;
  showFooter?: boolean;
}): JSX.Element => {
  const {selectedDates, setSelectedDates} = useContext(DatePickerContext);
  const DatePickerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="flex flex-row gap-3 w-full">
        <DatePickerContent ref={DatePickerRef} />
      </div>
      {showFooter && (
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-row justify-between items-center">
            <Typography variant="body1">{trans('calendar.your_selection')}</Typography>
            {Boolean(selectedDates?.length > 0) && (
              <TextLink
                color="default"
                label={trans('calendar.clear_selection')}
                onClick={() => setSelectedDates([])}
              />
            )}
          </div>
          <Typography variant="body" className="text-typography-caption mb-1">
            {description ?? trans('calendar.add_to_calendar_description')}
          </Typography>
          <div className="flex flex-row gap-1 flex-wrap">
            {selectedDates.map((date, index) => {
              return (
                <Chip
                  icon={IconCancel}
                  onIconClick={() => {
                    const newDates = selectedDates.filter((_, i) => i !== index);
                    setSelectedDates(newDates);
                  }}
                  size="sm"
                  label={date.toDateString()}
                  key={index}
                />
              );
            })}
          </div>
          {children}
        </div>
      )}
    </>
  );
};

export default DatePicker;
