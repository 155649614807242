// @ts-nocheck
/* eslint-disable */

import {SERVICE_CUSTOM_SURVEY} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {isArray} from '@modules/Core/util/typesUtil';
import {
  SERVICE_STATUS_FINISHED,
  SERVICE_STATUS_NOT_READY,
  SERVICE_STATUS_READY,
  SERVICE_STATUS_STARTED,
} from '@modules/Profile/types/modules.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {currentProfile, isSoftfact} from '@modules/Profile/util/profileUtil';
import {appState} from '@modules/State/util/util';
import {currentTeam} from '@modules/Team/util/teamUtil';
import {trans} from '@modules/Translations/util/i18n';

export function getCurrentServiceRound(service, profile = null) {
  const foundProfile = profile ?? currentProfile();
  logger.debug('getCurrentServiceRound', {
    service,
    foundProfile,
    servicestatuses: foundProfile?.data?.serviceStatuses[service],
  });
  return (foundProfile?.data?.serviceStatuses || {})[service]?.current_round ?? 0;
}

// TODO @Sherif types
function getServiceStatus(service, profile = null) {
  const foundProfile = profile ?? currentProfile();
  if (!foundProfile) {
    return null;
  }
  return (foundProfile?.data?.serviceStatuses || {})[service];
}

export function isServiceDoneV2(service, profile = null) {
  const serviceStatus = getServiceStatus(service, profile);
  return serviceStatus?.status === SERVICE_STATUS_FINISHED;
}

export function isServiceReadyV2(service, profile = null) {
  const serviceStatus = getServiceStatus(service, profile);
  return serviceStatus?.status === SERVICE_STATUS_READY;
}

export function isServiceStartedV2(service, profile = null) {
  const serviceStatus = getServiceStatus(service, profile);
  return serviceStatus?.status === SERVICE_STATUS_STARTED;
}

export function isServiceNotReadyV2(service, profile = null) {
  const serviceStatus = getServiceStatus(service, profile);

  return !serviceStatus?.status || serviceStatus?.status === SERVICE_STATUS_NOT_READY;
}

// @TODO @Sherif support all survey types
export function isSurveyDone(type, params = {}) {
  const profile = currentProfile();
  const currentTeamId = 'teamId' in params ? params.teamId : currentTeam()?.id;

  const typeList = isArray(type) ? type : [type];

  if (profile) {
    let result = true;
    for (const type of typeList) {
      if (type?.startsWith('team_')) {
        result &&= !!profile.surveys[`${type}_${currentTeamId}`]?.finished;
        // TODO @Stefan ADD `third_person_feedback_done_${params['inviteId']}`
      } else if (type === SERVICE_CUSTOM_SURVEY) {
        result &&= !!profile.customSurveys.includes(type);
      } else {
        result &&= !!profile.services[type]?.done;
      }
    }
    return result;
  }

  return false;
}

export function hasStoredResults(type: string, params = {}) {
  const profile = currentProfile();
  const typeList = isArray(type) ? type : [type];

  if (profile) {
    let result = true;
    for (const type of typeList) {
      result &&= profile?.data?.storedResults?.[type]?.length > 0;
    }
    return result;
  }

  return false;
}

/**
 * If service is paid for
 * @param type
 * @param teamId
 * @returns {boolean}
 */
export function isServiceAvailable(type: string, teamId: string | null = null) {
  const profile = currentProfile();
  if (!isSoftfact(type)) {
    return profile?.subscriptionData?.services?.[type]?.included === true;
  }

  return profile?.data?.serviceStatuses?.[type]?.available;
}

// TODO(CLEANUP24) this should be isTeamProjectStarted. It is used only for team_development + ta
export function isServiceStarted(type: string, teamId: string | null = null) {
  const teams = appState().teams.currentTeams;

  if (teamId) {
    const team = (teams || []).find(team => team.id === teamId);
    // currently only team_development uses this
    if (team) {
      const teamDevProject = team?.runningProjects?.team_development;

      return teamDevProject?.started;
    }
  }
}

export function isServiceConsented(
  service: string,
  teamId: string | null = null,
  profile: _Profile | null = null
): boolean {
  const foundProfile = profile ?? currentProfile();

  const subscriptionServices = foundProfile?.subscriptionData?.services;

  if (!subscriptionServices) {
    return false;
  }

  if (teamId) {
    return !!subscriptionServices[service]?.consent?.[`${service}_team_${teamId}`];
  }

  return !!subscriptionServices[service]?.consent;
}

export function isServicePreconditionsFulfilled(service: string, teamId: string | null = null) {
  const profile = currentProfile();

  const subscriptionServices = profile?.subscriptionData?.services;

  if (!subscriptionServices) {
    return false;
  }

  let unfulfilledPreconditions = null;
  if (teamId) {
    unfulfilledPreconditions = subscriptionServices[service]?.unfulfilledPreconditions?.[`${service}_team_${teamId}`];
  } else {
    unfulfilledPreconditions = subscriptionServices[service]?.unfulfilledPreconditions;
  }

  return unfulfilledPreconditions?.length === 0;
}

export function getServicePreconditions(service: string, teamId = null) {
  const profile = currentProfile();

  const subscriptionServices = profile?.subscriptionData?.services;

  if (!subscriptionServices) {
    return false;
  }

  let conditions = null;
  if (teamId) {
    conditions = subscriptionServices[service]?.preConditions?.[`${service}_team_${teamId}`];
  } else {
    conditions = subscriptionServices[service]?.preConditions;
  }

  return conditions;
}

export function getUnfulfilledServicePreconditions(service: string, teamId: string | null = null) {
  const profile = currentProfile();

  const subscriptionServices = profile?.subscriptionData?.services;

  if (!subscriptionServices) {
    return false;
  }

  let unfulfilledPreconditions = null;
  if (teamId) {
    unfulfilledPreconditions = subscriptionServices[service]?.unfulfilledPreconditions?.[`${service}_team_${teamId}`];
  } else {
    unfulfilledPreconditions = subscriptionServices[service]?.unfulfilledPreconditions;
  }

  return unfulfilledPreconditions ?? [];
}

/**
 * If service is ready, meaning intro has been read and project created
 * @param type
 * @param teamId
 * @returns {boolean}
 */
export function isServiceReady(type: string, teamId: string | null = null) {
  const foundProfile = currentProfile();
  const teams = appState().teams.currentTeams;

  let inviteId = null;
  if (type == 'third_person_feedback') {
    inviteId = teamId;
    teamId = null;
  }

  if (teamId) {
    const team = (teams || []).find(team => team.id === teamId);
    // currently only team_development uses this
    if (team && (type === 'team_development' || type === 'team_reflection')) {
      const teamDevProject = team?.runningProjects?.[type];
      return !!teamDevProject?.ready;
    }
  }

  if (foundProfile) {
    switch (type) {
      case 'profile_potentials':
        return !!foundProfile.services?.[`${type}_${teamId}`]?.ready;
      case 'third_person_feedback':
        return !!foundProfile.services?.[`service_${type}_${inviteId}_ready`]?.ready;
      default:
        return !!foundProfile.services?.[type]?.ready;
    }
  }

  return true;
}

// Currently works just for softfacts
export function isServiceDone(type) {
  const profile = currentProfile();

  if (profile) {
    return !!profile.services?.[type]?.done;
  }

  return true;
}

export function isServiceReadyAndAvailable(type, teamId = null) {
  return isServiceAvailable(type, teamId) && isServiceReady(type, teamId);
}

export function isServiceStartedOrPreparedAndNotAvailable(service) {
  const isAvailable = isServiceAvailable(service);
  const isStarted = isServiceStartedV2(service);
  const isReady = isServiceReadyV2(service);

  return !isAvailable && (isStarted || isReady);
}

export function getSoftfactTitle(service: string) {
  if (!service) {
    return trans('base.soft_facts_menu_title');
  }
  return `${trans('base.soft_facts_menu_title')} - ${trans(`profile_modules.services.${service}.title`)}`;
}
