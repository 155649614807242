// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {flowRoute, navigateTo} from '@/_core/util/util';
import {_GlobalDialog} from '@modules/Core/components/base/GlobalDialog';
import {closeDialog, showDialog} from '@modules/Core/hooks/ui/dialog';
import {logger} from '@modules/Core/util/Logger';
import {Flow} from '@modules/FlowEngine/components/Flow';
import {_FlowInput} from '@modules/FlowEngine/types/core.model';

const DIALOG_FLOW_INSTANCE_KEY = 'popup';

export function toFlow(flowName: string, params: Record<string, any> = {}, origin?: string): void {
  const {teamId, ...paramsWithoutTeamId} = params;
  const route = flowRoute(flowName, teamId, paramsWithoutTeamId, origin);
  if (!route) {
    logger.error('Route not found for flow', flowName);
    return;
  }
  navigateTo(route);
}

export function flowPopup(flowName: string, params: Record<string, any> = {}): void {
  fireDialogFlow(flowName, null, {hideButtons: true, showCloseButton: true, size: 'lg'}, params);
}

export function fireDialogFlow(
  flowName: string,
  onFinish?: (() => void) | null,
  dialogProps: _GlobalDialog = {},
  flowInput: _FlowInput = {}
): string {
  const dialogId = showDialog({
    children: React.createElement(Flow, {
      flowName,
      instanceKey: DIALOG_FLOW_INSTANCE_KEY,
      onFinish: () => {
        closeDialog(dialogId);
        onFinish?.();
      },
      input: flowInput,
    }),
    ...dialogProps,
  });

  return dialogId;
}
