// @ts-nocheck
/* eslint-disable */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {flowRoute} from '@/_core/util/util';
import {AppInnerHTML} from '@modules/Core/components/wrappers/AppInnerHTML';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {getComponent} from '@modules/Core/services/api/compopnentApi';
import {handleHttpError} from '@modules/Core/util/errors';
import {addReactEventListener, dispatchReactEvent, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {extractContent, onFormSubmit, onWrapperClick} from '@modules/Core/util/wrapperUtil';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';

// TODO @Sherif types

interface _HtmlWrapperProps {
  type: string;
  isHomepage?: boolean;
  isProfile?: boolean;
  params?: string;
}

// TODO (Low Prio) Change type to path or url
const HtmlWrapper: React.FC<_HtmlWrapperProps> = ({type, isHomepage = false, isProfile = false, params = ''}) => {
  const [contentHtml, setContentHtml] = useState(null);
  const [reloadCount, setReloadCount] = useState(0);

  // const {loadRequest} = useLoader();

  const {currentLanguage} = useLanguage();
  const {navigate} = useAppNavigate();
  const snackbar = useSnackbar();

  const ref = useRef(null);

  const reloadHtmlListener = useCallback(e => {
    reloadHtml();
  });

  // TODO (Low Prio) @Stefan @Sherif Improve?
  useEffect(() => {
    addReactEventListener('reloadHtml', reloadHtmlListener);
    return () => {
      removeReactEventListener('reloadHtml', reloadHtmlListener);
    };
  }, []);

  useEffect(() => {
    // loadRequest(loadComponent);
    void loadComponent();
  }, [type, currentLanguage]);

  useEffect(() => {
    if (ref.current) {
      dispatchReactEvent('htmlReady', {target: ref.current});
    }
  }, [contentHtml]);

  function reloadHtml(): void {
    // TODO (Maybe) Use (current) url instead of type
    setReloadCount(new Date().getTime());
    console.log('reloadHtml', type, currentLanguage, reloadCount);
  }

  async function loadComponent(): Promise<void> {
    try {
      await getContent(type);
    } catch (e) {
      handleHttpError(e);
    }
  }

  async function getContent(type: string): Promise<void> {
    if (type) {
      const response = await getComponent(type + params);
      if (response?.data?.status === 'account-deleted') {
        // doLogout(trans('base.account_deleted'));
        return;
      }
      if (response?.data?.status === 'account-not-deleted') {
        snackbar.danger(trans('base.account_not_deleted'));
        navigate(flowRoute(FLOWS.ProfileDashboard));
        return;
      }

      if (response?.data?.message) {
        snackbar.success(response.data.message);
      }

      if (response?.data?.html) {
        setContentHtml(extractContent(response, isHomepage, isProfile));
      }

      if (response?.data?.redirect) {
        navigate(response.data.redirect);
      }
    }
  }

  async function doSubmitForm(event: any): Promise<any> {
    event.preventDefault();
    const {target} = event;
    const onSubmit = async () => {
      const result = await onFormSubmit(event, navigate, isHomepage, setUrl);
      if (result) {
        setContentHtml(result);
      } else {
        reloadHtml();
      }
    };
    if (target.dataset.presubmit) {
      window.onFormSubmit = onSubmit;
      return eval(target.dataset.presubmit);
    }
    await onSubmit();
  }

  return (
    <div ref={ref}>
      <AppInnerHTML
        key={reloadCount}
        onSubmit={async e => await doSubmitForm(e)}
        onClick={e => onWrapperClick(e, navigate)}
        html={contentHtml}
      />
    </div>
  );
};

export default HtmlWrapper;
