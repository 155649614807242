// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {useLoader} from '@modules/Core/hooks/state/loader';
import {addReactEventListener, ReactEventJournalUpdated, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {ListJournals} from '@modules/Profile/components/journals/ListJournals';
import {getAllJournals, getJournalGroups} from '@modules/Profile/services/journalsApi';
import {_Journal, _JournalFilterParams, _JournalGroup} from '@modules/Profile/types/journal.model';

interface ProfileJournalsProps {
  filterCategory?: string;
  exerciseView?: boolean;
  view?: string;
  showEntriesForOthers?: boolean;
}

const ProfileJournals: React.FC<ProfileJournalsProps> = ({
  filterCategory: initialFilterCategory,
  exerciseView = false,
  view = 'normal',
  showEntriesForOthers = false,
}) => {
  const {loadRequest} = useLoader();
  const [activeTab, setActiveTab] = useState<string>('all');
  const [journals, setJournals] = useState<Record<string, Record<string, _Journal[]>> | null>(null);
  const [journalGroups, setJournalGroups] = useState<_JournalGroup[] | null>(null);
  const [filterCategory, setFilterCategory] = useState<string | null>(initialFilterCategory ?? null);
  const [filterTeamId, setFilterTeamId] = useState<string | null>(null);

  useEffect(() => {
    setFilterCategory(initialFilterCategory ?? null);
    setFilterTeamId(null);
  }, [activeTab]);

  useEffect(() => {
    void loadRequest(getAllGroups);
    void loadRequest(getJournalsList);
    addReactEventListener(ReactEventJournalUpdated, refresh);

    return () => {
      removeReactEventListener(ReactEventJournalUpdated, refresh);
    };
  }, []);

  async function getJournalsList(filterParams: _JournalFilterParams): Promise<void> {
    try {
      if (filterParams) {
        setFilterCategory(filterParams?.filterCategory as string | null);
        setFilterTeamId(filterParams?.filterTeamId as string | null);
      }
      const response = await getAllJournals({
        filterCategory: filterParams ? (filterParams?.filterCategory as string | null) : filterCategory,
        filterTeamId: filterParams ? (filterParams?.filterTeamId as string | null) : filterTeamId,
        showEntriesForOthers,
      });
      setJournals((response?.data?.journals as Record<string, Record<string, _Journal[]>>) ?? {});
    } catch (err) {
      logger.error('Error:', err);
    }
  }

  async function getAllGroups(): Promise<void> {
    try {
      logger.info('Fetching all journal groups');
      const response = await getJournalGroups();
      setJournalGroups(response?.data as _JournalGroup[]);
    } catch (err) {
      logger.error('Error:', err);
    }
  }

  const refresh = (): void => {
    void loadRequest(getJournalsList);
  };

  return (
    <ListJournals
      journals={journals}
      refresh={refresh}
      activeTab={activeTab}
      journalGroups={journalGroups}
      filterCategory={filterCategory}
      filterTeamId={filterTeamId}
      setActiveTab={setActiveTab}
      getJournalsList={getJournalsList}
      exerciseView={exerciseView}
      view={view}
      showEntriesForOthers={showEntriesForOthers}
    />
  );
};

export default ProfileJournals;
