// @ts-nocheck
/* eslint-disable */

import {UploadedImage} from '@modules/Core/components/base/uploads/MultiImageUpload';
import {httpClient} from '@modules/Core/services/HttpClient';

const UPLOAD_MULTIPLE_IMAGES = 'images/upload-multiple';
const DELETE_IMAGE = 'images/delete';

/**
 * Upload multiple images to the server
 * @param formData FormData containing images with key 'images[]'
 * @returns Promise with uploaded image data
 */
export async function uploadMultipleImages(formData: FormData): Promise<{
  success: boolean;
  images: UploadedImage[];
  message?: string;
}> {
  try {
    const response = await httpClient.post(UPLOAD_MULTIPLE_IMAGES, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading images:', error);
    return {
      success: false,
      images: [],
      message: 'Failed to upload images',
    };
  }
}

/**
 * Delete an uploaded image
 * @param imageId ID of the image to delete
 * @returns Promise with deletion status
 */
export async function deleteImage(imageId: string): Promise<{
  success: boolean;
  message?: string;
}> {
  try {
    const response = await httpClient.post(DELETE_IMAGE, {imageId});
    return response.data;
  } catch (error) {
    console.error('Error deleting image:', error);
    return {
      success: false,
      message: 'Failed to delete image',
    };
  }
}
