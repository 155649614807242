// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {Switch} from '@modules/Core/components/base/inputs/Switch';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {Section} from '@modules/Core/components/layout/Section';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

interface FormField {
  name: string;
  type: string;
  placeholder?: string;
  label?: string;
  value?: string | boolean;
}

export const FormStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [formValues, setFormValues] = useState<Record<string, string | boolean>>({});
  const [formFields, setFormFields] = useState<FormField[]>([]);
  const {headline, headlineVariant, description, descriptionVariant, image} = options;

  // Parse options to get form fields
  const getFormFields = (): FormField[] => {
    const fields: FormField[] = [];
    const processedNames = new Set<string>();

    Object.entries(options).forEach(([key]) => {
      const [name, property] = key.split(':');

      if (property === 'type' && !processedNames.has(name)) {
        processedNames.add(name);
        fields.push({
          name,
          type: options[`${name}:type`],
          placeholder: options[`${name}:placeholder`],
          label: options[`${name}:label`],
          value: options[`${name}:value`],
        });
      }
    });

    return fields;
  };

  useEffect(() => {
    // Initialize form values
    const initialValues: Record<string, string | boolean> = {};
    const formFields = getFormFields();
    formFields.forEach(field => {
      initialValues[field.name] = field.value ?? '';
    });
    setFormFields(formFields);
    setFormValues(initialValues);
  }, [options?.flowName, options?.name, options?.loadStateDone]);

  const handleFieldChange = (name: string, value: string | boolean) => {
    setFormValues(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const getValuesCallback = (): Record<string, any> => formValues;

  const renderField = (field: FormField) => {
    switch (field.type.toLowerCase()) {
      case 'textarea':
        return (
          <TextArea
            key={field.name}
            value={(formValues[field.name] as string) || ''}
            onChange={e => handleFieldChange(field.name, e.target.value)}
            placeholder={field.placeholder}
            label={field.label}
          />
        );
      case 'input':
        return (
          <Input
            key={field.name}
            value={(formValues[field.name] as string) || ''}
            onChange={v => handleFieldChange(field.name, v)}
            placeholder={field.placeholder}
            label={field.label}
          />
        );
      case 'switch':
      case 'checkbox':
        return (
          <div key={field.name} className="flex justify-between">
            {field.label && <span>{field.label}</span>}
            <Switch
              active={!!formValues[field.name]}
              color="dark"
              onChange={() => handleFieldChange(field.name, !formValues[field.name])}
            />
          </div>
        );
    }
    return null;
  };

  return (
    <BaseStep getValueCallbackFn={getValuesCallback} inputs={Object.values(formValues)} options={options}>
      <Section
        title={headline}
        titleVariant={headlineVariant || 'h5'}
        description={description}
        descriptionVariant={descriptionVariant}
        image={image}
      >
        {formFields.map(field => renderField(field))}
      </Section>
    </BaseStep>
  );
};
