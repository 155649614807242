// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {useSelectState} from '@modules/State/hooks/selectState';
import {store} from '@modules/State/state/store';

export const FAB_FEATURE_FLAG = 'fab';

export function isFeatureEnabled(feature: string): boolean {
  const {flags} = store.getState().featureFlags;
  const result = flags?.includes(feature) ?? false;
  logger.info(`[FeatureFlags] Checking feature flag: ${feature}, flags: ${JSON.stringify(flags)},result: ${result}`);
  return result;
}

export function useFeatureFlags(): {isFeatureEnabled: (feature: string) => boolean; flags: string[] | undefined} {
  const flags = useSelectState(state => state.featureFlags.flags);

  const hookIsFeatureEnabled = (feature: string): boolean => {
    return isFeatureEnabled(feature);
  };

  return {
    isFeatureEnabled: hookIsFeatureEnabled,
    flags,
  };
}
