// @ts-nocheck
/* eslint-disable */

import {_Dialog} from '@modules/Core/components/base/Dialog';
import {_ActionsTableHeaderSelectValue} from '@modules/Core/components/base/table/actions/ActionsTableHeader';
import {useTableHeaderActions} from '@modules/Core/components/base/table/actions/tableHeaderActions';
import {Typography} from '@modules/Core/components/base/Typography';
import {SendNewEmailDialogBody} from '@modules/Core/components/platform/dialogs/SendNewEmailDialogBody';
import {MANAGE_TEAM_OWNERSHIP, MANAGE_TEAM_ROLES_PERMISSION} from '@modules/Core/config/rolesConstants';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {TEAM_ADMIN_ROLE, TEAM_MEMBER_ROLE, TEAM_OWNER_ROLE} from '@modules/Core/types/rolesAndPermissions.model';
import {fireConfirmationDialog} from '@modules/Core/util/dialogUtil';
import {showEmailDialog} from '@modules/Core/util/emailDialogHelper';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {can, hasRole, performChangeRoleAction} from '@modules/Core/util/rolesAndPermissionsUtil';
import {copyObject} from '@modules/Core/util/util';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_Profile} from '@modules/Profile/types/profile.model';
import {currentProfile} from '@modules/Profile/util/profileUtil';
import {refreshReduxState} from '@modules/State/util/util';
import {trans} from '@modules/Translations/util/i18n';

const dialogLanguageProps: Record<string, Record<string, string>> = {
  [TEAM_ADMIN_ROLE]: {
    title: 'roles.dialogs.team.promote_to_admin.title',
    message_singular: 'roles.dialogs.team.promote_to_admin.message_singular',
    message_plural: 'roles.dialogs.team.promote_to_admin.message_plural',
  },
  [TEAM_MEMBER_ROLE]: {
    title: 'roles.dialogs.team.change_to_team_member.title',
    message_singular: 'roles.dialogs.team.change_to_team_member.message_singular',
    message_plural: 'roles.dialogs.team.change_to_team_member.message_plural',
  },
  [TEAM_OWNER_ROLE]: {
    title: 'roles.dialogs.team.handover_ownership.title',
    message_self: 'roles.dialogs.team.handover_ownership.message_self',
    message_other: 'roles.dialogs.team.handover_ownership.message_other',
  },
};

export interface _ChosenProps {
  hasTeamOwners: boolean;
  hasTeamAdmins: boolean;
  hasTeamMembers: boolean;
  multipleSelected: boolean;
  multipleRolesSelected: boolean;
  hasSelection: boolean;
}

const defaultProps: _ChosenProps = {
  hasTeamOwners: false,
  hasTeamAdmins: false,
  hasTeamMembers: false,
  multipleSelected: false,
  multipleRolesSelected: false,
  hasSelection: false,
};

const NO_PERMISSION_ACTION = 'roles.errors.no_permission_action';
const HANDOVER_OWNERSHIP_DISABLED = 'roles.errors.handover_ownership_disabled';
const SELECT_SAME_ROLES_FOR_ACTION_TEAM = 'roles.errors.select_same_roles_for_action_team';
const SELECTED_USERS_ALREADY_HAVE_ROLE = 'roles.errors.selected_users_already_have_role';
const ONE_OWNER_NEEDED = 'roles.errors.one_owner_needed';

export const useTeamMembersRoleMgmtActions = (
  teamId: string,
  profiles: Record<string, _Profile>,
  setDialogConfig: (config: _Dialog) => void,
  setShowDialog: (show: boolean) => void
): {
  actions: _ActionsTableHeaderSelectValue[];
  chosenProfiles: string[];
  canSelectProfile: (profile: _Profile, currentSubscriptionProps?: _ChosenProps) => boolean;
  onProfileSelected: (profileId: string) => void;
  onClearSelectedProfiles: () => void;
  onSelectAllProfiles: () => void;
} => {
  const snackbar = useSnackbar();

  const getAllowedActions = (selectedProfilesParams: _ChosenProps): _ActionsTableHeaderSelectValue[] => {
    const options: _ActionsTableHeaderSelectValue[] = [];

    // if permission found, show content, otherwise, return null

    const canManageRoles = can(MANAGE_TEAM_ROLES_PERMISSION, {
      team_id: teamId,
    });

    const canTransferOwnership = can(MANAGE_TEAM_OWNERSHIP, {
      team_id: teamId,
    });

    const ownerSelected = selectedProfilesParams.hasTeamOwners;
    const multipleProfileSelected = selectedProfilesParams.multipleSelected;
    const onlyOwnerSelected = ownerSelected && !multipleProfileSelected;
    const hasNoPermission = !canManageRoles || (onlyOwnerSelected && !canTransferOwnership);

    options.push(transferOwnershipAction(selectedProfilesParams, canTransferOwnership));
    options.push(promoteToAdminAction(selectedProfilesParams, hasNoPermission, canManageRoles));
    options.push(changeToTeamMemberAction(selectedProfilesParams, hasNoPermission, canManageRoles));
    options.push(deleteFromTeamAction(selectedProfilesParams, hasNoPermission, canManageRoles, canTransferOwnership));
    options.push(sendEmailAction(selectedProfilesParams, hasNoPermission, canManageRoles, canTransferOwnership));

    return options;
  };

  const getCurrentProps = (selectedRowsIds: string[], rows: Record<string, _Profile>): _ChosenProps => {
    const newProfileParams = copyObject(defaultProps)!;

    if (selectedRowsIds.length === 0) {
      return newProfileParams;
    }

    newProfileParams.hasSelection = true;

    for (const rowId of selectedRowsIds) {
      const profile = rows[rowId];

      newProfileParams.hasTeamOwners =
        hasRole(profile, TEAM_OWNER_ROLE, {
          team_id: teamId,
        }) || newProfileParams.hasTeamOwners;
      newProfileParams.hasTeamAdmins =
        hasRole(profile, TEAM_ADMIN_ROLE, {
          team_id: teamId,
        }) || newProfileParams.hasTeamAdmins;
      newProfileParams.hasTeamMembers =
        hasRole(profile, TEAM_MEMBER_ROLE, {
          team_id: teamId,
        }) || newProfileParams.hasTeamMembers;
    }

    // at least 2 roles selected
    newProfileParams.multipleRolesSelected =
      (newProfileParams.hasTeamOwners ? 1 : 0) +
        (newProfileParams.hasTeamAdmins ? 1 : 0) +
        (newProfileParams.hasTeamMembers ? 1 : 0) >=
      2;

    newProfileParams.multipleSelected = selectedRowsIds.length > 1;

    return newProfileParams;
  };

  const {selectedRowsIds, onClearSelection, onRowSelected, actions, onSelectAll} = useTableHeaderActions(
    profiles,
    getAllowedActions,
    getCurrentProps,
    null,
    defaultProps,
    trans('roles.choose_members_same_role')
  );

  async function doHandleRoleChange(profileId: string[], role: string) {
    // Implement role change action
    try {
      const response = await performChangeRoleAction(profileId, role, {
        team_id: teamId,
      });

      if (response?.data?.status === 'OK') {
        snackbar.success(response?.data?.message);

        setShowDialog(false);

        reloadContentEngine();

        onClearSelection();
      } else {
        snackbar.danger(response?.data?.message);
      }
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
    }
    setShowDialog(false);
  }

  async function handleSendEmail() {
    const recipients = Object.values(profiles || {})
      .filter(profile => selectedRowsIds.includes(profile.id))
      .map(profile => profile.email);

    showEmailDialog({
      emailContent: {
        subject: trans('emails.personal_message'),
        body: '',
        recipients,
      },
      setDialogConfig,
      onCloseDialog: () => {
        setShowDialog(false);
      },
      availableEmails: recipients,
    });

    setShowDialog(true);
  }

  async function handleDeleteMembers(selectedProfilesParams: _ChosenProps): Promise<void> {
    const title = 'roles.dialogs.team.remove_from_team.title';
    const message = selectedProfilesParams.multipleSelected
      ? 'roles.dialogs.team.remove_from_team.message_plural'
      : 'roles.dialogs.team.remove_from_team.message_singular';

    const names = Object.values(profiles || {})
      .filter(profile => selectedRowsIds.includes(profile.id))
      .map(profile => profile.fullName)
      .join(', ');

    setDialogConfig({
      title: trans(title),
      children: (
        <Typography
          dangerouslySetInnerHTML={{
            __html: trans(message, {
              names,
            }),
          }}
        />
      ),
      onConfirm: () => {
        void doHandleDeleteMembers(selectedRowsIds);
      },
    });

    setShowDialog(true);
  }

  async function doHandleDeleteMembers(profileIds: string[]) {
    const method = 'team.remove';
    const params = {
      teamId,
      profileIds,
    };

    try {
      const response = await performFlowAction(
        {
          method,
          params,
        },
        {
          loadingIndicator: true, // TODO @Sherif Really?
          refetchProfile: true,
        }
      );

      if (response.data?.status === 'success') {
        snackbar.success(trans(response?.data?.message));

        setShowDialog(false);

        reloadContentEngine();

        onClearSelection();
      } else {
        setShowDialog(false);
        snackbar.danger(trans(response?.data?.message));
      }
    } catch (e) {
      setShowDialog(false);
      // handled by AuthTokenInterceptor => errorMessage()
    }
  }

  async function handleRoleChange(role: string, selectedProfilesParams: _ChosenProps) {
    const {title} = dialogLanguageProps[role];
    let message = selectedProfilesParams.multipleSelected
      ? dialogLanguageProps[role].message_plural
      : dialogLanguageProps[role].message_singular;

    if (role === TEAM_OWNER_ROLE) {
      message = hasRole(currentProfile(), TEAM_OWNER_ROLE, {
        team_id: teamId,
      })
        ? dialogLanguageProps[role].message_self
        : dialogLanguageProps[role].message_other;
    }

    const names = Object.values(profiles || {})
      .filter(profile => selectedRowsIds.includes(profile.id))
      .map(profile => profile.fullName)
      .join(', ');

    setDialogConfig({
      title: trans(title),
      children: (
        <Typography
          dangerouslySetInnerHTML={{
            __html: trans(message, {
              names,
            }),
          }}
        />
      ),
      onConfirm: () => {
        void doHandleRoleChange(selectedRowsIds, role);
      },
    });

    setShowDialog(true);
  }

  function transferOwnershipAction(
    selectedProfilesParams: _ChosenProps,
    canTransferOwnership: boolean
  ): _ActionsTableHeaderSelectValue {
    const disabled =
      !canTransferOwnership ||
      selectedProfilesParams.multipleSelected ||
      selectedProfilesParams.hasTeamOwners ||
      !selectedProfilesParams.hasSelection;
    return {
      label: trans('roles.actions.team.handover_ownership'),
      value: 'handover_ownership',
      isHidden: disabled,
      action: () => {
        void handleRoleChange(TEAM_OWNER_ROLE, selectedProfilesParams);
      },
    };
  }

  function promoteToAdminAction(
    selectedProfilesParams: _ChosenProps,
    hasNoPermission: boolean,
    canManageRoles: boolean
  ): _ActionsTableHeaderSelectValue {
    const disabled =
      !canManageRoles ||
      selectedProfilesParams.hasTeamOwners ||
      selectedProfilesParams.hasTeamAdmins ||
      !selectedProfilesParams.hasSelection;
    return {
      label: trans('roles.actions.team.promote_to_admin'),
      value: 'promote_to_admin',
      isHidden: disabled,
      action: () => {
        void handleRoleChange(TEAM_ADMIN_ROLE, selectedProfilesParams);
      },
    };
  }

  function changeToTeamMemberAction(
    selectedProfilesParams: _ChosenProps,
    hasNoPermission: boolean,
    canManageRoles: boolean
  ): _ActionsTableHeaderSelectValue {
    const disabled =
      !canManageRoles ||
      selectedProfilesParams.hasTeamOwners ||
      selectedProfilesParams.hasTeamMembers ||
      !selectedProfilesParams.hasSelection;
    return {
      label: trans('roles.actions.team.change_to_team_member'),
      value: 'change_to_team_member',
      isHidden: disabled,
      action: () => {
        void handleRoleChange(TEAM_MEMBER_ROLE, selectedProfilesParams);
      },
    };
  }

  function sendEmailAction(
    selectedProfilesParams: _ChosenProps,
    hasNoPermission: boolean,
    canManageRoles: boolean,
    canTransferOwnership: boolean
  ): _ActionsTableHeaderSelectValue {
    const disabled = !canManageRoles || !selectedProfilesParams.hasSelection;
    return {
      label: trans('roles.actions.team.send_email'),
      value: 'send_email',
      isHidden: disabled,
      action: () => {
        handleSendEmail();
      },
    };
  }

  function deleteFromTeamAction(
    selectedProfilesParams: _ChosenProps,
    hasNoPermission: boolean,
    canManageRoles: boolean,
    canTransferOwnership: boolean
  ): _ActionsTableHeaderSelectValue {
    const disabled = !canManageRoles || selectedProfilesParams.hasTeamOwners || !selectedProfilesParams.hasSelection;
    return {
      label: trans('roles.actions.team.delete_from_team'),
      value: 'delete_from_team',
      isHidden: disabled,
      action: () => {
        void handleDeleteMembers(selectedProfilesParams);
      },
    };
  }

  return {
    actions,
    chosenProfiles: selectedRowsIds,
    onClearSelectedProfiles: onClearSelection,
    onProfileSelected: onRowSelected,
    onSelectAllProfiles: onSelectAll,
  };
};
