// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {navigateToFlow} from '@app/_core/util/util';
import {TabsItem} from '@modules/ContentEngine/components/items/TabsItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconAdd} from '@modules/Core/components/base/Icons/Icons';
import {Section} from '@modules/Core/components/layout/Section';
import {FeedbackAnalysisOverviewCard} from '@modules/FeedbackAnalysis/components/FeedbackAnalysisOverviewCard';
import {_FeedbackAnalysis, _FeedbackAnalysisStatus} from '@modules/FeedbackAnalysis/types/feedbackAnalysis.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  items: _FeedbackAnalysis[];
}

export const FeedbackAnalysisListItem = ({items}: _Props) => {
  if (!items?.length) {
    return (
      <Section
        imageViewDirection="vertical"
        title={trans('feedback_analysis.empty_section.title')}
        description={trans('feedback_analysis.empty_section.description')}
        image="/assets/img/design_images/feedback analysis.png"
        imageHeight="h-[348px]"
      >
        <div className="flex justify-center items-center">
          <Button
            width="relative-col-width-2"
            label={trans('feedback_analysis.create.title')}
            icon={IconAdd}
            onClick={() => navigateToFlow('FeedbackAnalysisSetup')}
          />
        </div>
      </Section>
    );
  }
  return (
    <Section>{items?.map(analysis => <FeedbackAnalysisOverviewCard key={analysis.id} analysis={analysis} />)}</Section>
  );
};
