// @ts-nocheck
/* eslint-disable */

import React, {ReactNode, useEffect, useState} from 'react';
import {useCreateItems} from '../../../hooks/createItems';
import '../SectionItem.scss';
import {_ContentEngineContext, _ContentEngineItem} from '../../../types/contentEngineItem.model';
import {_Section, Section} from '@modules/Core/components/layout/Section';
import {trans} from '@modules/Translations/util/i18n';
import {_TypographyVariants} from '@modules/Core/components/base/Typography';
import {getTutorialTriggerId} from '@modules/Tutorials/util/tutorialIdUtil';
import {_ActionView} from '@modules/Core/components/layout/ActionList';
import {getIconByName} from '@modules/Core/components/base/Icons/Icons';

export interface _UnparsedActionProps {
  type: 'button' | 'cta_button' | 'link_button' | 'upgrade_button';
  label: string;
  link: string;
  condition?: string | string[];
}

interface _PropItem {
  item: _ContentEngineItem;
}

// TODO: Combine this and _SectionProps to avoid duplication
export interface _SectionItemProps extends _Section {
  id?: string;
  items?: _PropItem[] | string;
  locked?: boolean;
  lockPlaceholderItem?: _PropItem;
  hide?: boolean;

  title?: string | ReactNode;
  titleAsElement?: boolean;
  titleVariant?: _TypographyVariants;
  titleId?: string;
  titlePrefix?: ReactNode;
  description?: string;
  descriptionVariant?: _TypographyVariants;
  subtitle?: string;
  journalKey?: string;
  journalMetadata?: Record<string, any>;
  journalSuffix?: ReactNode;
  info?: string;
  actions?: _UnparsedActionProps[];
  translate?: boolean;
  descriptionParams?: Record<string, string>;
  titleParams?: Record<string, string>;

  variant?: 'section' | 'group';
  image?: string;
  imagePosition?: 'start' | 'end';
  imageViewDirection?: 'horizontal' | 'vertical';
  imageHeight?: string;
  imageWidth?: string;
  teamId?: string;
  context?: _ContentEngineContext;
  tutorialKey?: string;
  tutorialService?: string;
  children?: ReactNode;
  gap?: number;
  containerGap?: number;
  fullWidth?: boolean;
  fullHeight?: boolean;
  view?: 'card' | 'default';
  actionsView?: _ActionView;
  width?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
  alignRightSideHeader?: 'start' | 'center' | 'end';
  titleIcon?: string;
  tutorialIcon?: string;
}

export const SectionItem: React.FC<_SectionItemProps> = ({
  id,
  titleId,
  titleVariant,
  items,
  locked,
  lockPlaceholderItem,
  title,
  description,
  descriptionVariant,
  journalKey,
  info,
  actions,
  subtitle,
  descriptionParams,
  translate,
  titleParams,
  variant = 'section',
  image,
  imagePosition,
  imageViewDirection = 'horizontal',
  imageHeight,
  imageWidth,
  journalMetadata,
  teamId,
  journalSuffix,
  hide = false,
  context,
  tutorialKey,
  tutorialService,
  children,
  titleAsElement,
  titlePrefix,
  gap,
  fullWidth,
  fullHeight,
  view,
  actionsView,
  width = null,
  containerGap,
  alignRightSideHeader,
  titleIcon,
  tutorialIcon,
  ...props
}) => {
  const [contentEngineItems, setContentEngineItems] = useState<_ContentEngineItem[] | string>([]);
  const [lockPlaceholder, setLockPlaceholder] = useState<_ContentEngineItem | null>(null);

  const parsedContent = useCreateItems(contentEngineItems, context);

  const parsedLockPlaceholder = useCreateItems(lockPlaceholder, context);

  useEffect(() => {
    // if items is string, it means it is a text
    if (typeof items === 'string') {
      setContentEngineItems(items);
      return;
    }
    setContentEngineItems((items || []).map(item => item.item));
  }, [items]);

  useEffect(() => {
    if (!lockPlaceholderItem) {
      setLockPlaceholder(null);
      return;
    }

    setLockPlaceholder(lockPlaceholderItem.item);
  }, [lockPlaceholder]);

  if (hide) {
    return null;
  }
  const calculatedWidth = parseInt(width?.toString(), 10);
  return (
    <Section
      containerClassName={
        Number.isNaN(calculatedWidth)
          ? null
          : `relative-col-width-${Math.min(Math.max(calculatedWidth, 1), 12)} ${props.containerClassName ?? ''}`
      }
      id={id}
      imagePosition={imagePosition}
      imageViewDirection={imageViewDirection}
      image={image}
      imageHeight={imageHeight}
      imageWidth={imageWidth}
      variant={variant}
      title={translate ? trans(title, titleParams) : title}
      titleId={titleId}
      titleAsElement={titleAsElement}
      titleVariant={titleVariant}
      titlePrefix={titlePrefix}
      subTitle={subtitle}
      description={translate && description ? trans(description, descriptionParams) : description}
      descriptionVariant={descriptionVariant}
      info={info}
      gap={gap}
      journalKey={journalKey}
      journalMetadata={journalMetadata}
      journalSuffix={journalSuffix}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      tutorialProps={{
        tutorials: context?.tutorials,
        tutorialKey: tutorialKey!,
        id: getTutorialTriggerId(tutorialService),
        iconOverride: tutorialIcon ?? null,
      }}
      actions={(actions ?? []).map(action => ({
        ...action,
        condition: action.condition === '0' || action.condition === '1' ? action.condition === '1' : action.condition,
      }))}
      locked={locked}
      lockedContentPlaceholder={parsedLockPlaceholder}
      teamId={teamId}
      view={view}
      actionsView={actionsView}
      containerGap={containerGap}
      alignRightSideHeader={alignRightSideHeader}
      className={props.className}
      headerClassName={props.headerClassName}
      titleIcon={getIconByName(titleIcon)}
      titleIconSize={props.titleIconSize}
    >
      {parsedContent}
      {children}
    </Section>
  );
};
