// @ts-nocheck
/* eslint-disable */

import {danger} from '@modules/Core/util/snackbar';

export function isString(variable: unknown): boolean {
  return typeof variable === 'string' || variable instanceof String;
}

export function isArray(variable: unknown): boolean {
  return variable instanceof Array || Array.isArray(variable);
}

export function isObject(variable: unknown): boolean {
  return variable instanceof Object;
}

export function isObjectField(item: unknown): boolean {
  return !!(item && typeof item === 'object' && !Array.isArray(item));
}

export function formatFloatString(str: string): string {
  if (!str) return str;
  const num = parseFloat(str);

  // Check if the parsed number is a float
  if (Number.isInteger(num)) {
    return num.toString(); // Return the number as a string without decimal places
  }
  return num.toFixed(2); // Return the number with up to 2 decimal places
}

export function assertTrue(condition: boolean, errorMessage?: string): boolean {
  if (!condition && errorMessage) {
    danger(errorMessage);
  }
  return condition;
}

export function assertFalse(condition: boolean, errorMessage?: string): boolean {
  return assertTrue(!condition, errorMessage);
}

export function isEmpty(value: unknown): boolean {
  return (
    value === null ||
    value === undefined ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
}

export function assertNotEmpty(value: unknown, errorMessage?: string): boolean {
  return assertTrue(!isEmpty(value), errorMessage);
}

export function assertEmpty(value: unknown, errorMessage?: string): boolean {
  return assertTrue(isEmpty(value), errorMessage);
}
