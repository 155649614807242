// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {ADD_JOURNAL_XP_DUMMY_KEY} from '@modules/Gamification/config/constants';
import {CreateJournalButton} from '@modules/Profile/components/journals/CreateJournalButton';
import ExperienceCount from '@modules/Statistics/components/general/ExperienceKeyCount';
import {trans} from '@modules/Translations/util/i18n';

interface _EmptyContent {
  activeTab: string;
  filterCategory: string | null;
  filterTeamId: string | null;
  onUpdateJournals: (category: string) => void;
}

export const JournalEmptyContent: React.FC<_EmptyContent> = ({
  activeTab,
  filterCategory,
  filterTeamId,
  onUpdateJournals,
}) => {
  return (
    <div className="flexCenterCenter flex flex-col items-center gap-5">
      <div className="flex flex-col gap-3 items-center">
        <div className="relative-col-width-3">
          <img src="/assets/img/design_images/add%20note%202x.png" alt="placeholder" />
        </div>
        <ExperienceCount xpKey={ADD_JOURNAL_XP_DUMMY_KEY} />
        <Typography variant="h6" bold>
          {trans('journal.no_journals_title')}
        </Typography>
        <Typography variant="body">{trans('journal.no_journals')}</Typography>
      </div>
      <CreateJournalButton filterCategory={filterCategory} teamId={filterTeamId} onUpdateJournals={onUpdateJournals} />
    </div>
  );
};
