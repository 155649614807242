// @ts-nocheck
/* eslint-disable */

import {fetchProfileData} from '@modules/Profile/state/profileSlice';
import {dispatchAction} from '@modules/State/util/util';
import {logger} from './Logger';

export type _ReactEvent = 'JournalsUpdates' | string;

export const ReactEventJournalUpdated: _ReactEvent = 'JournalsUpdates';

export const ReactEventActionClicked: _ReactEvent = 'ActionClicked';

export function dispatchReactEvent(name: string, data?: any): void {
  const event = new CustomEvent(name, {detail: data});
  logger.debug('[dispatchReactEvent]', name, data);
  document.dispatchEvent(event);
}

export function addReactEventListener(name: string, callback: (event: any) => void): void {
  document.addEventListener(name, callback);
}

export function removeReactEventListener(name: string, callback: (event: any) => void = () => {}) {
  document.removeEventListener(name, callback);
}

export function reloadContentEngine(key: string | null = null): void {
  const data = key ? {key} : {};
  dispatchReactEvent('reload.content', data);
}

export async function reloadProfileStateWithPromise(): Promise<void> {
  await dispatchAction(fetchProfileData(false));
}

export function reloadProfileState(): void {
  dispatchAction(fetchProfileData(false));
}
