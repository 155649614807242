// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Badge} from '@modules/Core/components/base/badge/Badge';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Card} from '@modules/Core/components/base/cards/Card';
import {
  IconCancel,
  IconCopy,
  IconLink,
  IconMore,
  IconSend,
  IconShare,
  IconShow,
  IconSurvey,
  IconTrash,
} from '@modules/Core/components/base/Icons/Icons';
import {ContextMenu} from '@modules/Core/components/base/inputs/ContextMenu';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {fireConfirmationDialog} from '@modules/Core/util/dialogUtil';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {_FeedbackAnalysis, _FeedbackAnalysisStatus} from '@modules/FeedbackAnalysis/types/feedbackAnalysis.model';
import {
  closeFeedbackAnalysisAction,
  deleteFeedbackAnalysisAction,
  duplicateFeedbackAnalysisAction,
  publishFeedbackAnalysisAction,
} from '@modules/FeedbackAnalysis/util/feedbackAnalysisFlowActions';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';
import {shareFeedbackAnalysisFlow} from '../util/dialogFlowsUtil';

interface _Props {
  analysis: _FeedbackAnalysis;
}

const statusLangProps: Record<string, string> = {
  draft: 'feedback_analysis.status.draft',
  published: 'feedback_analysis.status.published',
  closed: 'feedback_analysis.status.closed',
};

const colors: Record<string, 'dark' | 'success'> = {
  draft: 'dark',
  published: 'success',
};

export const FeedbackAnalysisOverviewCard: React.FC<_Props> = ({analysis}) => {
  const snackbar = useSnackbar();
  const navigateToFlow = useNavigateToFlow();

  const onDuplicate = async (analysis: _FeedbackAnalysis): Promise<void> => {
    const newId = await duplicateFeedbackAnalysisAction(analysis.id);

    if (newId) {
      snackbar.success(trans('feedback_analysis.duplicate_success'));
      reloadContentEngine();
      // onChangeTab(_FeedbackAnalysisStatus.DRAFT);
    } else {
      snackbar.danger(trans('base.error'));
    }
  };

  const onPublish = async (analysis: _FeedbackAnalysis): Promise<void> => {
    fireConfirmationDialog({
      title: trans('feedback_analysis.publish_confirm_title'),
      onConfirm: async () => {
        const success = await publishFeedbackAnalysisAction(analysis.id);

        if (success) {
          snackbar.success(trans('feedback_analysis.publish_success'));
          reloadContentEngine();
          // onChangeTab(_FeedbackAnalysisStatus.PUBLISHED);
        } else {
          snackbar.danger(trans('base.error'));
        }
      },
    });
  };

  const onClose = async (analysis: _FeedbackAnalysis): Promise<void> => {
    fireConfirmationDialog({
      title: trans('feedback_analysis.close_confirm_title'),
      onConfirm: async () => {
        const success = await closeFeedbackAnalysisAction(analysis.id);

        if (success) {
          snackbar.success(trans('feedback_analysis.close_success'));
          reloadContentEngine();
        } else {
          snackbar.danger(trans('base.error'));
        }
      },
    });
  };

  const onEdit = (analysis: _FeedbackAnalysis): void => {
    navigateToFlow('FeedbackAnalysisSetup', {feedbackId: analysis.id, edit: true});
  };

  const onDelete = async (analysis: _FeedbackAnalysis): Promise<void> => {
    fireConfirmationDialog({
      title: trans('feedback_analysis.delete_confirm_title'),
      onConfirm: async () => {
        const success = await deleteFeedbackAnalysisAction(analysis.id);
        if (success) {
          snackbar.success(trans('feedback_analysis.delete_success'));
          reloadContentEngine();
        } else {
          snackbar.danger(trans('base.error'));
        }
      },
    });
  };

  const onShare = async (analysis: _FeedbackAnalysis): Promise<void> => {
    shareFeedbackAnalysisFlow(analysis);
  };

  const getActions = (): Array<{label: string; onChange: () => void; icon: React.FC}> => {
    const result = [];

    if (![_FeedbackAnalysisStatus.PUBLISHED, _FeedbackAnalysisStatus.CLOSED].includes(analysis.status)) {
      result.push({
        label: trans('feedback_analysis.actions.publish'),
        onChange: async () => await onPublish(analysis),
        icon: IconSend,
      });
    }

    if (analysis.status === _FeedbackAnalysisStatus.DRAFT) {
      result.push({
        label: trans('feedback_analysis.actions.edit'),
        onChange: () => {
          onEdit(analysis);
        },
        icon: IconSurvey,
      });
    }

    if (analysis.status === _FeedbackAnalysisStatus.PUBLISHED) {
      result.push({
        label: trans('feedback_analysis.actions.invite'),
        onChange: () => {
          onShare(analysis);
        },
        icon: IconShare,
      });

      if (analysis.link) {
        result.push({
          label: trans('feedback_analysis.actions.copy_link'),
          onChange: () => {
            if (analysis.link) {
              navigator.clipboard.writeText(analysis.link);
              snackbar.success(trans('base.copied_successfully'));
            }
          },
          icon: IconLink,
        });
      }

      result.push({
        label: trans('feedback_analysis.actions.close'),
        onChange: () => {
          onClose(analysis);
        },
        icon: IconCancel,
      });

      if (false) {
        result.push({
          label: trans('feedback_analysis.actions.see_results'),
          onChange: () => {
            navigateToFlow('FeedbackAnalysis', {feedbackId: analysis.id});
          },
          icon: IconShow,
        });
      }
    }

    result.push({
      label: trans('feedback_analysis.actions.duplicate'),
      onChange: () => {
        onDuplicate(analysis);
      },
      icon: IconCopy,
    });
    result.push({
      label: trans('feedback_analysis.actions.delete'),
      onChange: () => {
        onDelete(analysis);
      },
      icon: IconTrash,
    });
    return result;
  };

  return (
    <Card
      titleVariant="h5"
      titleClassName="w-full"
      titleSuffix={
        <div className="flex flex-row flex-grow justify-end">
          <Badge value={trans(statusLangProps[analysis.status])} color={colors[analysis.status]} size="fit" />
        </div>
      }
      title={analysis.title}
      description={analysis.description}
    >
      <div className="flex flex-row h-full w-full justify-between">
        <div className="flex flex-col w-full h-full gap-2">
          <AvatarUser label={analysis.createdBy?.fullName} image={getProfilePic(analysis.createdBy)} />

          <div className="flex flex-row flex-grow gap-1">
            {Boolean(
              analysis.status === _FeedbackAnalysisStatus.PUBLISHED &&
                analysis.completedCount &&
                analysis.completedCount > 0
            ) && (
              <>
                {trans('feedback_analysis.completed_count', {
                  count: analysis.completedCount,
                })}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center gap-3">
          {false && Boolean(analysis.canSeeResults) && (
            <Button
              variant="secondary"
              onClick={() =>
                navigateToFlow('FeedbackAnalysis', {
                  feedbackId: analysis.id,
                })
              }
            >
              {trans('feedback_analysis.actions.see_results')}
            </Button>
          )}
          <ContextMenu triggerIcon={IconMore} menuItems={getActions()} />
        </div>
      </div>
    </Card>
  );
};
