// @ts-nocheck
/* eslint-disable */

import React from 'react';
import AnswersTableItem from './AnswersTableItem';

interface Answer {
  title: {
    default: string;
  };
  type: string;
  value: string | number | null;
}

interface CustomSurvey {
  title: {
    default: string;
  };
  description: {
    default: string;
  };
  answers: Answer[];
}

interface Candidate {
  fullName: string;
  email: string;
  link: string;
  isFavorite: boolean;
  isTop: boolean;
  dimensions: any[];
  customSurvey: CustomSurvey;
}

interface Props {
  candidates: Candidate[];
  emptyStateComponent?: React.ReactNode;
  title?: string;
  description?: string;
}

export const CandidateAnswersTableItem: React.FC<Props> = ({candidates, emptyStateComponent, title, description}) => {
  if (!candidates?.length) {
    return emptyStateComponent || <div>No items to display</div>;
  }

  // Check if any candidate has a custom survey
  const hasCustomSurvey = candidates.some(candidate => candidate.customSurvey);
  if (!hasCustomSurvey) {
    return emptyStateComponent;
  }

  // Get questions from the first candidate with a survey
  const firstCandidateWithSurvey = candidates.find(candidate => candidate.customSurvey);
  const questions = firstCandidateWithSurvey?.customSurvey.answers || [];

  // Transform candidates into the format expected by AnswersTableItem
  const columns = candidates.map(candidate => ({
    fullName: candidate.fullName,
    identifier: candidate.email,
    isFavorite: candidate.isFavorite,
    answers: candidate.customSurvey?.answers || [],
  }));

  return (
    <AnswersTableItem
      columns={columns}
      questions={questions}
      emptyStateComponent={emptyStateComponent}
      title={title}
      description={description}
    />
  );
};

export default CandidateAnswersTableItem;
