// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {IconAdd} from './icons/IconAdd';
import {IconApple} from './icons/IconApple';
import {IconArrowBack} from './icons/IconArrowBack';
import {IconArrowDoubleLeft} from './icons/IconArrowDoubleLeft';
import {IconArrowDoubleRight} from './icons/IconArrowDoubleRight';
import {IconArrowDownward} from './icons/IconArrowDownward';
import {IconArrowForward} from './icons/IconArrowForward';
import {IconArrowLeft} from './icons/IconArrowLeft';
import {IconArrowRight} from './icons/IconArrowRight';
import {IconArrowUpward} from './icons/IconArrowUpward';
import {IconAsterisk} from './icons/IconAsterisk';
import {IconAttach} from './icons/IconAttach';
import {IconAttentionSeeker} from './icons/IconAttentionSeeker';
import {IconBell} from './icons/IconBell';
import {IconBriefcase} from './icons/IconBriefcase';
import {IconCalendar} from './icons/IconCalendar';
import {IconCancel} from './icons/IconCancel';
import {IconCandidate} from './icons/IconCandidate';
import {IconCandidateAdd} from './icons/IconCandidateAdd';
import {IconCandidateEdit} from './icons/IconCandidateEdit';
import {IconCheck} from './icons/IconCheck';
import {IconCheckbox} from './icons/IconCheckbox';
import {IconCheckTicked} from './icons/IconCheckTicked';
import {IconClock} from './icons/IconClock';
import {IconCollapseSettings} from './icons/IconCollapseSettings';
import {IconCommunication} from './icons/IconCommunication';
import {IconCoordinator} from './icons/IconCoordinator';
import {IconCopy} from './icons/IconCopy';
import {IconCreditCard} from './icons/IconCreditCard';
import {IconCritic} from './icons/IconCritic';
import {IconCycle} from './icons/IconCycle';
import {IconDiamond} from './icons/IconDiamond';
import {IconDifficult} from './icons/IconDifficult';
import {IconDislike} from './icons/IconDislike';
import {IconDrag} from './icons/IconDrag';
import {IconDragArrows} from './icons/IconDragArrows';
import {IconEasy} from './icons/IconEasy';
import {IconEditText} from './icons/IconEditText';
import {IconEmail} from './icons/IconEmail';
import {IconEmotionalIntelligence} from './icons/IconEmotionalIntelligence';
import {IconEnergySource} from './icons/IconEnergySource';
import {IconEnergyThieves} from './icons/IconEnergyThieves';
import {IconEvaluator} from './icons/IconEvaluator';
import {IconExpandLess} from './icons/IconExpandLess';
import {IconExpandMore} from './icons/IconExpandMore';
import {IconExpandSettings} from './icons/IconExpandSettings';
import {IconFactionary} from './icons/IconFactionary';
import {IconFavorite} from './icons/IconFavorite';
import {IconFavoriteCandidates} from './icons/IconFavoriteCandidates';
import {IconFlow} from './icons/IconFlow';
import {IconGoogle} from './icons/IconGoogle';
import {IconHeart} from './icons/IconHeart';
import {IconHeartFiled} from './icons/IconHeartFiled';
import {IconHide} from './icons/IconHide';
import {IconHome} from './icons/IconHome';
import {IconInfo} from './icons/IconInfo';
import {IconJourneyAdd} from './icons/IconJourneyAdd';
import {IconJourneyEdit} from './icons/IconJourneyEdit';
import {IconLearning} from './icons/IconLearning';
import {IconLike} from './icons/IconLike';
import {IconLink} from './icons/IconLink';
import {IconLinkedin} from './icons/IconLinkedin';
import {IconList} from './icons/IconList';
import {IconListener} from './icons/IconListener';
import {IconLocation} from './icons/IconLocation';
import {IconLocked} from './icons/IconLocked';
import {IconLogo} from './icons/IconLogo';
import {IconLogout} from './icons/IconLogout';
import {IconLongText} from './icons/IconLongText';
import {IconMention} from './icons/IconMention';
import {IconMenu} from './icons/IconMenu';
import {IconMic} from './icons/IconMic';
import {IconMindfulness} from './icons/IconMindfulness';
import {IconMore} from './icons/IconMore';
import {IconMotivation} from './icons/IconMotivation';
import {IconMotives} from './icons/IconMotives';
import {IconNewChat} from './icons/IconNewChat';
import {IconNote} from './icons/IconNote';
import {IconOpenNew} from './icons/IconOpenNew';
import {IconOrder} from './icons/IconOrder';
import {IconOrganisation} from './icons/IconOrganisation';
import {IconOutlook} from './icons/IconOutlook';
import {IconPassive} from './icons/IconPassive';
import {IconPassword} from './icons/IconPassword';
import {IconPaymentSettings} from './icons/IconPaymentSettings';
import {IconPdf} from './icons/IconPdf';
import {IconPerson} from './icons/IconPerson';
import {IconPhoto} from './icons/IconPhoto';
import {IconPowerSeaker} from './icons/IconPowerSeaker';
import {IconProactivity} from './icons/IconProactivity';
import {IconProblemSolver} from './icons/IconProblemSolver';
import {IconProfile} from './icons/IconProfile';
import {IconProfilePrivacy} from './icons/IconProfilePrivacy';
import {IconRadio} from './icons/IconRadio';
import {IconRanking} from './icons/IconRanking';
import {IconRating} from './icons/IconRating';
import {IconRedo} from './icons/IconRedo';
import {IconRefresh} from './icons/IconRefresh';
import {IconRemove} from './icons/IconRemove';
import {IconResilience} from './icons/IconResilience';
import {IconResponsibility} from './icons/IconResponsibility';
import {IconRoles} from './icons/IconRoles';
import {IconSave} from './icons/IconSave';
import {IconSchedule} from './icons/IconSchedule';
import {IconScrollable} from './icons/IconScrollable';
import {IconSearch} from './icons/IconSearch';
import {IconSelfEfficacy} from './icons/IconSelfEfficacy';
import {IconSend} from './icons/IconSend';
import {IconSettings} from './icons/IconSettings';
import {IconShare} from './icons/IconShare';
import {IconShow} from './icons/IconShow';
import {IconSingleLineInput} from './icons/IconSingleLineInput';
import {IconSmile} from './icons/IconSmile';
import {IconSmileyExcited} from './icons/IconSmileyExcited';
import {IconSmileyHappy} from './icons/IconSmileyHappy';
import {IconSmileyNeutral} from './icons/IconSmileyNeutral';
import {IconSmileySad} from './icons/IconSmileySad';
import {IconSmileyUpset} from './icons/IconSmileyUpset';
import {IconSocialRole} from './icons/IconSocialRole';
import {IconSoftFact} from './icons/IconSoftFact';
import {IconSoftFactGrey} from './icons/IconSoftFactGrey';
import {IconStarEmpty} from './icons/IconStarEmpty';
import {IconStarFilled} from './icons/IconStarFilled';
import {IconStop} from './icons/IconStop';
import {IconSurvey} from './icons/IconSurvey';
import {IconSurveyTemplate} from './icons/IconSurveyTemplate';
import {IconTag} from './icons/IconTag';
import {IconTaskCompleter} from './icons/IconTaskCompleter';
import {IconTaskMotivator} from './icons/IconTaskMotivator';
import {IconTeam} from './icons/IconTeam';
import {IconTeamAnalysis} from './icons/IconTeamAnalysis';
import {IconTeamDevelopment} from './icons/IconTeamDevelopment';
import {IconTeamLeader} from './icons/IconTeamLeader';
import {IconTeamworkSupporter} from './icons/IconTeamworkSupporter';
import {IconThrust} from './icons/IconThrust';
import {IconTip} from './icons/IconTip';
import {IconTopCandidates} from './icons/IconTopCandidates';
import {IconTrash} from './icons/IconTrash';
import {IconTrust} from './icons/IconTrust';
import {IconTune} from './icons/IconTune';
import {IconUndo} from './icons/IconUndo';
import {IconUnlock} from './icons/IconUnlock';
import {IconUnlocked} from './icons/IconUnlocked';
import {IconZoomIn} from './icons/IconZoomIn';
import {IconZoomOut} from './icons/IconZoomOut';
import {IconProps} from './types';

// Tailwind + svgs: https://v1.tailwindcss.com/course/working-with-svg-icons

// todo @jonny guide
// 1. export from figma as svg
// 2. run batch script to remove all unnecessary attributes
// 3. import here
// 4. remove line breaks in webstorm: search replace \n\n but make sure, regex search is checked ".*"

// troubleshoot: The icon only shows a greyish circle or something like that? Check if the script managed to remove all unnecessary attributes. The svg should only contain the path and the viewbox attribute.

// Export all icons
export {
  IconSmileySad,
  IconSmileyUpset,
  IconSmileyExcited,
  IconSmileyNeutral,
  IconSmileyHappy,
  IconSoftFact,
  IconSoftFactGrey,
  IconArrowDownward,
  IconArrowUpward,
  IconTune,
  IconLink,
  IconDrag,
  IconDragArrows,
  IconAsterisk,
  IconSurvey,
  IconUndo,
  IconRedo,
  IconCollapseSettings,
  IconExpandSettings,
  IconRating,
  IconLongText,
  IconSingleLineInput,
  IconRadio,
  IconCheckbox,
  IconApple,
  IconOutlook,
  IconGoogle,
  IconOpenNew,
  IconAdd,
  IconArrowBack,
  IconArrowDoubleLeft,
  IconArrowDoubleRight,
  IconArrowForward,
  IconArrowLeft,
  IconArrowRight,
  IconAttach,
  IconAttentionSeeker,
  IconBell,
  IconBriefcase,
  IconCalendar,
  IconCancel,
  IconPaymentSettings,
  IconCheckTicked,
  IconCheck,
  IconClock,
  IconCommunication,
  IconCoordinator,
  IconCopy,
  IconCreditCard,
  IconCritic,
  IconCycle,
  IconDiamond,
  IconDifficult,
  IconDislike,
  IconEasy,
  IconEditText,
  IconEmail,
  IconEnergySource,
  IconEnergyThieves,
  IconEvaluator,
  IconExpandLess,
  IconExpandMore,
  IconFactionary,
  IconFlow,
  IconHeartFiled,
  IconHeart,
  IconHide,
  IconHome,
  IconInfo,
  IconLearning,
  IconLike,
  IconList,
  IconListener,
  IconLocation,
  IconLocked,
  IconLogo,
  IconLogout,
  IconMention,
  IconMenu,
  IconMic,
  IconMindfulness,
  IconMore,
  IconMotivation,
  IconMotives,
  IconNewChat,
  IconNote,
  IconTip,
  IconOrder,
  IconPassive,
  IconPassword,
  IconPerson,
  IconOrganisation,
  IconPhoto,
  IconPowerSeaker,
  IconProactivity,
  IconProblemSolver,
  IconProfilePrivacy,
  IconProfile,
  IconRanking,
  IconRefresh,
  IconRemove,
  IconResponsibility,
  IconRoles,
  IconScrollable,
  IconSearch,
  IconSelfEfficacy,
  IconSend,
  IconSettings,
  IconShare,
  IconShow,
  IconSmile,
  IconSocialRole,
  IconStarEmpty,
  IconStarFilled,
  IconStop,
  IconTaskCompleter,
  IconTaskMotivator,
  IconTeamAnalysis,
  IconTeamDevelopment,
  IconTeamLeader,
  IconTeam,
  IconTeamworkSupporter,
  IconThrust,
  IconTrash,
  IconUnlock,
  IconUnlocked,
  IconZoomIn,
  IconZoomOut,
  IconTag,
  IconSchedule,
  IconEmotionalIntelligence,
  IconTrust,
  IconResilience,
  IconPdf,
  IconSave,
  IconJourneyAdd,
  IconJourneyEdit,
  IconSurveyTemplate,
  IconCandidate,
  IconCandidateAdd,
  IconCandidateEdit,
  IconFavorite,
  IconFavoriteCandidates,
  IconLinkedin,
  IconTopCandidates,
};

const iconMap: Record<string, React.FC<IconProps>> = {
  add: IconAdd,
  share: IconShare,
  cycle: IconCycle,
  task_completer: IconTaskCompleter,
  profile: IconProfile,
  teamwork_supporter: IconTeamworkSupporter,
  thrust: IconThrust,
  trash: IconTrash,
  unlock: IconUnlock,
  unlocked: IconUnlocked,
  zoom_in: IconZoomIn,
  zoom_out: IconZoomOut,
  tag: IconTag,
  schedule: IconSchedule,
  tip: IconTip,
  info: IconInfo,
  smile: IconSmile,
  editText: IconEditText,
  logout: IconLogout,
  arrow_right: IconArrowRight,
  arrow_left: IconArrowLeft,
  teamDevelopment: IconTeamDevelopment,
  email: IconEmail,
  arrow_back: IconArrowBack,
  softfact: IconSoftFactGrey,
  topCandidates: IconTopCandidates,
  favoriteCandidates: IconFavoriteCandidates,
  checkbox: IconCheckbox,
  checkbox_unselected: IconSingleLineInput,
  resilience: IconResilience,
  emotional_intelligence: IconEmotionalIntelligence,
  trust: IconTrust,
  task_motivator: IconTaskMotivator,
  team: IconTeam,
  team_analysis: IconTeamAnalysis,
  team_leader: IconTeamLeader,
  social_role: IconSocialRole,
  star_empty: IconStarEmpty,
  star_filled: IconStarFilled,
  stop: IconStop,
  self_efficacy: IconSelfEfficacy,
  send: IconSend,
  settings: IconSettings,
  show: IconShow,
  ranking: IconRanking,
  refresh: IconRefresh,
  remove: IconRemove,
  responsibility: IconResponsibility,
  roles: IconRoles,
  scrollable: IconScrollable,
  search: IconSearch,
  organisation: IconOrganisation,
  photo: IconPhoto,
  power_seaker: IconPowerSeaker,
  proactivity: IconProactivity,
  problem_solver: IconProblemSolver,
  profile_privacy: IconProfilePrivacy,
  order: IconOrder,
  passive: IconPassive,
  password: IconPassword,
  person: IconPerson,
  mic: IconMic,
  mindfulness: IconMindfulness,
  more: IconMore,
  motivation: IconMotivation,
  motives: IconMotives,
  new_chat: IconNewChat,
  learning: IconLearning,
  like: IconLike,
  list: IconList,
  listener: IconListener,
  location: IconLocation,
  locked: IconLocked,
  logo: IconLogo,
  mention: IconMention,
  menu: IconMenu,
  open_new: IconOpenNew,
  energy_source: IconEnergySource,
  energy_thieves: IconEnergyThieves,
  evaluator: IconEvaluator,
  expand_less: IconExpandLess,
  expand_more: IconExpandMore,
  flow: IconFlow,
  heart: IconHeart,
  heart_filled: IconHeartFiled,
  hide: IconHide,
  home: IconHome,
  difficult: IconDifficult,
  dislike: IconDislike,
  easy: IconEasy,
  clock: IconClock,
  communication: IconCommunication,
  coordinator: IconCoordinator,
  copy: IconCopy,
  credit_card: IconCreditCard,
  critic: IconCritic,
  diamond: IconDiamond,
  arrow_double_right: IconArrowDoubleRight,
  attach: IconAttach,
  attention_seeker: IconAttentionSeeker,
  bell: IconBell,
  briefcase: IconBriefcase,
  calendar: IconCalendar,
  pdf: IconPdf,
  save: IconSave,
  journeyAdd: IconJourneyAdd,
  journeyEdit: IconJourneyEdit,
  surveyTemplate: IconSurveyTemplate,
  candidate: IconCandidate,
  candidateAdd: IconCandidateAdd,
  candidateEdit: IconCandidateEdit,
  favorite: IconFavorite,
  linkedin: IconLinkedin,
  survey: IconSurvey,
};

// TODO : We need to implement this for all or find a smarter way cause fuuu me
export const getIconByName = (name: string): React.FC<IconProps> => iconMap[name];
