// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {useSoftFactGroups} from '@modules/ContentEngine/hooks/useSoftFactGroups';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {Slider} from '@modules/Core/components/base/Slider';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';
import {IconInfo} from '../Icons/Icons';

export interface SliderItem {
  id: string;
  title?: string;
  leftText?: string;
  rightText?: string;
  leftTooltip?: string;
  rightTooltip?: string;
  value: number;
  range?: {
    min: number;
    max: number;
  };
  reflection?: string;
  description?: string;
  items?: Array<{
    initials?: string;
    name?: string;
    value: number;
    id: string;
  }>;
  initials?: string;
  name?: string;
  recommendationTitle?: string;
}

interface Props {
  items: Record<string, SliderItem[]>;
  scores?: Record<string, number>;
  maxScore?: number;
  itemOrder?: string[];
  emptyStateComponent?: React.ReactNode;
  title?: string;
  description?: string;
  titleColumnWidth?: string;
}

export const SliderListItem: React.FC<Props> = ({
  items,
  scores,
  maxScore = 10,
  itemOrder,
  emptyStateComponent,
  title,
  description,
  titleColumnWidth = 'relative-col-width-3',
}) => {
  const softFactGroups = useSoftFactGroups();

  if (!items || Object.keys(items).length === 0) return emptyStateComponent || <div>No items to display</div>;

  const allItems = Object.entries(items).map(([prefix, groupItems]) => {
    // Find the matching group from softFactGroups
    const group = softFactGroups.find(g => g.id === prefix);

    const sortedItems = itemOrder
      ? [...groupItems].sort((a, b) => itemOrder.indexOf(a.id) - itemOrder.indexOf(b.id))
      : groupItems;

    return (
      <Section
        key={prefix}
        gap={4}
        titleIcon={getServiceIcon(prefix)}
        title={group?.name || prefix}
        titlePrefix={scores?.[prefix] && <Typography variant="h5">{scores[prefix]}%</Typography>}
      >
        {sortedItems.map(item => {
          const id = item.id.split(':')[1];
          const dimension = group?.dimensions.find(d => d.id === id);

          return (
            <Slider
              simpleStyle
              title={dimension?.name || item.title}
              leftText={trans('profile_result.low')}
              rightText={trans('profile_result.high')}
              leftIcon={item.leftTooltip ? IconInfo : undefined}
              rightIcon={item.rightTooltip ? IconInfo : undefined}
              leftTooltip={item.leftTooltip}
              rightTooltip={item.rightTooltip}
              rangeStart={item.range ? (item.range.min / maxScore) * 100 : undefined}
              rangeEnd={item.range ? (item.range.max / maxScore) * 100 : undefined}
              rangeColor="bg-green700"
              titleColumnWidth={titleColumnWidth}
              components={
                item.items
                  ? item.items.map(subItem => ({
                      id: subItem.id,
                      progress: (subItem.value / maxScore) * 100,
                      component: <Avatar size="lg" initials={subItem.initials} tooltip={JSON.stringify(subItem)} />,
                    }))
                  : []
              }
            />
          );
        })}
      </Section>
    );
  });

  return (
    <Section title={title} description={description} gap={5}>
      {allItems}
    </Section>
  );
};

export default SliderListItem;
