// @ts-nocheck
/* eslint-disable */

import React, {createContext, Dispatch, ReactElement, SetStateAction, useEffect, useState} from 'react';
import defaultOptions, {IOptions} from './options';
import {getFormattedDate as formatDate} from './utils';

interface IDatePickerContext {
  options: IOptions;
  view: Views;
  setView: Dispatch<SetStateAction<Views>>;
  show: boolean;
  setShow: (show: boolean) => void;
  selectedDates: Date[];
  changeSelectedDate: (date: Date | null) => void;
  showSelectedDate: boolean;
  setShowSelectedDate: Dispatch<SetStateAction<boolean>>;
  selectedMonth: number;
  selectedYear: number;
  getFormattedDate: (date: Date | number, formatOptions?: Intl.DateTimeFormatOptions | null | undefined) => string;
  changeCurrentMonth: (action: 'prev' | 'next' | 'today') => void;
  setSelectedDates: (value: Date[]) => void;
}

export type Views = 'days' | 'months' | 'years' | 'decades';

export const DatePickerContext = createContext<IDatePickerContext>({
  options: defaultOptions,
  view: 'days',
  setView: () => {},
  show: false,
  setShow: () => {},
  selectedDates: [],
  changeSelectedDate: () => {},
  showSelectedDate: true,
  setShowSelectedDate: () => {},
  selectedMonth: 0,
  selectedYear: 0,
  getFormattedDate: () => '',
  changeCurrentMonth: () => {},
  setSelectedDates: (value: Date[]) => {},
});

interface IDatePickerProviderProps {
  children: ReactElement;
  options?: IOptions;
  dates: Date[];
  setDates: (dates: Date[]) => void;
  show: boolean;
  setShow: (show: boolean) => void;
}

const DatePickerProvider = ({
  children,
  options: customOptions,
  dates,
  setDates,
  show,
  setShow,
}: IDatePickerProviderProps): JSX.Element => {
  const options = {...defaultOptions, ...customOptions};
  const [view, setView] = useState<Views>('days');
  const [selectedDates, setSelectedDates] = useState<Date[]>(dates);
  const [showSelectedDate, setShowSelectedDate] = useState<boolean>(options?.defaultDate !== null);
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());

  const handleDateSelection = (dates: Date[]): void => {
    setSelectedDates(dates);
    setDates(dates);
  };

  const changeCurrentMonth = (action: 'prev' | 'next' | 'today'): void => {
    if (action === 'prev') {
      setSelectedMonth(prev => (prev === 1 ? 12 : prev - 1));
      setSelectedYear(prev => (selectedMonth === 1 ? prev - 1 : prev));
    }
    if (action === 'next') {
      setSelectedMonth(prev => (prev === 12 ? 1 : prev + 1));
      setSelectedYear(prev => (selectedMonth === 12 ? prev + 1 : prev));
    }
    if (action === 'today') {
      setSelectedMonth(new Date().getMonth() + 1);
      setSelectedYear(new Date().getFullYear());
    }
  };

  const changeSelectedDate = (date: Date | null): void => {
    if (date === null) {
      handleDateSelection([]);
      return;
    }

    if (options?.maxDate && date > options.maxDate) return;
    if (options?.minDate && date < options.minDate) return;
    if (options?.disabledDates && options.disabledDates.includes(date)) return;

    if (options?.onlySingleDate) {
      handleDateSelection([date]);
      setShowSelectedDate(true);
      return;
    }

    const isIncludedAlready = (existingDate: Date): boolean =>
      formatDate(options?.language ? options.language : 'en', existingDate) ===
      formatDate(options?.language ? options.language : 'en', date);

    const newDates = selectedDates.some(isIncludedAlready)
      ? selectedDates.filter(existingDate => !isIncludedAlready(existingDate))
      : [...selectedDates, date];

    handleDateSelection(newDates);

    setShowSelectedDate(true);
    // if (options?.autoHide && view === 'days' && action === 'date') setShow(false);
    // if (onChange) onChange(date);
  };

  const getFormattedDate = (
    date: Date | number,
    formatOptions?: Intl.DateTimeFormatOptions | undefined | null
  ): string => formatDate(options?.language ? options?.language : 'en', date, formatOptions);

  return (
    <DatePickerContext.Provider
      value={{
        options,
        view,
        setView,
        show,
        setShow,
        selectedDates,
        changeSelectedDate,
        showSelectedDate,
        setShowSelectedDate,
        selectedMonth,
        selectedYear,
        getFormattedDate,
        changeCurrentMonth,
        setSelectedDates: handleDateSelection,
      }}
    >
      {children}
    </DatePickerContext.Provider>
  );
};

export default DatePickerProvider;
