// @ts-nocheck
/* eslint-disable */

import {AxiosRequestConfig} from 'axios';
import {httpClient} from '@modules/Core/services/HttpClient';
import {
  DELETE_PHOTO,
  DOWNLOAD_SERVICE_CERTIFICATE,
  IMPERSONATE,
  PRIVACY_SETTINGS,
  PROFILE,
  PROFILE_DATA,
  PROFILE_FEELINGS,
  PROPERTY_TYPES,
  SAVE_DESCRIPTION,
  SAVE_PHOTO,
  SAVE_PROFILE,
  SHARED_RESULTS,
  SOFT_FACTS_SHARED_RESULT,
  STOP_IMPERSONATING,
  THIRD_PERSON_FEEDBACK,
  THIRD_PERSON_FEEDBACK_REMINDER,
  THIRD_PERSON_FEEDBACK_SINGLE_REPORT,
} from './paths';

async function getProfile(loadingIndicator = true): Promise<any> {
  return await httpClient.get(PROFILE, loadingIndicator ? {headers: {'X-Show-Loader': 'true'}} : {});
}

async function trackProfile(profile: any): Promise<any> {
  return await httpClient.post(`${PROFILE}?${profile.id.substring(0, 8)}`);
}

async function deleteProfilePhoto(): Promise<any> {
  return await httpClient.get(DELETE_PHOTO);
}

async function saveProfilePhoto(request: AxiosRequestConfig): Promise<any> {
  return await httpClient.post(SAVE_PHOTO, request);
}

async function saveProfileDescription(request: AxiosRequestConfig): Promise<any> {
  return await httpClient.post(SAVE_DESCRIPTION, request);
}

async function saveProfile(request: AxiosRequestConfig): Promise<any> {
  return await httpClient.post(SAVE_PROFILE, request);
}

async function getPrivacySettings(): Promise<any> {
  return await httpClient.get(PRIVACY_SETTINGS);
}

async function getPropertyTypes(): Promise<any> {
  return await httpClient.get(PROPERTY_TYPES);
}

async function addProfileFeeling(value: string): Promise<any> {
  return await httpClient.post(PROFILE_FEELINGS, {value});
}

async function getThirdPersonFeedbacks(initialTabIndex: number): Promise<any> {
  let path = THIRD_PERSON_FEEDBACK;
  if (initialTabIndex) {
    path += `?openSentFeedbacks=${initialTabIndex}`;
  }
  return await httpClient.get(path);
}

async function thirdPersonFeedbackReminder(inviteId: string): Promise<any> {
  return await httpClient.get(`${THIRD_PERSON_FEEDBACK_REMINDER}/${inviteId}`);
}

async function getProfileData(profileId: string): Promise<any> {
  return await httpClient.get(`${PROFILE_DATA}/${profileId}`);
}

async function getSharedResults(initialTabIndex?: number): Promise<any> {
  let path = SHARED_RESULTS;
  if (initialTabIndex) {
    path += `?openSentResult=${initialTabIndex}`;
  }
  return await httpClient.get(path);
}

async function getSoftFactsSharedResult(permissionId: string): Promise<any> {
  return await httpClient.get(`${SOFT_FACTS_SHARED_RESULT}/${permissionId}`);
}

async function getThirdPersonFeedbackSingleReport(projectId: string): Promise<any> {
  return await httpClient.get(`${THIRD_PERSON_FEEDBACK_SINGLE_REPORT}/${projectId}`);
}

async function downloadServiceCertificate(service: string, round: string | number): Promise<any> {
  return await httpClient.get(`${DOWNLOAD_SERVICE_CERTIFICATE}/${service}/${round}`, {
    responseType: 'blob', // Indicate that you expect a binary response (a file to download)
    headers: {
      // 'Content-Type': 'application/pdf', // Typically not needed for a GET request
      'Cache-Control': 'no-cache, no-store, must-revalidate', // Directs the browser to not cache the response
      Pragma: 'no-cache', // For backward compatibility with HTTP/1.0 caches
      Expires: '0', // Sets the expiration to a past date to prevent caching
    },
  });
}

/**
 * Start impersonating another user
 * @param profileId The ID of the profile to impersonate
 * @returns Promise with the response
 */
async function impersonateUser(profileId: string): Promise<any> {
  return await httpClient.post(IMPERSONATE, {profile_id: profileId});
}

/**
 * Stop impersonating and return to original user
 * @returns Promise with the response
 */
async function stopImpersonating(): Promise<any> {
  return await httpClient.post(STOP_IMPERSONATING);
}

export {
  getProfile,
  trackProfile,
  deleteProfilePhoto,
  saveProfilePhoto,
  saveProfileDescription,
  saveProfile,
  getPrivacySettings,
  getPropertyTypes,
  addProfileFeeling,
  getThirdPersonFeedbacks,
  thirdPersonFeedbackReminder,
  getProfileData,
  getSharedResults,
  getSoftFactsSharedResult,
  getThirdPersonFeedbackSingleReport,
  downloadServiceCertificate,
  impersonateUser,
  stopImpersonating,
};
