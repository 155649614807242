// @ts-nocheck
/* eslint-disable */

import {logger} from '@sentry/utils';
import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import snackbar from '@modules/Core/util/snackbar';
import {_FeedbackAnalysis, _FeedbackAnalysisSubmission} from '@modules/FeedbackAnalysis/types/feedbackAnalysis.model';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_FlowActionResult} from '@modules/FlowEngine/types/flow-action.model';

// Function to publish a feedback analysis
export const publishFeedbackAnalysisAction = async (analysisId: string): Promise<boolean> => {
  try {
    const response = await performFlowAction(
      {
        method: 'feedback_analysis.publish',
        params: {analysisId},
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Publish 360° Feedback Action failed:', responseData?.message);
    return false;
  } catch (e) {
    logger.error('Publish 360° Feedback Action encountered an error:', e);
    return false;
  }
};

// Function to close a feedback analysis
export const closeFeedbackAnalysisAction = async (analysisId: string): Promise<boolean> => {
  try {
    const response = await performFlowAction(
      {
        method: 'feedback_analysis.close',
        params: {analysisId},
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Close 360° Feedback Action failed:', responseData?.message);
    return false;
  } catch (e) {
    logger.error('Close 360° Feedback Action encountered an error:', e);
    return false;
  }
};

// Function to delete a feedback analysis
export const deleteFeedbackAnalysisAction = async (analysisId: string): Promise<boolean> => {
  try {
    const response = await performFlowAction(
      {
        method: 'feedback_analysis.delete',
        params: {analysisId},
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Delete 360° Feedback Action failed:', responseData?.message);
    return false;
  } catch (e) {
    logger.error('Delete 360° Feedback Action encountered an error:', e);
    return false;
  }
};

// Function to share a feedback analysis
export const shareFeedbackAnalysisAction = async (analysisId: string, shareWith: string[]): Promise<boolean> => {
  try {
    const response = await performFlowAction(
      {
        method: 'feedback_analysis.share',
        params: {analysisId, shareWith},
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Share 360° Feedback Action failed:', responseData?.message);
    return false;
  } catch (e) {
    logger.error('Share 360° Feedback Action encountered an error:', e);
    return false;
  }
};

// Function to save a feedback analysis
export const saveFeedbackAnalysisAction = async (
  body: _FeedbackAnalysis,
  analysisId?: string
): Promise<string | null> => {
  try {
    const response = await performFlowAction(
      {
        method: 'feedback_analysis.save',
        params: {
          analysisId,
          ...(body ?? {}),
        },
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    logger.debug('Save 360° Feedback Action response:', responseData);
    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return responseData.data.analysis_id;
    }
    logger.error('Save 360° Feedback Action failed:', responseData?.message);
    return null;
  } catch (e) {
    logger.error('Save 360° Feedback Action encountered an error:', e);
    return null;
  }
};

// Function to finalize a feedback analysis submission
export const finalizeFeedbackAnalysisSubmission = async (
  analysisId: string,
  submission: _FeedbackAnalysisSubmission
): Promise<boolean> => {
  try {
    const response = await performFlowAction(
      {
        method: 'feedback_analysis.finalize',
        params: {
          analysisId,
          submission,
        },
      },
      {
        refetchProfile: true,
        unauthorized: true,
      }
    );

    const responseData = response?.data;

    logger.debug('Finalize 360° Feedback Action response:', responseData);
    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      logger.debug('360° Feedback submission finalized successfully:', responseData.data);
      return true;
    }
    logger.error('Finalize 360° Feedback Action failed:', responseData?.message);
    return false;
  } catch (e) {
    logger.error('Finalize 360° Feedback Action encountered an error:', e);
    return false;
  }
};

export const duplicateFeedbackAnalysisAction = async (analysisId: string): Promise<string | boolean> => {
  try {
    const response = await performFlowAction(
      {
        method: 'feedback_analysis.duplicate',
        params: {analysisId},
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data?.analysis_id) {
      return responseData?.data?.analysis_id;
    }
    logger.error('Duplicate 360° Feedback Action failed:', responseData?.message);
    return false;
  } catch (e) {
    logger.error('Duplicate 360° Feedback Action encountered an error:', e);
    return false;
  }
};

// Function to set/unset a feedback analysis favorite
export const setFeedbackAnalysisFavoriteAction = async (
  analysisId: string,
  candidateId: string,
  isFavorite: boolean
): Promise<boolean> => {
  try {
    const response = await performFlowAction({
      method: 'feedback_analysis.set_favorite',
      params: {
        analysisId,
        candidateId,
        isFavorite,
      },
    });

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      if (responseData.message) {
        snackbar.success(responseData.message);
      }
      reloadContentEngine();
      return true;
    }
    logger.error('Set 360° Feedback Favorite Action failed:', responseData?.message);
    return false;
  } catch (e) {
    logger.error('Set 360° Feedback Favorite Action encountered an error:', e);
    return false;
  }
};
