// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {Bar, getElementAtEvent} from 'react-chartjs-2';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {logger} from '@modules/Core/util/Logger';
import {useProfile} from '@modules/Profile/hooks/profile';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

const ROW_SIZE = 30;
const EXTRA_HEIGHT = 70;

interface _Props {
  channels: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  teamProfiles: any;
  channelName: any;
  transChannelName: any;
  transparencyAccepted: boolean;
}

export const TeamCommunicationModal: React.FC<_Props> = ({
  channels = [],
  open,
  setOpen,
  showModal,
  setShowModal,
  teamProfiles,
  channelName,
  transChannelName,
  transparencyAccepted,
}) => {
  const {currentProfile} = useProfile();

  const [data, setData] = useState({});

  const [channelTopicsLabels, setChannelTopicsLabels] = useState([]);

  const [chosenElement, setChosenElement] = useState(null);

  const chartRef = useRef();

  // profile redux hooks
  const channelTopicsLegends = ['l_0', 'l_1', 'l_2'];

  useEffect(() => {
    if (!channels) {
      setChosenElement(null);
      return;
    }

    createGraphData();
  }, [channels, channelName, showModal]);

  function createSingleDataset(index, color, newGraphData) {
    const foundEntriesForChannel = Object.entries(newGraphData || {}).filter(
      ([key, value]) => !channelName || key === channelName?.key
    );

    if (channelName) {
      const topicName = channelTopicsLegends[index];

      for (const foundEntry of foundEntriesForChannel) {
        if (!foundEntry[1][topicName]) {
          return null;
        }
      }
    }

    const data = foundEntriesForChannel.map(([key, value]) => value[channelTopicsLegends[index]] || 0);

    const nonZeroData = data.filter(data => data > 0);

    if (!nonZeroData.length) {
      return null;
    }

    return {
      type: 'bar',
      label: trans(`way_of_working.communication_channels.values.${channelTopicsLegends[index]}`),
      data,
      borderColor: color,
      backgroundColor: color,
      borderRadius: 35,
      padding: 10,
      height: 10,
      borderWidth: 0,
      barThickness: 10,
    };
  }

  function createGraphData() {
    const newGraphData = {};
    const labels = Object.keys(channels);
    const datasets = [];

    if (!labels.length) {
      return;
    }

    for (const channel in channels) {
      newGraphData[channel] = {};
      for (const topic in channels[channel]) {
        if (!newGraphData[channel][topic]) {
          newGraphData[channel][topic] = 0;
        }
        newGraphData[channel][topic] += channels[channel]?.[topic]?.length;
      }
    }

    // Filter out empty channels and their corresponding labels
    for (const channel in newGraphData) {
      if (Object.values(newGraphData[channel]).reduce((a, b) => a + b, 0) === 0) {
        // remove channel from labels
        labels.splice(labels.indexOf(channel), 1);
        delete newGraphData[channel];
      }
    }

    const newData = {
      labels: channelName
        ? [transChannelName(channelName?.key)]
        : labels.map(channelName => transChannelName(channelName)),
      datasets: [
        createSingleDataset(0, '#8ACED0', newGraphData),
        createSingleDataset(1, '#F0B856', newGraphData),
        createSingleDataset(2, '#132E3E', newGraphData),
      ].filter(dataset => dataset),
    };

    setData(newData);
    setChannelTopicsLabels(channelName ? [channelName?.key] : Object.keys(newGraphData));
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onClick = event => {
    const {datasetIndex, index} = getElementAtEvent(chartRef.current, event)[0] ?? {};

    if (index >= 0) {
      setChosenElement({
        datasetIndex,
        index,
      });
      handleClickOpen();
    }
  };

  const dataLength = (data?.labels || []).length;
  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    layout: {
      margin: 10,
      padding: 10,
    },
    scales: {
      x: {
        grid: {
          offset: true,
          lineWidth: 0,
        },
        stacked: true,
        // min: 10,
        display: false,
        xAlign: 'right',
      },
      y: {
        beginAtZero: false,
        stacked: true,
        grid: {
          display: false,
          lineWidth: 0,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 10,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        onClick: null,

        labels: {
          useBorderRadius: true,
          borderRadius: 6,
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: false,
        text: channelName || 'Channel Topics',
      },
      datalabels: {
        display: false,
      },
    },
  };

  const dialogBody = () => {
    if (!chosenElement) {
      return null;
    }
    const {datasetIndex, index} = chosenElement;

    const profileIds = channels[channelTopicsLabels[index]]?.[channelTopicsLegends[datasetIndex]];

    return (
      <Dialog
        showCloseButton
        open={open}
        onClose={handleClose}
        title={`${transChannelName(channelTopicsLegends[datasetIndex])} : ${transChannelName(channelTopicsLabels[index])}`}
        hideButtons
      >
        <div className="flex flex-col justify-start">
          {(profileIds || []).map((id, index) => {
            const profile = teamProfiles[id];
            const showProfile = transparencyAccepted || currentProfile?.id === id;
            return (
              <div className="flex flex-row justify-content-left w-100 align-items-center p-1">
                <AvatarUser image={getProfilePic(profile)} label={showProfile ? profile?.fullName : ''} />
              </div>
            );
          })}
        </div>
      </Dialog>
    );
  };

  return (
    <>
      {dialogBody()}
      <Dialog
        showCloseButton
        open={showModal && !open}
        onClose={() => setShowModal(false)}
        title={trans('way_of_working.channel_topics')}
      >
        <div
          style={{
            height: `${channelTopicsLabels?.length * ROW_SIZE + EXTRA_HEIGHT}px`,
            width: '100%',
          }}
        >
          <Bar
            type="bar"
            options={options}
            ref={chartRef}
            data={data}
            onClick={onClick}
            borderColor={data?.datasets?.borderColor}
            backgroundColor={data?.datasets?.backgroundColor}
            borderRadius={data?.datasets?.borderRadius}
            padding={data?.datasets?.padding}
            borderWidth={data?.datasets?.borderWidth}
          />
        </div>
      </Dialog>
    </>
  );
};
