// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Divider} from '@modules/Core/components/base/Divider';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {Emails} from '@modules/Team/components/invitations/Emails';
import {BaseStep} from '../Base/BaseStep';

interface EmailTarget {
  id: string;
  title: string;
  description?: string;
  placeholder?: string;
  label?: string;
  consentLabel?: string;
  helpLabel?: string;
  emails?: string[];
}

interface MultipleEmailsStepOptions extends _BaseStepComponentOptions {
  targets: EmailTarget[];
}

export const MultipleEmailsStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const typedOptions = options as MultipleEmailsStepOptions;
  const {targets} = typedOptions ?? {};
  const [emailGroups, setEmailGroups] = useState<Record<string, string[]>>({});

  useEffect(() => {
    if (targets) {
      const initialEmailGroups = targets.reduce<Record<string, string[]>>((acc, target) => {
        acc[target.id] = target.emails || [];
        return acc;
      }, {});
      setEmailGroups(initialEmailGroups);
    }
  }, []);

  const getValuesCallback = (): Record<string, any> => ({
    emailGroups,
  });

  logger.debug('[MultipleEmailsStep] options', {
    options: typedOptions,
    emailGroups,
  });

  const updateEmails = (targetId: string, emails: string[]) => {
    setEmailGroups(prev => ({
      ...prev,
      [targetId]: emails,
    }));
  };

  return (
    <BaseStep getValueCallbackFn={getValuesCallback} inputs={[emailGroups]} options={options}>
      <Section
        title={options.title}
        description={options.description}
        titleVariant="h5"
        descriptionVariant="body1"
        gap={5}
      >
        {(targets || []).map((target, index) => (
          <Section
            key={target.id}
            title={target.title}
            description={target.description}
            descriptionVariant="body1"
            gap={5}
          >
            <Emails
              emails={emailGroups[target.id] || []}
              setEmails={emails => updateEmails(target.id, emails)}
              placeholder={target.placeholder}
              label={target.label}
              consentLabel={target.consentLabel}
              helpLabel={target.helpLabel}
            />
            {index < targets.length - 1 && <Divider />}
          </Section>
        ))}
      </Section>
    </BaseStep>
  );
};
