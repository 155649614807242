// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';

interface BoxProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties & {
    '--tw-hover-bg'?: string;
  };
  sx?: {
    display?: 'flex';
    flexDirection?: 'row' | 'column';
    gap?: number;
    alignItems?: 'center' | 'start' | 'end' | 'stretch';
    justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around';
    flex?: number | string;
    width?: string;
    height?: string;
    padding?: number;
    margin?: number;
    marginBottom?: number;
    backgroundColor?: string;
    borderRadius?: number;
    transition?: string;
    '&:hover'?: {
      transform?: string;
      cursor?: string;
      backgroundColor?: string;
    };
  };
}

export const Box: React.FC<BoxProps> = ({children, className = '', style = {}, sx = {}}) => {
  const classes = [];
  const hoverClasses = [];
  const computedStyle: React.CSSProperties & {
    '--tw-hover-bg'?: string;
  } = {...style};

  // Handle display and flex properties
  if (sx.display === 'flex') classes.push('flex');
  if (sx.flexDirection === 'column') classes.push('flex-col');
  if (sx.gap !== undefined) classes.push(`gap-${sx.gap}`);
  if (sx.alignItems) classes.push(`items-${sx.alignItems}`);
  if (sx.justifyContent) classes.push(`justify-${sx.justifyContent}`);
  if (sx.flex !== undefined) {
    if (sx.flex === 1) classes.push('flex-1');
    else computedStyle.flex = sx.flex;
  }

  // Handle dimensions
  if (sx.width === '100%') classes.push('w-full');
  else if (sx.width) computedStyle.width = sx.width;

  if (sx.height) {
    if (typeof sx.height === 'number') classes.push(`h-${sx.height}`);
    else computedStyle.height = sx.height;
  }

  // Handle spacing
  if (sx.padding !== undefined) classes.push(`p-${sx.padding}`);
  if (sx.margin !== undefined) classes.push(`m-${sx.margin}`);
  if (sx.marginBottom !== undefined) classes.push(`mb-${sx.marginBottom}`);

  // Handle colors and borders
  if (sx.backgroundColor) computedStyle.backgroundColor = sx.backgroundColor;
  if (sx.borderRadius !== undefined) classes.push(`rounded-${sx.borderRadius}`);

  // Handle transitions and hover states
  if (sx.transition) classes.push('transition-all');
  if (sx['&:hover']) {
    if (sx['&:hover'].transform === 'scale(1.05)') hoverClasses.push('hover:scale-105');
    if (sx['&:hover'].cursor === 'pointer') hoverClasses.push('hover:cursor-pointer');
    if (sx['&:hover'].backgroundColor) {
      computedStyle['--tw-hover-bg'] = sx['&:hover'].backgroundColor;
      hoverClasses.push('hover:bg-[var(--tw-hover-bg)]');
    }
  }

  return (
    <div className={twMerge(classes.join(' '), hoverClasses.join(' '), className)} style={computedStyle}>
      {children}
    </div>
  );
};
