// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {Typography} from '@modules/Core/components/base/Typography';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {logger} from '@modules/Core/util/Logger';
import {isImpersonating} from '@modules/Core/util/rolesAndPermissionsUtil';
import UpgradePlanButton from '@modules/Profile/components/payments/UpgradePlanButton';
import {_ProfileExerciseCE} from '@modules/Profile/types/exercises.model';
import {submitServiceData} from '@modules/Profile/util/exercisesActionsUtil';
import {isServiceChallengesAndGoalsLocked} from '@modules/Profile/util/exercisesUtil';
import {refreshReduxState} from '@modules/State/util/util';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileServiceChallengesItemProps {
  service: string;
  challenges: string;
  goals: string;
  alerts: any;
  profileExercises: _ProfileExerciseCE;
  initialSurveyDone: boolean;
}

export const ProfileServiceChallengesItem: React.FC<_ProfileServiceChallengesItemProps> = ({
  service,
  challenges,
  goals,
  alerts,
  profileExercises,
  initialSurveyDone,
}) => {
  const snackbar = useSnackbar();
  const [allLocked, setAllLocked] = useState<boolean>(false);
  const [innerChallenges, setInnerChallenges] = useState<string | null>(null);
  const [innerGoals, setInnerGoals] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [available, setAvailable] = useState<boolean>(false);
  const [currentRound, setCurrentRound] = useState<number>(0);

  useEffect(() => {
    setAvailable(profileExercises?.rounds?.[profileExercises?.currentRound]?.available);
    const locked = isServiceChallengesAndGoalsLocked(profileExercises);

    // First time, textareas enabeld by default
    if (locked && profileExercises?.currentRound === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (isImpersonating()) {
      setDisabled(true);
    }

    setAllLocked(locked);
    setCurrentRound(profileExercises?.currentRound || 0);
  }, [profileExercises]);

  useEffect(() => {
    if (challenges) {
      setInnerChallenges(challenges);
    }
    if (goals) {
      setInnerGoals(goals);
    }
  }, [challenges, goals]);

  async function onSave(): Promise<void> {
    if (!initialSurveyDone) {
      return;
    }
    try {
      const keys = ['challenges', 'goals'];
      const values = [innerChallenges, innerGoals];
      const response = await submitServiceData(service, keys, values);
      if (!response) {
        return;
      }
      setDisabled(true);
      void refreshReduxState();
    } catch (e) {
      logger.error(e);
      snackbar.danger(trans('base.error'));
    }
  }

  function onChange(key: string, value: string): void {
    if (!initialSurveyDone) {
      return;
    }
    if (key === 'challenge') {
      setInnerChallenges(value);
    } else if (key === 'goals') {
      setInnerGoals(value);
    }
  }

  if (!service) {
    return null;
  }

  const actionNeeded = allLocked && currentRound > 0;
  const isFirstLockedRound = currentRound === 0 && allLocked;

  logger.debug('Challenges Goals: all inputs', {
    allLocked,
    innerChallenges,
    innerGoals,
    disabled,
    available,
    actionNeeded,
    isFirstLockedRound,
    profileExercises,
    locked: isServiceChallengesAndGoalsLocked(profileExercises),
    currentRound,
  });
  return (
    <>
      <div className="flex flex-row w-full gap-3">
        <div className="flex flex-col gap-3 flex-grow relative-col-width-5">
          <Typography variant="h6">{trans('profile_modules.current_challenge')}</Typography>
          <TextareaComponent
            disabled={disabled}
            setDisabled={setDisabled}
            value={innerChallenges}
            setValue={e => onChange('challenge', e)}
          />
        </div>
        <div className="flex flex-col gap-3 flex-grow relative-col-width-5">
          <Typography variant="h6">{trans('profile_modules.current_goals')}</Typography>
          <TextareaComponent
            disabled={disabled}
            setDisabled={setDisabled}
            value={innerGoals}
            setValue={e => onChange('goals', e)}
            isFirstLockedRound={isFirstLockedRound}
          />
        </div>
      </div>

      {!isImpersonating() && (
        <div className="flex flex-row justify-end gap-3">
          <ButtonsComponent
            initialSurveyDone={initialSurveyDone}
            available={available}
            disabled={disabled}
            setDisabled={setDisabled}
            actionNeeded={actionNeeded}
            isFirstLockedRound={isFirstLockedRound}
            onSave={onSave}
            challenges={innerChallenges}
            goals={innerGoals}
          />
        </div>
      )}
    </>
  );
};

interface _TextareaComponentProps {
  value: string | null;
  setValue: (value: string) => void;
  disabled: boolean;
  setDisabled: (value: boolean) => void;
  isFirstLockedRound?: boolean;
}

const TextareaComponent: React.FC<_TextareaComponentProps> = ({value, setValue, disabled}) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setValue(event.target.value);
  };

  return <TextArea rows={3} readOnly={disabled} value={value} onChange={handleChange} />;
};

interface _ButtonsComponentProps {
  initialSurveyDone: boolean;
  available: boolean;
  disabled: boolean;
  setDisabled: (value: boolean) => void;
  actionNeeded: boolean;
  onSave: () => void;
  isFirstLockedRound?: boolean;
  challenges: string | null;
  goals: string | null;
  buttonWidth?: string;
}

const ButtonsComponent: React.FC<_ButtonsComponentProps> = ({
  initialSurveyDone,
  available,
  disabled,
  setDisabled,
  actionNeeded,
  onSave,
  isFirstLockedRound,
  challenges,
  goals,
  buttonWidth = 'relative-col-width-2',
}) => {
  if (!initialSurveyDone) {
    return null;
  }

  if (!available) {
    return <UpgradePlanButton source="profile" label={trans('profile_modules.upgrade_dialog.cta')} />;
  }

  const showEdit = disabled;
  const showKeep = disabled && actionNeeded;
  const showSave = !disabled && !isFirstLockedRound;
  const showUnlock = !disabled && isFirstLockedRound;

  logger.debug('Challenges Goals ALL VARIABLES:', {
    showEdit,
    showKeep,
    showSave,
    showUnlock,
    disabled,
    actionNeeded,
    isFirstLockedRound,
  });
  return (
    <>
      {showEdit && (
        <div className={buttonWidth}>
          <Button onClick={() => setDisabled(false)} variant="secondary">
            {trans('profile_modules.edit')}
          </Button>
        </div>
      )}

      {showKeep && (
        <div className={buttonWidth}>
          <Button
            onClick={() => {
              void onSave();
            }}
            variant="primary"
          >
            {trans('profile_modules.keep')}
          </Button>
        </div>
      )}
      {showSave && (
        <div className={buttonWidth}>
          <Button
            disabled={disabled}
            onClick={() => {
              void onSave();
            }}
            variant="primary"
          >
            {trans('profile_modules.save')}
          </Button>
        </div>
      )}

      {showUnlock && (
        <div className={buttonWidth}>
          <Button
            disabled={!challenges || !goals}
            onClick={() => {
              void onSave();
            }}
            variant="primary"
          >
            {trans('profile_exercises.unlock_exercises')}
          </Button>
        </div>
      )}
    </>
  );
};
