// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconEditText} from '@modules/Core/components/base/Icons/icons/IconEditText';
import {Table, _TableDataItem} from '@modules/Core/components/base/table/Table';
import {Typography} from '@modules/Core/components/base/Typography';
import DialogDatePicker from '@modules/Core/components/dialogs/DialogDatePicker';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {trans} from '@modules/Translations/util/i18n';
import {BaseStep} from '../Base/BaseStep';

interface EmailDates {
  email: string;
  dates: string[];
  rounds: number;
}

interface MultipleDatesStepOptions extends _BaseStepComponentOptions {
  emails: string[];
  rounds: number;
  emailDates?: Record<string, string[]>;
}

interface RoundData {
  date: string;
  onClick: () => void;
}

interface TableRowData {
  feedback_for: string;
  [key: string]: RoundData | string;
}

export const MultipleDatesStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const typedOptions = options as MultipleDatesStepOptions;
  const {emails, rounds, emailDates: initialEmailDates} = typedOptions ?? {};

  const [emailDates, setEmailDates] = useState<Record<string, string[]>>(() => {
    // Start with any initial email dates from options
    const baseEmailDates = initialEmailDates || {};

    // Add any missing emails with empty date arrays
    return (
      emails?.reduce<Record<string, string[]>>((acc, email) => {
        acc[email] = baseEmailDates[email] || [];
        return acc;
      }, {}) || {}
    );
  });

  const [openDialog, setOpenDialog] = useState<{email: string; index: number} | null>(null);

  const getValuesCallback = (): Record<string, any> => ({
    emailDates,
  });

  logger.debug('[MultipleDatesStep] options', {
    options: typedOptions,
    emailDates,
  });

  const handleDateSelect = (confirmed: boolean, selectedDates?: Date[]) => {
    if (confirmed && selectedDates?.length && openDialog) {
      const {email, index} = openDialog;
      setEmailDates(prev => {
        const newDates = {...prev};
        const selectedDate = selectedDates[0].toISOString();

        // Check if this round didn't have a date set for the current email
        const currentEmailHasNoDate = !prev[email]?.[index];

        // If the current email has no date set for this round, check if it's the first date for the round
        if (currentEmailHasNoDate) {
          const isFirstDateForRound = Object.values(prev).every(dates => !dates[index]);

          if (isFirstDateForRound) {
            // Set the same date for all emails in this round
            emails?.forEach(emailAddress => {
              if (!newDates[emailAddress]) {
                newDates[emailAddress] = [];
              }
              newDates[emailAddress] = [...(newDates[emailAddress] || [])];
              newDates[emailAddress][index] = selectedDate;
            });
          } else {
            // Just update the selected email's date
            if (!newDates[email]) {
              newDates[email] = [];
            }
            newDates[email] = [...(newDates[email] || [])];
            newDates[email][index] = selectedDate;
          }
        } else {
          // Updating an existing date - only update the selected email
          if (!newDates[email]) {
            newDates[email] = [];
          }
          newDates[email] = [...(newDates[email] || [])];
          newDates[email][index] = selectedDate;
        }

        return newDates;
      });
    }
    setOpenDialog(null);
  };

  const handleDateClick = (email: string, index: number) => {
    setOpenDialog({email, index});
  };

  const handleResetAll = () => {
    setEmailDates(
      emails?.reduce<Record<string, string[]>>((acc, email) => {
        acc[email] = [];
        return acc;
      }, {}) || {}
    );
  };

  const columns = [
    {
      key: 'feedback_for',
      label: trans('calendar.feedback_for'),
    },
    ...Array.from({length: rounds}).map((_, index) => ({
      key: `round_${index + 1}`,
      label: trans('calendar.round_date', {number: index + 1}),
    })),
  ];

  const tableData = emails?.map(email => ({
    feedback_for: email,
    ...Array.from({length: rounds}).reduce<Record<string, RoundData>>((acc, _, index) => {
      acc[`round_${index + 1}`] = {
        date: emailDates[email]?.[index] ? new Date(emailDates[email][index]).toLocaleDateString() : '',
        onClick: () => handleDateClick(email, index),
      };
      return acc;
    }, {}),
  }));

  const renderRow = (row: TableRowData): _TableDataItem => {
    const processedRow: Record<string, string | JSX.Element> = {
      feedback_for: row.feedback_for,
    };

    for (let i = 1; i <= rounds; i++) {
      const roundKey = `round_${i}`;
      const roundData = row[roundKey] as RoundData;
      processedRow[roundKey] = (
        <div
          onClick={roundData.onClick}
          className="flex gap-2 w-full border border-gray-300 rounded cursor-pointer hover:bg-gray-50"
        >
          {roundData.date && <span>{roundData.date}</span>}
          {!roundData.date && <IconEditText size="sm" />}
        </div>
      );
    }

    return processedRow;
  };

  return (
    <BaseStep getValueCallbackFn={getValuesCallback} inputs={[emailDates]} options={options}>
      <Section
        title={options.title}
        description={options.description}
        titleVariant={options.titleVariant || 'h5'}
        descriptionVariant={options.descriptionVariant || 'body'}
        gap={5}
      >
        <div className="flex justify-end mb-4">
          <Button
            variant="secondary"
            onClick={handleResetAll}
            size="sm"
            label={trans('calendar.reset_all_dates')}
            width="relative-col-width-2"
          />
        </div>
        <Table data={tableData || []} columns={columns} renderRow={renderRow} showTopBorder={false} />
      </Section>
      {openDialog && (
        <DialogDatePicker
          open
          onClose={handleDateSelect}
          initialDates={
            emailDates[openDialog.email]?.[openDialog.index]
              ? [new Date(emailDates[openDialog.email][openDialog.index])]
              : []
          }
          multiSelect={false}
        />
      )}
    </BaseStep>
  );
};
