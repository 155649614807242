// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {flowRoute} from '@/_core/util/util';
import DatePicker from '@modules/Core/components/base/datepicker/DatePicker';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {RadioButton} from '@modules/Core/components/base/inputs/RadioButton';
import {Typography} from '@modules/Core/components/base/Typography';
import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {invokeAction} from '@modules/Core/util/actionsUtil';
import {generateICS} from '@modules/Core/util/icsGenerator';
import {runAsync} from '@modules/Core/util/runAsync';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useExercises} from '@modules/Profile/hooks/exercises';
import {useProfile} from '@modules/Profile/hooks/profile';
import {createExerciseData, getProfileExercises} from '@modules/Profile/util/exercisesUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileAddToCalendarDialogProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  service: string;
  exerciseIndex: number;
  round: number;
  collectedData: Record<string, any> | null;
}

// TODO:cleanup fns to util class
const ProfileAddToCalendarDialog: React.FC<_ProfileAddToCalendarDialogProps> = ({
  open,
  onClose,
  service,
  exerciseIndex,
  round,
  collectedData,
}) => {
  const exercisesData = useExercises();
  const {currentProfile} = useProfile();
  const [currentProfileExercises, setCurrentProfileExercises] = useState(null);
  const [step, setStep] = useState<number>(0);

  // const calendarIntegrationValues = [
  //   {value: 'google', label: trans('calendar.google'), icon: IconGoogle},
  //   {value: 'outlook', label: trans('calendar.outlook'), icon: IconOutlook},
  //   {value: 'apple', label: trans('calendar.apple'), icon: IconApple},
  // ];

  const [show, setShow] = useState<boolean>(true);
  // const [calendarIntegration, setCalendarIntegration] = useState<string>(null);
  const [dates, setDates] = useState<Date[]>([]);

  // Dirty, but fuck it it's one component

  useEffect(() => {
    setStep(0);
  }, [open]);

  useEffect(() => {
    const profileExercies = getProfileExercises(currentProfile);
    setCurrentProfileExercises(profileExercies);
  }, [currentProfile, service]);

  const handleAddToCalendar = (): void => {
    setStep(1);
  };

  const handleAddToCalendar2 = async (): Promise<void> => {
    dates.forEach((date: Date, index) => {
      const start = new Date(date.getTime());
      const end = new Date(date.getTime());
      start.setHours(9, 0, 0);
      end.setHours(10, 0, 0);
      void addEventToCalendar(start, end, index === dates?.length - 1);
    });
  };

  const addEventToCalendar = async (startDate: Date, endDate: Date, isLast: boolean = false): Promise<void> => {
    const currentExercises = createExerciseData(service, exercisesData?.[service], currentProfileExercises?.[service]);
    const currentExercise = currentExercises.exercises[round][exerciseIndex];

    const title = trans('profile_exercises.calendar.event_title', {
      softfactTitle: trans(`service.${service}`),
      roundIndex: round + 1,
      exercise: trans(currentExercise.intro.title),
    });

    const description = trans('profile_exercises.calendar.event_details', {
      exerciseUrl:
        window.location.origin +
        flowRoute(FLOWS.ProfileExercises, null, {
          round,
          exerciseIndex,
          service,
        }),
    });

    const response = await invokeAction(
      'send.calendar.invite',
      {
        title,
        description,
        icsContent: generateICS({
          title,
          description,
          startDate,
          endDate,
          attendeeEmail: currentProfile?.email,
          attendeeName: currentProfile?.fullName,
        }),
      },
      {
        showSnackbar: isLast,
      }
    );

    if (isLast && response?.status === ACTION_STATUS_SUCCESS) {
      onClose(true);
    }
  };

  const estimatedTimeMap: Record<string, number> = {
    '1_week': 7,
    '2_weeks': 14,
    '3_weeks': 21,
    '1_month': 30,
  };

  useEffect(() => {
    if (collectedData) {
      const lastDate = new Date(
        new Date().setDate(new Date().getDate() + estimatedTimeMap[collectedData.estimatedTime ?? '1_week'])
      );
      setDates([lastDate]);
    }
  }, [collectedData]);

  switch (step) {
    case 1:
      return (
        <Dialog
          title={trans('calendar.add_to_calendar_dialog_title')}
          open={open}
          showCloseButton
          onClose={() => onClose(false)}
          onConfirm={() => runAsync(handleAddToCalendar2)}
          confirmLabel={trans('calendar.add_to_calendar_confirm_button')}
        >
          <Typography variant="body">{trans('calendar.add_to_calendar_dialog_description')}</Typography>
          <DatePicker show={show} setShow={setShow} dates={dates} setDates={setDates} />
        </Dialog>
      );

    default:
      return (
        <Dialog
          open={open}
          showCloseButton
          onClose={() => onClose(false)}
          confirmLabel={trans('profile_exercises.calendar.cta')}
          onConfirm={handleAddToCalendar}
          cancelLabel={trans('profile_exercises.close_go_to_exercise')}
          onCancel={() => onClose(true)}
          image="/assets/img/design_images/calendar.svg"
          imageProps={{style: {height: '348px'}}}
          title={trans('profile_exercises.calendar.title')}
          justifyButtons="center"
          justifyContent="center"
        >
          <Typography variant="body1" className="text-center">
            {trans('profile_exercises.calendar.message')}
          </Typography>
        </Dialog>
      );
  }
};

interface _CalendarIntegrations {
  calendarIntegrationValues: Array<{value: string; label: string}>;
  calendarIntegration: string;
  setCalendarIntegration: (value: string) => void;
}

const CalendarIntegrations: React.FC<_CalendarIntegrations> = ({
  calendarIntegrationValues,
  calendarIntegration,
  setCalendarIntegration,
}) => {
  return (
    <div className="flex flex-row gap-3">
      {calendarIntegrationValues.map(option => (
        <div className="flex-grow">
          <RadioButton
            label={
              <div className="flex flex-row items-center justify-center gap-1">
                {option.icon && <option.icon />}
                {option.label}
              </div>
            }
            active={calendarIntegration === option.value}
            onChange={() => setCalendarIntegration(option.value)}
          />
        </div>
      ))}
    </div>
  );
};

export default ProfileAddToCalendarDialog;
