// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {_TypographyVariants} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {trans} from '@modules/Translations/util/i18n';

interface FormField {
  name: string;
  type: string;
  placeholder?: string;
  label?: string;
  value?: string;
}

interface _FormItemProps {
  headline?: string;
  headlineVariant?: _TypographyVariants;
  description?: string;
  descriptionVariant?: _TypographyVariants;
  fields: FormField[];
  onSubmit?: (values: Record<string, string>) => void;
}

export const FormItem: React.FC<_FormItemProps> = ({
  headline,
  headlineVariant = 'h6',
  description,
  descriptionVariant,
  fields,
  onSubmit,
}) => {
  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Initialize form values
    const initialValues: Record<string, string> = {};
    fields.forEach(field => {
      initialValues[field.name] = field.value ?? '';
    });
    setFormValues(initialValues);
  }, [fields]);

  const handleFieldChange = (name: string, value: string) => {
    setFormValues(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(formValues);
    }
  };

  const renderField = (field: FormField) => {
    switch (field.type.toLowerCase()) {
      case 'textarea':
        return (
          <div key={field.name} className="flex flex-col gap-2">
            <TextArea
              value={formValues[field.name] || ''}
              onChange={e => handleFieldChange(field.name, e.target.value)}
              placeholder={field.placeholder}
              label={field.label}
            />
          </div>
        );
      case 'text':
      default:
        return (
          <div key={field.name} className="flex flex-col gap-2">
            <Input
              value={formValues[field.name] || ''}
              onChange={v => handleFieldChange(field.name, v)}
              placeholder={field.placeholder}
              label={field.label}
            />
          </div>
        );
    }
  };

  if (loading) {
    return (
      <Section>
        <div className="text-body2">{trans('common.loading')}</div>
      </Section>
    );
  }

  return (
    <Section
      title={headline}
      titleVariant={headlineVariant}
      description={description}
      descriptionVariant={descriptionVariant}
    >
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          {fields.map(field => renderField(field))}

          <div className="flex flex-row justify-end w-full items-center mt-4">
            <div className="relative-col-width-2">
              <Button label={trans('base.save')} type="submit" />
            </div>
          </div>
        </div>
      </form>
    </Section>
  );
};
