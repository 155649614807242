// @ts-nocheck
/* eslint-disable */

import React, {JSX, useEffect, useRef, useState} from 'react';
import {isEqual} from 'lodash';
import {BaseContentEngineItem} from '@modules/ContentEngine/components/base/BaseContentEngineItem';
import {contentEngineViews} from '@modules/ContentEngine/config/contentEngineViews';
import {loadContent} from '@modules/ContentEngine/services/api';
import {Group} from '@modules/Core/components/layout/Group';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {addReactEventListener, dispatchReactEvent, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {CONTENT_RELOADED_APP_EVENT} from '@modules/Navigation/hooks/navigationInit';
import {ProfileAlerts} from '@modules/Profile/components/alerts/ProfileAlerts';
import {ProfileOneTimeNotificationsFlow} from '@modules/Profile/components/dialogs/ProfileOneTimeNotificationsFlow';
import {ProfileTutorials} from '@modules/Profile/components/tutorials/ProfileTutorials';
import {useProfile} from '@modules/Profile/hooks/profile';
import {useLanguage} from '@modules/Translations/hooks/language';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';
import {_ContentEngineItem} from '../../types/contentEngineItem.model';
import {_ContentEngineSchema, _ContentEngineViews, _ProfileOneTimeNotification} from '../../types/core.model';

interface _Props {
  type: string;
  params?: Record<string, any>;
  withLoadingIndicator?: boolean;
  dynamicParams?: Record<string, any>;
  unauthorized?: boolean;
  reloadKey?: string;
}

interface _BodyProps {
  jsonContent: _ContentEngineSchema | null;
  dynamicParams?: Record<string, any>;
}

export const ContentEngineContainer: React.FC<_Props> = ({
  unauthorized = false,
  type,
  params,
  dynamicParams = {},
  withLoadingIndicator = false,
  reloadKey,
}): JSX.Element => {
  const jsonContent = useLoadContent({reloadKey, type, params, withLoadingIndicator, unauthorized});

  return <ContentEngineJsonContainer jsonContent={jsonContent} dynamicParams={dynamicParams} />;
};

export const ContentEngineJsonContainer: React.FC<_BodyProps> = ({jsonContent, dynamicParams}): JSX.Element => {
  const [items, setItems] = useState<_ContentEngineItem[]>([]);
  const [alerts, setAlerts] = useState<_ProfileAlert[]>([]);
  const [tutorials, setTutorials] = useState<Record<string, _Tutorial>>({});
  const [oneTimeNotifications, setOneTimeNotifications] = useState<_ProfileOneTimeNotification[]>([]);
  const [alertsDone, setAlertsDone] = useState<boolean>(false);
  const [notificationsDone, setNotificationsDone] = useState<boolean>(false);
  const [tutorialDone, setTutorialDone] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<_ContentEngineViews>('default');

  useEffect(() => {
    setItems(jsonContent?.items ?? []);
    setAlerts(jsonContent?.alerts ?? []);
    setTutorials(jsonContent?.tutorials ?? {});
    setOneTimeNotifications(jsonContent?.oneTimeNotifications ?? []);
    setCurrentView(jsonContent?.view ?? 'default');
  }, [jsonContent]);

  const isAlertsDone = alertsDone || !alerts?.length;
  const isNotificationsDone = notificationsDone || !oneTimeNotifications?.length;
  logger.debug('ContentEngineContainer: Rendering', {
    items,
    alerts,
    tutorials,
    oneTimeNotifications,
    isAlertsDone,
    isNotificationsDone,
    tutorialDone,
    jsonContent,
  });

  const currentViewConfig = contentEngineViews[currentView] ?? contentEngineViews.default;
  return (
    <>
      <ProfileAlerts alerts={alerts} />
      <ProfileTutorials tutorials={tutorials} />

      {Boolean(isAlertsDone) && (
        <ProfileOneTimeNotificationsFlow
          notifications={oneTimeNotifications}
          onDone={() => setNotificationsDone(true)}
        />
      )}
      <Group {...currentViewConfig}>
        {(items || []).map(item => (
          <BaseContentEngineItem key={item.uuid} item={item} tutorials={tutorials} dynamicParams={dynamicParams} />
        ))}
      </Group>
    </>
  );
};

interface _ContentProps {
  type: string;
  params?: Record<string, any>;
  withLoadingIndicator: boolean;
  unauthorized?: boolean;
  reloadKey?: string;
}

function useLoadContent({
  unauthorized,
  type,
  params,
  withLoadingIndicator,
  reloadKey,
}: _ContentProps): _ContentEngineSchema | null {
  const {currentProfile} = useProfile();
  const {currentLanguage} = useLanguage();
  const lastLoadedType = useRef<string | null>();
  const lastLoadedLanguage = useRef<string | null>();
  const lastDataUpdated = useRef<string | null>();
  const lastParams = useRef<Record<string, any> | null>();

  const [content, setContent] = useState<_ContentEngineSchema | null>(null);

  useEffect(() => {
    const reloadContent = async (event: any = {}): Promise<void> => {
      if (event?.detail?.key && event.detail.key !== reloadKey) {
        logger.info('[ContentEngineContainer]: Skipping reload', event.detail.key, reloadKey);
        return;
      }
      await doLoadContent(false);
    };
    addReactEventListener('reload.content', reloadContent);
    return () => {
      removeReactEventListener('reload.content', reloadContent);
    };
  }, []);

  useEffectAsync(async () => {
    if (
      lastLoadedType.current === type &&
      lastLoadedLanguage.current === currentLanguage &&
      lastDataUpdated.current === currentProfile?.dataLastUpdated &&
      isEqual(lastParams.current, params)
    ) {
      return;
    }
    logger.debug('[ContentEngineContainer]: Loading content', [type, currentLanguage]);
    lastLoadedType.current = type;
    lastLoadedLanguage.current = currentLanguage;
    lastDataUpdated.current = currentProfile?.dataLastUpdated;
    lastParams.current = params;
    await doLoadContent(withLoadingIndicator);
  }, [type, currentLanguage, params]);

  const doLoadContent = async (loadingIndicator: boolean): Promise<void> => {
    try {
      dispatchReactEvent('navigate.load', `content-loaded: ${type}`);
      const response = await loadContent(type, params, loadingIndicator, unauthorized);
      setContent(response.data as _ContentEngineSchema);
      dispatchReactEvent('navigate.done', `content-loaded: ${type}`);
      dispatchReactEvent(CONTENT_RELOADED_APP_EVENT, `content-loaded: ${type}`);
    } catch (error: any) {
      // handled by AuthTokenInterceptor => errorMessage()
    }
  };
  return content;
}
