// @ts-nocheck
/* eslint-disable */

interface ProfileInfo {
  id: string;
  email: string;
  fullName: string;
  profilePicture: string | null;
  teams: Array<{
    id: string;
    name: string;
  }>;
}

interface TeamInfo {
  id: string;
  name: string;
  profiles: Array<{
    id: string;
    fullName: string;
    profilePicture: string | null;
    email: string;
  }>;
}

/**
 * Extracts profile information including full name, profile picture, and teams from institution data
 * @param institutionData - The data returned from currentInstitution()
 * @returns Array of profile information objects
 */
export const extractProfilesInfo = (institutionData: any): ProfileInfo[] => {
  if (!institutionData?.profiles) {
    return [];
  }

  return Object.entries(institutionData.profiles).map(([id, profile]: [string, any]) => ({
    id,
    email: profile?.email ?? '',
    fullName: profile?.fullName ?? '',
    profilePicture: profile?.profilePicture ?? null,
    teams: Array.isArray(profile?.teams)
      ? profile.teams.map((team: any) => ({
          id: team?.id ?? '',
          name: team?.name ?? '',
        }))
      : [],
  }));
};

/**
 * Extracts team information including id, name, and associated profiles from institution data
 * @param institutionData - The data returned from currentInstitution()
 * @returns Array of team information objects
 */
export const extractTeamsInfo = (institutionData: any): TeamInfo[] => {
  const profiles = extractProfilesInfo(institutionData);
  const teamsMap = new Map<string, TeamInfo>();

  // Build teams map from profile team information
  profiles.forEach(profile => {
    profile.teams.forEach(team => {
      if (!teamsMap.has(team.id)) {
        teamsMap.set(team.id, {
          id: team.id,
          name: team.name,
          profiles: [],
        });
      }

      const teamInfo = teamsMap.get(team.id);
      if (teamInfo) {
        teamInfo.profiles.push({
          id: profile.id,
          fullName: profile.fullName,
          profilePicture: profile.profilePicture,
          email: profile.email,
        });
      }
    });
  });

  return Array.from(teamsMap.values());
};
