// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {logger} from '@modules/Core/util/Logger';
import {_Team} from '@modules/Team/types/team.model';

export interface _TeamState {
  currentTeam: _Team | null;
}

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    currentTeam: null,
  } satisfies _TeamState,
  reducers: {
    setCurrentTeam(state, action) {
      logger.info('[TeamSlice] Saving team.');
      state.currentTeam = action.payload;
    },
    resetCurrentTeam(state) {
      logger.info('[TeamSlice] Resetting team.');
      state.currentTeam = null;
    },
  },
});

export const {setCurrentTeam, resetCurrentTeam} = teamSlice.actions;
export default teamSlice;

export function changeCurrentTeam(teamId: string | null = null) {
  return async (dispatch: any, getState: any) => {
    logger.info('[TeamSlice] Changing current team.', {teamId});

    if (!teamId) {
      teamId = getState()?.team?.currentTeam?.id;
    }

    if (!teamId) {
      return;
    }

    // Find the team in the existing teams list
    const teams = getState()?.teams?.currentTeams || [];
    const team = teams.find((t: _Team) => t.id === teamId);

    if (team) {
      dispatch(setCurrentTeam(team));
    }
  };
}
