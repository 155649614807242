// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {WYSIWYG} from '@modules/Core/components/base/inputs/WYSIWYG';
import './Tasks.scss';

interface _RichTextTaskEditViewProps {
  value: string | null;
  onChange: (val: string) => void;
}

export const RichTextTaskEditView: React.FC<_RichTextTaskEditViewProps> = ({value, onChange}) => {
  return (
    <div>
      <WYSIWYG value={value} onChange={onChange} />
    </div>
  );
};
