// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {getAllJournals} from '@modules/Profile/services/journalsApi';
import {_JournalFilterParams} from '@modules/Profile/types/journal.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {currentProfile} from './profileUtil';

export const setProfileAlertDoneAction = async (alertId: string): Promise<any> => {
  const method = 'profile.alert.done'; // Backend method name
  const params = {
    alertId,
  };

  try {
    // Call the performFlowAction function with the method and parameters
    const response = await performFlowAction({
      method,
      params,
    });

    // Extract the response data
    const responseData = response?.data;

    // Check if the response indicates a successful operation
    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data;
    }

    // Log an error if the operation was not successful
    logger.error(
      'Start Service Next Round Action failed:',
      responseData?.status === 'error' ? responseData.message : 'Unknown error'
    );
    return null;
  } catch (e) {
    // Log any errors encountered during the operation
    logger.error('Start Service Next Round Action encountered an error:', e);
    return null;
  }
};

export const setProfileAlertsDoneAction = async (alertIds: string[]): Promise<any> => {
  const method = 'profile.alerts.done'; // Backend method name for batch operation
  const params = {
    alertIds, // Pass the array of alert IDs
  };

  try {
    // Call the performFlowAction function with the method and parameters
    const response = await performFlowAction({
      method,
      params,
    });

    // Extract the response data
    const responseData = response?.data;

    // Check if the response indicates a successful operation
    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data;
    }

    // Log an error if the operation was not successful
    logger.error(
      'Set Profile Alerts Done Action failed:',
      responseData?.status === 'error' ? responseData.message : 'Unknown error'
    );
    return null;
  } catch (e) {
    // Log any errors encountered during the operation
    logger.error('Set Profile Alerts Done Action encountered an error:', e);
    return null;
  }
};

export async function addNewJournal(
  category: string,
  teamId: string,
  journal: string,
  metadata: Record<string, any>,
  group: string,
  title?: string,
  targetUserEmail?: string
): Promise<boolean> {
  if (!category) {
    return false;
  }

  const method = 'journal.add';
  const params = {
    category,
    teamId,
    journal,
    metadata,
    group,
    title,
    targetUserEmail,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {refetchProfile: true}
    );

    return response?.data?.status === 'success';
  } catch (e) {
    console.error('Error adding journal:', e);
    return false;
  }
}

export async function deleteJournal(journalId: string): Promise<boolean> {
  if (!journalId) {
    return false;
  }

  const method = 'journal.delete';
  const params = {
    journalId,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {refetchProfile: true}
    );

    return response?.data?.status === 'success';
  } catch (e) {
    logger.error(e);
    return false;
  }
}

export async function updateJournal(
  journalId: string,
  journal: string,
  category: string,
  title?: string,
  metadata?: Record<string, any>,
  teamId?: string
): Promise<boolean> {
  if (!journalId || !category) {
    return false;
  }

  const method = 'journal.update';
  const params = {
    journalId,
    journal,
    category,
    title,
    metadata,
    teamId,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {refetchProfile: true}
    );

    return response?.data?.status === 'success';
  } catch (e) {
    console.error('Error updating journal:', e);
    return false;
  }
}

export async function addJournalComment(journalId: string, content: string): Promise<boolean> {
  if (!journalId || !content.trim()) {
    return false;
  }

  const method = 'journal.comment.add';
  const params = {
    journalId,
    content,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {refetchProfile: true}
    );

    return response?.data?.status === 'success';
  } catch (e) {
    console.error('Error adding journal comment:', e);
    return false;
  }
}

export function getServiceCategory(service: string): string | null {
  if (!service) {
    return null;
  }
  return `soft_facts.${service}`;
}

export async function getJournalsList(filterParams: _JournalFilterParams): Promise<any> {
  try {
    const response = await getAllJournals(filterParams);
    return response?.data?.journals;
  } catch (err) {
    logger.error(err);
    return [];
  }
}

export function hasJournals(profile: _Profile | null): boolean {
  profile = profile ?? currentProfile();
  return !!profile?.hasJournals;
}

/**
 * Process HTML content to enhance images with scaling and click handlers
 */
export function processJournalHtml(html: string): string {
  if (!html) return '';

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // First enhance all images with basic styles
  const allImages = doc.getElementsByTagName('img');
  Array.from(allImages).forEach(img => {
    img.classList.add('cursor-pointer', 'hover:opacity-90', 'transition-opacity');
    img.setAttribute('data-journal-image', 'true');
    img.style.maxWidth = '400px';
    img.style.maxHeight = '300px';
    img.style.objectFit = 'contain';
    img.style.backgroundColor = 'white';
    img.style.padding = '2px';
    img.style.border = '1px solid #e0e0e0';
    img.style.borderRadius = '4px';
  });

  // Enhance all links with specified classes
  const allLinks = doc.getElementsByTagName('a');
  Array.from(allLinks).forEach(link => {
    link.classList.add('text-prussian300', 'hover:text-brand700', 'underline');
  });

  // Function to check if paragraph contains only images and whitespace
  const isImageOnlyParagraph = (p: HTMLElement): boolean => {
    return Array.from(p.childNodes).every(
      node =>
        (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'IMG') ||
        (node.nodeType === Node.TEXT_NODE && !node.textContent?.trim())
    );
  };

  // Function to get all images from a paragraph
  const getImagesFromParagraph = (p: HTMLElement): HTMLImageElement[] => {
    return Array.from(p.getElementsByTagName('img'));
  };

  // Process paragraphs
  const paragraphs = doc.getElementsByTagName('p');
  let i = 0;
  while (i < paragraphs.length) {
    const currentP = paragraphs[i];

    // Skip if paragraph doesn't contain only images
    if (!isImageOnlyParagraph(currentP)) {
      i++;
      continue;
    }

    // Collect consecutive image-only paragraphs
    const consecutiveParagraphs = [currentP];
    let nextIndex = i + 1;
    while (nextIndex < paragraphs.length && isImageOnlyParagraph(paragraphs[nextIndex])) {
      consecutiveParagraphs.push(paragraphs[nextIndex]);
      nextIndex++;
    }

    // If we have multiple paragraphs with images or multiple images in one paragraph
    const allImages = consecutiveParagraphs.flatMap(p => getImagesFromParagraph(p));
    if (allImages.length > 1) {
      // Create container for all images
      const container = doc.createElement('div');
      container.className = 'flex flex-row flex-wrap gap-2 my-2';

      // Process all images
      allImages.forEach(img => {
        const wrapper = doc.createElement('div');
        wrapper.className = 'flex-shrink-0';

        // Clone and style the image
        const clonedImg = img.cloneNode(true) as HTMLImageElement;
        clonedImg.style.width = '200px';
        clonedImg.style.height = '200px';

        wrapper.appendChild(clonedImg);
        container.appendChild(wrapper);
      });

      // Replace first paragraph with container and remove others
      currentP.parentNode?.replaceChild(container, currentP);
      consecutiveParagraphs.slice(1).forEach(p => p.parentNode?.removeChild(p));

      i = nextIndex;
    } else {
      i++;
    }
  }

  return doc.body.innerHTML;
}
