// @ts-nocheck
/* eslint-disable */

import React, {ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

export interface TagProps {
  /** The content to be displayed inside the tag */
  children: ReactNode;
  /** Optional className for additional styling */
  className?: string;
  /** Background color class (Tailwind) */
  bgColor?: string;
  /** Text color class (Tailwind) */
  textColor?: string;
  /** Optional click handler */
  onClick?: () => void;
  /** Whether the tag should truncate long content */
  truncate?: boolean;
  /** Whether the tag should use compact styling */
  compact?: boolean;
}

export const Tag: React.FC<TagProps> = ({
  children,
  className,
  bgColor = 'bg-[#f0b856]', // updated default color
  textColor = 'text-[#f9f9f9]', // updated default color
  onClick,
  truncate = false,
  compact = false,
}) => {
  return (
    <div
      onClick={onClick}
      className={twMerge(
        'inline-flex flex-col items-center justify-center',
        compact ? 'py-1 px-2 h-auto' : 'h-3 p-2',
        'rounded-sm',
        'text-xs font-normal leading-3',
        bgColor,
        textColor,
        truncate && 'truncate',
        onClick && 'cursor-pointer',
        className
      )}
    >
      {children}
    </div>
  );
};

// Preset variants for common use cases
export const ProgressTag: React.FC<Omit<TagProps, 'bgColor' | 'textColor'>> = props => (
  <Tag bgColor="bg-[#132E3E]" textColor="text-[#F9F9F9]" {...props} />
);

export const SuccessTag: React.FC<Omit<TagProps, 'bgColor' | 'textColor'>> = props => (
  <Tag bgColor="bg-green-600" textColor="text-white" {...props} />
);

export const WarningTag: React.FC<Omit<TagProps, 'bgColor' | 'textColor'>> = props => (
  <Tag bgColor="bg-yellow-500" textColor="text-white" {...props} />
);

export const ErrorTag: React.FC<Omit<TagProps, 'bgColor' | 'textColor'>> = props => (
  <Tag bgColor="bg-red-600" textColor="text-white" {...props} />
);
