// @ts-nocheck
/* eslint-disable */

import React, {useState, useCallback} from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconCommunication, IconMention, IconMore, IconNote, IconTrash} from '@modules/Core/components/base/Icons/Icons';
import {ContextMenu} from '@modules/Core/components/base/inputs/ContextMenu';
import {Tag} from '@modules/Core/components/base/Tag';
import {Typography} from '@modules/Core/components/base/Typography';
import {UploadedImage} from '@modules/Core/components/base/uploads/MultiImageUpload';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {formatDateTime} from '@modules/Core/util/dates';
import {useInstitution} from '@modules/Institution/hooks/Institution';
import {JournalCommentDialog} from '@modules/Profile/components/journals/JournalCommentDialog';
import {JournalImageDialog} from '@modules/Profile/components/journals/JournalImageDialog';
import {JournalTitle} from '@modules/Profile/components/journals/JournalTitle';
import {_Journal} from '@modules/Profile/types/journal.model';
import {deleteJournal, processJournalHtml} from '@modules/Profile/util/journalUtil';
import {currentProfile, getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';
import './JournalContent.scss';

interface _Content {
  data: Array<
    _Journal & {
      categoryLabel?: string;
      groupLabel?: string;
      profile_id?: string;
      profile_email?: string;
      organisation_name?: string;
      sharedWithTeam?: boolean;
    }
  >;
  onUpdate?: () => void;
  onEditJournal?: (journal: _Journal) => void;
  view?: string;
}

export const JournalContent: React.FC<_Content> = ({data, onUpdate, onEditJournal, view = 'normal'}) => {
  const snackbar = useSnackbar();
  const dialog = useDialog();
  const [selectedJournal, setSelectedJournal] = useState<_Journal | null>(null);
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const {profiles} = useInstitution();

  const handleImageClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (target.tagName === 'IMG' && target.getAttribute('data-journal-image') === 'true') {
      setSelectedImage(target.getAttribute('src'));
    }
  }, []);

  function onEditJournalClick(journal: _Journal): void {
    onEditJournal?.(journal);
  }

  async function onDeleteConfirmed(journalId: string): Promise<void> {
    const success = await deleteJournal(journalId);

    if (success) {
      snackbar.success(trans('journal.delete_journal'));
      onUpdate?.();
    }
  }

  function onDeleteJournalClick(journalId: string): void {
    dialog.show({
      title: trans('journal.delete_journal_title'),
      onConfirm: () => {
        void onDeleteConfirmed(journalId);
      },
    });
  }

  function handleOpenComments(journal: _Journal): void {
    setSelectedJournal(journal);
    setIsCommentDialogOpen(true);
  }

  // Function to open the image dialog when clicking on a gallery image
  const handleGalleryImageClick = (imageUrl: string): void => {
    setSelectedImage(imageUrl);
  };

  const enableComments = true;

  // Helper function to check if a profile has a coach role
  const isCoach = (profileId: string) => {
    return profileId !== currentProfile()?.id;
  };

  return (
    <>
      <div className={`flex ${view === 'dashboard' ? 'flex-row flex-wrap gap-3' : 'flex-col gap-3'}`}>
        {data.map(note => (
          <div
            key={note.id}
            className={`${view === 'dashboard' ? 'relative-col-width-3-75' : 'w-full'} flex flex-col gap-3 p-2 rounded-sm border-1 border-grey100`}
          >
            <div className="flex flex-col flex-grow">
              <div className="flex flex-row justify-between items-center">
                <div className="flex-grow">
                  {note.title ? (
                    <JournalTitle title={note.title} />
                  ) : (
                    <JournalTitle
                      title={
                        note.groupLabel ? `${note.groupLabel}: ${note.categoryLabel || ''}` : note.categoryLabel || ''
                      }
                    />
                  )}
                </div>
                {view === 'normal' && (
                  <div className="flex flex-row gap-1 shrink-0">
                    {note.sharedWithTeam && (
                      <Tag bgColor="bg-blue100" compact>
                        {trans('journal.shared_with_team_tag')}
                      </Tag>
                    )}
                    {note.organisation_name && (
                      <Tag bgColor="bg-blue100" compact>
                        {note.organisation_name}
                      </Tag>
                    )}
                    {note.groupLabel && (
                      <Tag bgColor="bg-blue100" compact>
                        {note.groupLabel}
                      </Tag>
                    )}
                    {note.categoryLabel && (
                      <Tag bgColor="bg-blue100" compact>
                        {note.categoryLabel}
                      </Tag>
                    )}
                    {note.created_by && isCoach(note.created_by) && (
                      <Tag bgColor="bg-brand700" compact>
                        {trans('journal.coach_tag')}
                      </Tag>
                    )}
                  </div>
                )}
              </div>
              <div onClick={handleImageClick} className="mt-2">
                <Typography
                  className="journal-content"
                  style={{
                    whiteSpace: 'pre-line',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                  dangerouslySetInnerHTML={{__html: processJournalHtml(note.journal)}}
                />
              </div>

              {/* Image Gallery */}
              {note.metadata?.images && note.metadata.images.length > 0 && (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-2">
                  {(note.metadata.images as UploadedImage[]).map((image, index) => (
                    <div
                      key={image.id}
                      className="cursor-pointer relative group"
                      onClick={() => handleGalleryImageClick(image.sizes.large || image.original)}
                    >
                      <img
                        src={image.sizes.medium || image.original}
                        alt={`Journal image ${index + 1}`}
                        className="w-full h-32 object-cover rounded-xs border border-grey300"
                      />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="bg-black opacity-0 group-hover:opacity-70 rounded-xs w-16 h-5 flex items-center justify-center transition-all duration-200">
                          <span className="text-white opacity-0 group-hover:opacity-100 transition-opacity text-xs">
                            {trans('upload.journal_view_image')}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="flex flex-row justify-between items-center mt-2">
                <div className="flex items-center gap-2">
                  {note.created_by && profiles[note.created_by] && (
                    <AvatarUser
                      size="sm"
                      image={getProfilePic(profiles[note.created_by])}
                      tooltip={profiles[note.created_by].fullName}
                    />
                  )}
                  {note.date && <Typography>{formatDateTime(new Date(note.date))}</Typography>}
                  {note.profile_id && note.profile_id !== currentProfile()?.id && (
                    <Tag bgColor="bg-blue100" compact>
                      {note.profile_email}
                    </Tag>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <div className="relative">
                    {enableComments && (
                      <IconButton onClick={() => handleOpenComments(note)} icon={IconCommunication} size="sm" />
                    )}
                    {note.comments?.length ? (
                      <div className="absolute top-[-6px] right-[-6px] bg-blue700 text-white rounded-full w-2 h-2 text-xs flex items-center justify-center">
                        {note.comments.length}
                      </div>
                    ) : null}
                  </div>
                  <ContextMenu
                    triggerIcon={IconMore}
                    menuItems={[
                      {
                        label: trans('journal.edit_note'),
                        onChange: () => onEditJournalClick(note),
                        icon: IconNote,
                      },
                      {
                        label: trans('journal.delete_note'),
                        onChange: () => {
                          onDeleteJournalClick(note.id);
                        },
                        icon: IconTrash,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedJournal && (
        <JournalCommentDialog
          journal={selectedJournal}
          isOpen={isCommentDialogOpen}
          onClose={() => setIsCommentDialogOpen(false)}
        />
      )}
      {selectedImage && (
        <JournalImageDialog imageUrl={selectedImage} isOpen={!!selectedImage} onClose={() => setSelectedImage(null)} />
      )}
    </>
  );
};
