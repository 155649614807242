// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SingleValue} from 'react-select';
import {_SelectValue, Select} from '@modules/Core/components/base/inputs/Select';

export interface _JournalSelectorData {
  key: string;
  placeholder: string;
  width?: string;
  options: _SelectValue[];
}

export interface _JournalSelector {
  data: _JournalSelectorData[];
}

interface _JournalSelectorProps extends _JournalSelector {
  handleSelectorChange: (value: SingleValue<_SelectValue>, selectType: string) => void;
}

export const JournalSelector: React.FC<_JournalSelectorProps> = ({data, handleSelectorChange}) => {
  const onChange = (newSelectValue: SingleValue<_SelectValue>, selectType: string): void => {
    if (handleSelectorChange) handleSelectorChange(newSelectValue, selectType);
  };

  return (
    <div className="flex flex-row justify-end gap-3">
      {data.map(item => {
        // Add empty option to the select
        const options = item.options ?? [];
        return (
          <div key={item.key} className="relative-col-width-2">
            <Select
              options={options}
              placeholder={item.placeholder}
              onChange={e => onChange(e, item.key)}
              value={options[0]}
            />
          </div>
        );
      })}
    </div>
  );
};
