// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';

interface _JournalImageDialogProps {
  imageUrl: string;
  isOpen: boolean;
  onClose: () => void;
}

export const JournalImageDialog: React.FC<_JournalImageDialogProps> = ({imageUrl, isOpen, onClose}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} showCloseButton size="lg" containerGap={0}>
      <div className="flex justify-center items-center">
        <img
          src={imageUrl}
          alt="Journal content"
          className="max-w-full max-h-[85vh] object-contain"
          style={{
            minHeight: '200px',
            minWidth: '200px',
          }}
        />
      </div>
    </Dialog>
  );
};
