// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import './WYSIWYG.scss';
import {twMerge} from 'tailwind-merge';

interface _WYSIWYGProps {
  id?: string;
  value?: string | null;
  defaultValue?: string;
  onChange?: (value: string) => void;
  className?: string;
  readOnly?: boolean;
  dusk?: string;
  label?: string;
  required?: boolean;
}

export const WYSIWYG: React.FC<_WYSIWYGProps> = ({
  id,
  value,
  defaultValue,
  onChange,
  className,
  readOnly,
  dusk,
  label,
  required,
}) => {
  const [ReactQuill, setReactQuill] = useState<any>(null);

  // Dynamically import ReactQuill on client side
  useEffect(() => {
    import('react-quill')
      .then(module => {
        setReactQuill(() => module.default);
      })
      .catch(error => {
        console.error('Failed to load ReactQuill:', error);
      });
  }, []);

  return (
    <div className={twMerge('flex flex-col gap-1 w-full')}>
      {label && (
        <label htmlFor={id} className="text-body2-bold user-select-none pointer-events-none">
          {label}
          {required && <span className="required text-body2-bold">*</span>}
        </label>
      )}
      {ReactQuill && (
        <ReactQuill
          id={id}
          className={twMerge('w-full', className)}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          readOnly={readOnly}
          dusk={dusk}
          modules={{
            toolbar: [
              [{header: [1, 2, false]}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{list: 'ordered'}, {list: 'bullet'}],
              ['link', 'image'],
              ['clean'],
            ],
          }}
        />
      )}
    </div>
  );
};
