// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {RadioButton} from '@modules/Core/components/base/inputs/RadioButton';
import {Section} from '@modules/Core/components/layout/Section';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {selectLabel} from '@modules/FlowEngine/util/helpers/itemSelectionUtil';
import {trans} from '@modules/Translations/util/i18n';
import {BaseStep} from '../Base/BaseStep';

export interface _Item {
  id: string;

  [key: string]: any;
}

export const SelectStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const {selectedItem: previouslySelectedItem, items, labelSelector, buttonLabel, showButton} = options;

  useEffect(() => {
    if (previouslySelectedItem) {
      setSelectedItem(previouslySelectedItem);
    }
  }, [previouslySelectedItem]);

  return (
    <BaseStep
      getValueCallbackFn={() => {
        return {
          selectedItem,
        };
      }}
      inputs={[selectedItem]}
      options={options}
    >
      <Section
        title={options.title}
        description={options.description}
        titleVariant="h5"
        descriptionVariant="body1"
        gap={5}
      >
        <div className="flex flex-row flex-wrap gap-3">
          {items?.map((item: _Item) => (
            <div key={item.id} className="relative-col-width-2-5">
              <RadioButton
                label={selectLabel(item, labelSelector)}
                // label={trans(option.label)}
                active={selectedItem === item.id}
                onChange={() => setSelectedItem(item.id)}
              />
            </div>
          ))}
        </div>
      </Section>
      {showButton && !!selectedItem && (
        <div className="flex flex-row justify-center">
          <div className="relative-col-width-2">
            <Button
              label={buttonLabel || trans('base.confirm')}
              onClick={() => {
                options?.onComplete?.();
              }}
            />
          </div>
        </div>
      )}
    </BaseStep>
  );
};
