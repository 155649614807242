// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {AxiosResponse} from 'axios';
import {isToday} from 'date-fns';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {arrayToMapByIndex} from '@modules/Core/util/arrays';
import {dispatchReactEvent, reloadContentEngine, reloadProfileStateWithPromise} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {isArray} from '@modules/Core/util/typesUtil';
import {copyObject} from '@modules/Core/util/util';
import {useProfile} from '@modules/Profile/hooks/profile';
import {currentProfile} from '@modules/Profile/util/profileUtil';
import {finaliseSurvey, getSurvey, submitSurvey} from '@modules/SurveyEngine/services/surveyApi';
import {_Survey, _SurveyParams, _SurveyQuestion} from '@modules/SurveyEngine/types/survey.model';
import {mapQuestion} from '@modules/SurveyEngine/util/engineUtil';
import {convertKey, getSpecialFieldKey} from '@modules/SurveyEngine/util/questionUtil';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';

const DEFAULT_DEBOUNCE_TIME = 300;

export function useSurvey(type: string, onFinish: () => Promise<void>, surveyParams: _SurveyParams) {
  const {currentProfile} = useProfile();

  const [survey, setSurvey] = useState<_Survey | null>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [answers, setAnswers] = useState<Record<string, any>>({});
  const [question, setQuestion] = useState<_SurveyQuestion | null>(null);
  const [questions, setQuestions] = useState<_SurveyQuestion[]>([]);
  const snackbar = useSnackbar();
  const {currentLanguage} = useLanguage();

  const {
    onNextClicked,
    onBackClicked,
    onFinishClicked,
    onSkipSectionClicked,
    hasMorePages,
    isFirstQuestion,
    isLastQuestion,
    onSkipClicked,
    reset,
    currentIndex,
    calculateProgress,
    skipMode,
    progress,
    setCurrentIndex,
    createRequestBody,
  } = useQuestionsNavigationUtil(survey, questions, answers, saveSurvey, surveyParams, onFinish, projectId!);

  useEffect(() => {
    logger.debug('[useSurvey] Current question:', {
      question,
      survey,
    });
  }, [question, survey]);

  useEffect(() => {
    logger.info('[useSurvey] Survey changed, changing survey.', [
      type,
      surveyParams?.module,
      surveyParams?.subModule,
      surveyParams?.subSubModule,
      currentLanguage,
    ]);

    // If no survey, reset survey
    if (!type) {
      resetState();
      return;
    }

    // Reload survey only if current survey is different
    if (type) {
      loadSurvey().catch(logger.error);
    }
  }, [type, surveyParams?.module, surveyParams?.subModule, surveyParams?.subSubModule, currentLanguage]);

  useEffect(() => {
    if (survey) {
      if (!survey.questions[currentIndex]) {
        setCurrentIndex(parseInt(currentIndex, 10) + 1);
        return;
      }
      const q = mapQuestion(survey.questions[currentIndex]);
      setQuestions(survey.questions);
      setQuestion(q);
      calculateProgress();
      if (skipMode && q.type !== 'seperator') {
        void skipQuestion();
      }
    }
  }, [survey, currentIndex, skipMode]);

  const getCurrentService = () => {
    return currentProfile?.data.modules.flatMap(m => m.serviceStatuses).find(s => s?.service === type);
  };

  async function saveSurvey(
    newStepIndex: number,
    newAnswers: Record<string, any> | null,
    includeTimeout = false
  ): Promise<void> {
    if (newAnswers === null) {
      setCurrentIndex(newStepIndex);
      return;
    }

    // TODO:Change this to flow action instead of api call
    const response = await submitSurvey(createRequestBody(newStepIndex, newAnswers), surveyParams);

    if (response?.data) {
      if (includeTimeout) {
        setTimeout(() => {
          setCurrentIndex(response?.data?.goto as number);
        }, DEFAULT_DEBOUNCE_TIME);
      } else {
        setCurrentIndex(response?.data?.goto as number);
      }

      if (response?.data?.errors?.length > 0) {
        snackbar.danger(response?.data?.errors[0] as string);
      }
    }
  }

  async function loadSurvey(): Promise<void> {
    const currentService = getCurrentService();
    const response = await getSurvey({
      type,
      module: surveyParams?.module,
      subModule: surveyParams?.subModule,
      subSubModule: surveyParams?.subSubModule,
      projectId: surveyParams?.projectId,
      teamId: surveyParams?.teamId,
      skipIntro: currentService?.current_round === 0,
    });

    if (response.status === 200 && response?.data) {
      setSurvey(response.data?.survey as _Survey);
      setProjectId(response.data?.projectId as string);
      parseAnswers(response);
    }

    dispatchReactEvent('navigate.done', `survey-loaded: ${type}`);
  }

  function resetState(): void {
    setSurvey(null);
    setQuestion(null);
    setQuestions([]);
    reset();
  }

  function parseAnswers(response: AxiosResponse): void {
    const newAnswers: Record<string, any> = {};
    if (response.data?.answers) {
      for (const answerKey of Object.keys(response.data.answers?.data || {})) {
        const currentQuestion = Object.values(response?.data?.survey?.questions || {}).find(
          (q: any) => convertKey(q.key) === answerKey
        );

        // extra question
        if (!currentQuestion && answerKey.endsWith('_extra')) {
          const newKey = answerKey.replace('_extra', '');

          const specialFieldKey = getSpecialFieldKey(newKey);

          newAnswers[specialFieldKey] = response.data.answers.data[answerKey][0];

          continue;
        }

        if (currentQuestion?.type === 'range-definition') {
          newAnswers[answerKey] = {};

          if (response.data.answers.data[answerKey]?.length > 0) {
            (response.data.answers.data[answerKey][0] || []).forEach((row: any[], rowIndex: number) => {
              row.forEach((col: any, colIndex: number) => {
                if (col) {
                  newAnswers[answerKey][`${rowIndex}_${colIndex}`] = Boolean(col);
                }
              });
            });
          }
          continue;
        }
        // add key only if not extra key
        newAnswers[answerKey] = response.data.answers.data[answerKey];

        if (isArray(newAnswers[answerKey])) {
          newAnswers[answerKey] = newAnswers[answerKey][0];
        }
      }

      // Loop backwards and find the latest answered question
      const {questions} = response.data.survey;
      const questionIndexes = Object.keys(questions);

      for (let i = questionIndexes.length - 1; i >= 0; i--) {
        const currentQuestion = questions[questionIndexes[i]];
        const convertedKey = convertKey(currentQuestion.key);

        if (newAnswers[convertedKey]) {
          setCurrentIndex(questionIndexes[i < questionIndexes.length - 1 ? i + 1 : i]);
          break;
        }
      }
      logger.debug('[useSurvey] Answers:', newAnswers);
      setAnswers(newAnswers);
    }
  }

  // TODO @Sherif types
  function onAnswer(question: _SurveyQuestion, answer: any, isExtraField = false): void {
    const convertedKey = convertKey(question.key);

    let answersCopy = copyObject(answers);

    if (!answersCopy) {
      return;
    }

    answersCopy = answersCopy as Record<string, string[]>;

    switch (question.type) {
      case 'multiselect':
        if (!answersCopy[convertedKey]) {
          answersCopy[convertedKey] = [];
        }

        if (isExtraField) {
          answersCopy[getSpecialFieldKey(convertedKey)] = answer;
        } else {
          // Get key where value = answer
          const keyIndex = answersCopy[convertedKey].findIndex((key: string) => key === answer);
          if (keyIndex !== -1) {
            answersCopy[convertedKey].splice(keyIndex, 1);
          } else {
            answersCopy[convertedKey].push(answer);
          }
        }
        setAnswers(answersCopy);
        break;

      case 'range-definition':
        if (!answersCopy[convertedKey]) {
          answersCopy[convertedKey] = {};
        }
        const key = `${answer.key1Index}_${answer.key2Index}`;
        if (!answer.checked) {
          delete answersCopy[convertedKey][key];
        } else {
          answersCopy[convertedKey][key] = 'true';
        }
        setAnswers(answersCopy);
        break;

      case 'multiselect_dnd':
        if (!answersCopy[convertedKey]) {
          answersCopy[convertedKey] = {};
        }
        if (isExtraField) {
          answersCopy[convertedKey].extra = answer;
        } else {
          answersCopy[convertedKey] = answer;
        }

        setAnswers(answersCopy);
        break;

      case 'textarea':
        if (!answersCopy[convertedKey]) {
          answersCopy[convertedKey] = [];
        }
        answersCopy[convertedKey] = answer;
        setAnswers(answersCopy);
        break;

      case 'radio':
        answersCopy[convertedKey] = answer;
        setAnswers(answersCopy);
        // onNextClicked(null, answersCopy)
        break;

      default:
        answersCopy[convertedKey] = answer;
        setAnswers(answersCopy);
        // onNextClicked(null, answersCopy)
        break;
    }
  }

  async function skipQuestion(): Promise<void> {
    const answersCopy = copyObject(answers);
    if (!answersCopy) {
      return;
    }

    const question = questions[currentIndex];
    const convertedKey = convertKey(question.key);
    const answerKeys = question.answers ? Object.keys(question.answers) : [];
    const randomAnswer = answerKeys.length > 0 ? answerKeys[Math.floor(Math.random() * answerKeys.length)] : null;

    if (!question.type) {
      await saveSurvey(currentIndex + 1, answersCopy);
      setAnswers(answersCopy);
    }

    switch (question.type) {
      case 'intro':
      case 'seperator':
        answersCopy[convertedKey] = '1';
        break;

      case 'radio':
      case 'option-buttons':
      case 'option-radio':
        answersCopy[convertedKey] = randomAnswer;
        break;

      case 'multiselect':
        answersCopy[convertedKey] = [randomAnswer];
        break;

      case 'range-definition':
        answersCopy[convertedKey] = {
          '0_0': 'true',
          '1_1': 'true',
        };
        break;
      case 'textarea':
        answersCopy[convertedKey] = 'Random answer';
        break;

      case 'multiselect_dnd':
        const {answers} = question;
        const answer2 = question.answers2 || {};
        const answer2Entries = Object.entries(answer2);
        const randomIndex = Math.floor(Math.random() * answer2Entries.length);
        const randomAnswer2Item = answer2Entries[randomIndex];
        const remainingKeys = answerKeys.filter(key => key !== randomAnswer);
        const selectedAnswer = {
          key: randomAnswer,
          label: answers?.[randomAnswer] ?? '',
        };
        const resultObject = remainingKeys.map(key => ({
          key,
          label: answers?.[key] ?? '',
        }));

        answersCopy[convertedKey] = {
          items: resultObject,
          lists: [
            {
              key: randomAnswer2Item[0],
              label: randomAnswer2Item[1],
              values: [selectedAnswer],
            },
          ],
        };
        break;
      case 'content':
        break;

      default:
        return;
    }
    if (hasMorePages()) {
      await saveSurvey(currentIndex + 1, answersCopy);
      setAnswers(answersCopy);
    }
  }

  return {
    survey,
    question,
    questions,
    answers,
    onNextClicked,
    onBackClicked,
    onFinishClicked,
    onSkipSectionClicked,
    hasMorePages,
    progress,
    onAnswer,
    onSkipClicked,
    isFirstQuestion,
    isLastQuestion,
    skipQuestion,
  };
}

const useQuestionsNavigationUtil = (
  survey: _Survey | null,
  questions: _SurveyQuestion[],
  answers: Record<string, any>,
  saveSurvey: any,
  surveyParams: _SurveyParams,
  onFinish: () => Promise<void>,
  projectId: string
): Record<string, any> => {
  const snackbar = useSnackbar();

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [skipMode, setSkipMode] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [questionIndexes, setQuestionIndexes] = useState<number[]>([]);

  const currentQuestion = questions[currentIndex];

  useEffect(() => {
    if (questions) {
      setQuestionIndexes(Object.keys(questions).map(key => parseInt(key, 10)));
    }
  }, [questions]);

  function changeStep(index: number, newAnswers: Record<string, any> | null = answers, includeTimeout = false): void {
    // We don't want loading here
    void saveSurvey(index, newAnswers, includeTimeout);
  }

  function isFirstQuestion(): boolean {
    return currentIndex === questionIndexes[0];
  }

  function isLastQuestion(): boolean {
    return currentIndex === questionIndexes[questionIndexes.length - 1];
  }

  function onSkipClicked(): void {
    setSkipMode(true);
    if (currentQuestion?.type == 'seperator') {
      if (hasMorePages()) {
        changeStep(currentIndex + 1, []);
      }
    }
  }

  function onBackClicked(): void {
    setSkipMode(false);
    if (currentIndex > 0) {
      setSkipMode(false);
      changeStep(currentIndex - 1);
    }
  }

  function hasMorePages(): boolean {
    return currentIndex <= questionIndexes[questionIndexes.length - 1];
  }

  function onNextClicked(event: any, newAnswers: Record<string, any>): void {
    setSkipMode(false);
    const includeTimeout = event === null;
    if (hasMorePages()) {
      changeStep(currentIndex + 1, newAnswers, includeTimeout);
    }
  }

  function onSkipSectionClicked(event: any, newAnswers: Record<string, any>): void {
    const includeTimeout = event === null;
    if (hasMorePages()) {
      const nextSectionIndex = Object.keys(questions).find(
        index => parseInt(index) > currentIndex + 1 && questions[parseInt(index)].type === 'seperator'
      );
      const lastIndex = Object.keys(questions).at(-1) ?? '0';
      changeStep(nextSectionIndex ? parseInt(nextSectionIndex) : parseInt(lastIndex), null, includeTimeout);
    }
  }

  async function onFinishClicked(): Promise<void> {
    setSkipMode(false);
    const response = await finaliseSurvey(
      createRequestBody(currentIndex, answers),
      surveyParams?.module,
      surveyParams?.subModule,
      surveyParams?.subSubModule,
      surveyParams?.inviteId
    );
    if (response?.status === 200 && response?.data?.done) {
      await reloadProfileStateWithPromise();
      reloadContentEngine();
      void onFinish();
    } else {
      snackbar.danger(trans('project.survey_error'));
    }
  }

  function reset(): void {
    setCurrentIndex(0);
  }

  function createRequestBody(newStepIndex: number, newAnswers: Record<string, any> = answers): Record<string, any> {
    const result: Record<string, any> = {};
    const questionKey = convertKey(questions[currentIndex]?.key);
    const questionType = questions[currentIndex]?.type;
    const currentQuestion = questions[currentIndex];
    result.surveyId = survey?.id;
    result.projectId = projectId;
    result.index = currentIndex;
    result.goto = newStepIndex;
    result.key = questionKey;
    result.q_type = questions[currentIndex]?.type;
    result[questionKey] = newAnswers[questionKey];

    if (newAnswers[`${questionKey}[extra]`]) {
      result[questionKey] = arrayToMapByIndex(result[questionKey]);
      result[questionKey].extra = newAnswers[`${questionKey}[extra]`];
    }

    if (questionType === 'range-definition') {
      result.range_cols = Object.keys(currentQuestion?.answers2 ?? {})?.length;
      result.range_rows = Object.keys(currentQuestion?.answers ?? {})?.length;
    }

    return result;
  }

  function calculateProgress(): void {
    if (!survey) {
      setProgress(0);
      return;
    }
    // Note: We show 100% on the last question, not on the finish screen (thats why we subtract 2 instead of 1)
    const questionIndexes = Object.keys(survey.questions).map(key => parseInt(key, 10));
    const newProgress = Math.round((100 * currentIndex) / (questionIndexes[questionIndexes.length - 2] || 1));
    setProgress(newProgress);
  }

  const onSetCurrentIndex = (index: any) => {
    setCurrentIndex(parseInt(index, 10));
  };

  const profile = currentProfile();
  const hasPerfSatResultsFromToday =
    profile &&
    Object.values(profile.data.serviceStatuses).filter(
      (item: any) =>
        isToday(new Date(item.updated_at)) &&
        !['not_ready', 'ready'].includes(item.status) &&
        ['finished'].includes(item.current_round_status)
    ).length > 0;
  const showSkipSection =
    hasPerfSatResultsFromToday &&
    !['initial_status_quo', 'profile_satisfaction_and_performance'].includes(currentQuestion?.survey_key) &&
    (currentQuestion?.key === 'g_seperator_satisfaction_01' ||
      currentQuestion?.key?.startsWith('g_satisfaction_q_') ||
      currentQuestion?.key === 'g_seperator_performance_i_01' ||
      currentQuestion?.key?.startsWith('g_subjective_performance_q_'));

  return {
    onNextClicked,
    onBackClicked,
    onFinishClicked,
    onSkipSectionClicked: showSkipSection ? onSkipSectionClicked : null,
    hasMorePages,
    isFirstQuestion,
    isLastQuestion,
    onSkipClicked,
    reset,
    calculateProgress,
    skipMode,
    currentIndex,
    progress,
    setCurrentIndex: onSetCurrentIndex,
    createRequestBody,
  };
};
