// @ts-nocheck
/* eslint-disable */

import {flowRoute, absoluteUrl} from '@/_core/util/util';
import {_Address} from '@modules/Core/types/address.model';
import {invokeAction} from '@modules/Core/util/actionsUtil';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {_SubscriptionBillingInterval} from '@modules/Profile/types/memberships.model';

// TODO @Sherif types
export function getSuccessUrl(mode: string): string {
  // TODO @Sherif This should not be done on the frontend (see also my chat message). I will ignore it on the backend from now on. Please remove (Low Priority)
  return absoluteUrl(`${flowRoute(FLOWS.Payment)}?checkoutId={CHECKOUT_SESSION_ID}&tab=${mode}`);
}

export function getCancelUrl(): string {
  return absoluteUrl(flowRoute(FLOWS.Payment) || '');
}

/**
 * Actions
 */

export async function actionCreatePayLink(
  profileId: string,
  billingInterval: _SubscriptionBillingInterval,
  planId: string,
  profileIds: string[],
  address: _Address,
  type: 'profile' | 'institution',
  isDiscount: boolean
) {
  const method = 'pay_link.create';
  const successUrl = getSuccessUrl(type);
  const cancelUrl = getCancelUrl();

  const params = {
    profileId,
    billingInterval,
    planId,
    successUrl,
    cancelUrl,
    profileIds,
    address,
    type,
    isDiscount,
  };

  return await invokeAction(method, params, {
    loadingIndicator: true,
  });
}

export async function actionCalculateSubscription({
  profileId,
  billingInterval,
  planId,
  profileIds,
  institutionId,
  isDiscount,
}) {
  return await invokeAction('subscription.calculate', {
    profileId,
    billingInterval,
    planId,
    profileIds,
    institutionId,
    isDiscount,
  });
}

export async function actionCancelSubscription({subscriptionId, profileIds}) {
  const method = 'subscription.cancel';
  const params = {
    subscriptionId,
    profileIds,
  };

  return await invokeAction(method, params, {
    refetchProfile: true,
    loadingIndicator: true,
  });
}

export async function actionReactivateSubscription({subscriptionId, profileIds}) {
  const method = 'subscription.reactivate';
  const params = {
    subscriptionId,
    profileIds,
  };
  return await invokeAction(method, params, {
    refetchProfile: true,
    loadingIndicator: true,
  });
}

export async function actionChangePlan({profileIds, subscriptionId, institutionId, newPlanId, newBillingInterval}) {
  const method = 'subscription.plan.change';
  const params = {
    profileIds,
    subscriptionId,
    institutionId,
    newPlanId,
    newBillingInterval,
  };
  return await invokeAction(method, params, {
    refetchProfile: true,
    loadingIndicator: true,
  });
}

export async function actionAssignSubscription({profileIds, subscriptionDetailIds, institutionId}) {
  const method = 'subscription.assign';
  const params = {
    profileIds,
    subscriptionDetailIds,
    institutionId,
  };
  return await invokeAction(method, params, {
    refetchProfile: true,
    loadingIndicator: true,
  });
}
