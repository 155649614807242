// @ts-nocheck
/* eslint-disable */

/**
 * Profile
 */

export const DELETE_PHOTO = 'profile/delete-photo';
export const SAVE_PHOTO = 'profile/save-photos';
export const SAVE_DESCRIPTION = 'profile/description/save';
export const SAVE_PROFILE = 'profile/update';
export const PRIVACY_SETTINGS = 'profile/privacy-settings';
export const PROFILE_FEELINGS = 'profile/feelings/add';
export const PROFILE_DATA = 'profile';
export const PROFILE = 'current/profile';

/**
 * Exercises
 */
export const EXERCISES_JSON = 'exercises/json';
export const DOWNLOAD_SERVICE_CERTIFICATE = 'profile/service-certificate';

/**
 * Journal
 */
export const JOURNAL_CATEGORIES = 'journal/categories';
export const JOURNALS = 'journals';
export const JOURNAL_GROUPS = 'journal/groups';

/**
 * Third Person Feedbacks
 */

export const THIRD_PERSON_FEEDBACK = 'profile/third-person-feedback';
export const THIRD_PERSON_FEEDBACK_REMINDER = 'profile/third-person-feedback-reminder';
export const THIRD_PERSON_FEEDBACK_SINGLE_REPORT = 'profile/third-person-feedback-single';

/**
 * Shared Results
 */

export const SHARED_RESULTS = 'profile/shared-results';
export const SOFT_FACTS_SHARED_RESULT = 'profile/soft-facts/shared-result';

/**
 * Property
 */
export const PROPERTY_TYPES = 'profile/property-types';

/**
 * Personal Values
 */

export const PERSONAL_VALUES = 'personal-values';
export const PERSONAL_VALUES_NOTIFIED = (teamId: string): string => `teams/${teamId}/personal-values/notified`;
export const PERSONAL_VALUES_TEAM = (teamId: string): string => `personal-values/team/${teamId}`;
export const PERSONAL_VALUES_CREATE = 'personal-values/create';
export const PERSONAL_VALUES_DELETE = 'personal-values/delete';

/**
 * WoW
 */

export const DOWNLOAD_PROFILE_PRODUCTIVITY_ICS = 'profile/productivity.ics';

/**
 * Impersonation
 */
export const IMPERSONATE = 'impersonate';
export const STOP_IMPERSONATING = 'stop-impersonating';
