// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {Box} from '@modules/Core/components/base/Box';
import {Card} from '@modules/Core/components/base/cards/Card';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';
import {styled} from '@modules/Core/util/styled';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface SoftFactValue {
  key: string;
  value: number;
}

interface ProfileDimensions {
  profile: _ShortProfile;
  dimensions: SoftFactValue[];
}

interface ProfileSoftFactsHeatmapProps {
  data: ProfileDimensions[];
  minProfileCount?: number;
  title?: string;
  titleVariant?: _TypographyVariants;
  description?: string;
  descriptionVariant?: _TypographyVariants;
}

// Define the order and translations for soft facts dimensions
const SOFT_FACTS_DIMENSIONS = [
  {key: 'trust', label: () => trans('profile.trust')},
  {key: 'learning', label: () => trans('profile.learning')},
  {key: 'motivation', label: () => trans('profile.motivation')},
  {key: 'resilience', label: () => trans('profile.resilience')},
  {key: 'mindfulness', label: () => trans('profile.mindfulness')},
  {key: 'proactivity', label: () => trans('profile.proactivity')},
  {key: 'selfefficacy', label: () => trans('profile.selfefficacy')},
  {key: 'communication', label: () => trans('profile.communication')},
  {key: 'responsibility', label: () => trans('profile.responsibility')},
  {key: 'emotional_intelligence', label: () => trans('profile.emotional_intelligence')},
  {key: 'satisfaction', label: () => trans('base.satisfaction')},
  {key: 'individual_performance', label: () => trans('base.performance')},
];

// Define color stops for the gradient - updated to match design
const colorStops = [
  {value: 1, color: '#8B0000'}, // darker red
  {value: 2, color: '#FF4500'}, // orange-red
  {value: 3, color: '#FFA500'}, // orange
  {value: 4, color: '#90EE90'}, // light green
  {value: 5, color: '#006400'}, // dark green
];

const interpolateColor = (value: number): string => {
  // Find the two color stops that bound this value
  let lowerStop = colorStops[0];
  let upperStop = colorStops[colorStops.length - 1];

  for (let i = 0; i < colorStops.length - 1; i++) {
    if (value >= colorStops[i].value && value <= colorStops[i + 1].value) {
      lowerStop = colorStops[i];
      upperStop = colorStops[i + 1];
      break;
    }
  }

  // Convert hex to RGB for interpolation
  const lowerRGB = {
    r: parseInt(lowerStop.color.slice(1, 3), 16),
    g: parseInt(lowerStop.color.slice(3, 5), 16),
    b: parseInt(lowerStop.color.slice(5, 7), 16),
  };

  const upperRGB = {
    r: parseInt(upperStop.color.slice(1, 3), 16),
    g: parseInt(upperStop.color.slice(3, 5), 16),
    b: parseInt(upperStop.color.slice(5, 7), 16),
  };

  // Calculate interpolation factor
  const range = upperStop.value - lowerStop.value;
  const factor = (value - lowerStop.value) / range;

  // Interpolate RGB values
  const r = Math.round(lowerRGB.r + (upperRGB.r - lowerRGB.r) * factor);
  const g = Math.round(lowerRGB.g + (upperRGB.g - lowerRGB.g) * factor);
  const b = Math.round(lowerRGB.b + (upperRGB.b - lowerRGB.b) * factor);

  // Convert back to hex
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const GradientBox = styled(Box)({
  backgroundColor: 'var(--color)',
  height: '32px',
  transition: 'transform 0.15s',
  '&:hover': {
    transform: 'scale(1.02)',
    cursor: 'pointer',
  },
});

const LegendGradient = styled(Box)({
  display: 'flex',
  width: '150px',
  height: '12px',
  borderRadius: '2px',
  backgroundImage: 'linear-gradient(90deg, #8B0000 0%, #FF4500 25%, #FFA500 50%, #90EE90 75%, #006400 100%)',
});

const LegendContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
});

const LegendValues = styled(Box)({
  display: 'flex',
  width: '150px',
  justifyContent: 'space-between',
  padding: '0 2px',
});

const LegendLabels = styled(Box)({
  display: 'flex',
  width: '400px', // Increased width to give more space to labels
  justifyContent: 'space-between',
  alignItems: 'center',
});

const LegendLabelText = styled(Typography)({
  fontSize: '0.8rem',
  color: '${props => props.color || "#666"}',
  width: '200px',
  textAlign: 'center',
});

// Create a component that can render HTML content with custom color
const HtmlLabelText: React.FC<{htmlContent: string; color?: string}> = ({htmlContent, color}) => {
  return (
    <LegendLabelText>
      <span style={{color: color || '#666'}} dangerouslySetInnerHTML={{__html: htmlContent}} />
    </LegendLabelText>
  );
};

const LegendMiddle = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2px',
  width: '200px',
});

const RotatedHeader = styled(Typography)({
  transform: 'rotate(-45deg)',
  transformOrigin: 'top right',
  whiteSpace: 'nowrap',
  fontSize: '0.75rem',
  color: '#666',
  position: 'absolute',
  top: '8px',
  textAlign: 'right',
});

const HeaderContainer = styled(Box)({
  position: 'relative',
  height: '100px',
  marginTop: '8px',
  paddingBottom: '32px', // Space for the Soft Facts label
  marginLeft: '36px',
});

const ValueText = styled(Typography)({
  fontSize: '0.7rem',
});

const Legend = () => (
  <StyledBox style={{gap: '4px'}}>
    <LegendLabels>
      <HtmlLabelText
        htmlContent={trans('dashboard.soft_facts_heatmap.needs_urgent_focus')}
        color="#8B0000" // Dark red to match gradient start
      />
      <LegendMiddle>
        <LegendGradient />
        <LegendValues>
          {[1, 2, 3, 4, 5].map(value => (
            <ValueText key={value}>{value}</ValueText>
          ))}
        </LegendValues>
      </LegendMiddle>
      <HtmlLabelText
        htmlContent={trans('dashboard.soft_facts_heatmap.needs_appreciation')}
        color="#006400" // Dark green to match gradient end
      />
    </LegendLabels>
  </StyledBox>
);

const HeatmapRow = ({profile, dimensions}: ProfileDimensions) => {
  // Sort dimensions according to SOFT_FACTS_DIMENSIONS order
  const sortedDimensions = [...dimensions].sort((a, b) => {
    const aIndex = SOFT_FACTS_DIMENSIONS.findIndex(d => d.key === a.key);
    const bIndex = SOFT_FACTS_DIMENSIONS.findIndex(d => d.key === b.key);
    return aIndex - bIndex;
  });

  return (
    <StyledBox style={{gap: '16px', marginBottom: '4px'}}>
      <Avatar image={getProfilePic(profile)} size="sm" />
      <Box style={{display: 'flex', flex: 1, gap: '4px'}}>
        {sortedDimensions.map(item => (
          <ToolTip
            key={item.key}
            txt={`${SOFT_FACTS_DIMENSIONS.find(d => d.key === item.key)?.label()}: ${item.value.toFixed(1)}`}
            className="w-full"
          >
            <GradientBox
              style={
                {
                  flex: 1,
                  '--color': interpolateColor(item.value),
                } as React.CSSProperties
              }
            />
          </ToolTip>
        ))}
      </Box>
    </StyledBox>
  );
};

const TitleText = styled(Typography)({
  color: '#666',
});

const CenteredText = styled(Typography)({
  position: 'absolute',
  bottom: '0',
  left: '50%',
  transform: 'translateX(-50%)',
  color: '#666',
  fontSize: '0.875rem',
  fontWeight: 'bold',
});

const LeftText = styled(Typography)({
  color: '#666',
  fontSize: '0.875rem',
  fontWeight: 'bold',
});

export const ProfileSoftFactsHeatmapItem: React.FC<ProfileSoftFactsHeatmapProps> = ({
  data,
  minProfileCount,
  title,
  titleVariant = 'h5',
  description,
  descriptionVariant,
}) => {
  if (!data?.length && !minProfileCount) return null;

  // Check if we have enough profiles for anonymity
  if (minProfileCount && data.length < minProfileCount) {
    return (
      <Card
        title={title}
        titleVariant={titleVariant}
        description={trans('dashboard.soft_facts_heatmap.anonymity_notice', {count: minProfileCount})}
        descriptionVariant="body1"
      />
    );
  }

  const columnWidth = 100 / SOFT_FACTS_DIMENSIONS.length;

  return (
    <Card title={title} titleVariant={titleVariant} description={description} descriptionVariant={descriptionVariant}>
      <Card padding={2}>
        <Box style={{width: '100%'}}>
          <StyledBox style={{justifyContent: 'space-between', marginBottom: '16px'}}>
            <LeftText>{trans('dashboard.soft_facts_heatmap.people')}</LeftText>
            <Legend />
          </StyledBox>

          <Box style={{position: 'relative'}}>
            {data.map((profileDimension, index) => (
              <HeatmapRow
                key={`${profileDimension.profile.id}-${index}`}
                profile={profileDimension.profile}
                dimensions={profileDimension.dimensions}
              />
            ))}

            <HeaderContainer>
              {SOFT_FACTS_DIMENSIONS.map((dimension, index) => (
                <RotatedHeader
                  key={dimension.key}
                  style={{right: `${100 - index * columnWidth - columnWidth / 2 - columnWidth / 4}%`}}
                >
                  {dimension.label()}
                </RotatedHeader>
              ))}
            </HeaderContainer>
            <CenteredText>{trans('base.soft_facts_menu_title')}</CenteredText>
          </Box>
        </Box>
      </Card>
    </Card>
  );
};
