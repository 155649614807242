// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import DatePicker from '@modules/Core/components/base/datepicker/DatePicker';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Typography} from '@modules/Core/components/base/Typography';

interface _DialogDatePickerProps {
  open: boolean;
  onClose: (confirmed: boolean, selectedDates?: Date[]) => void;
  title?: string;
  description?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  initialDates?: Date[];
  multiSelect?: boolean;
}

const DialogDatePicker: React.FC<_DialogDatePickerProps> = ({
  open,
  onClose,
  title,
  description,
  confirmLabel,
  cancelLabel,
  initialDates = [],
  multiSelect = true,
}) => {
  const [show, setShow] = useState<boolean>(true);
  const [dates, setDates] = useState<Date[]>(initialDates);

  const handleDatesChange = (newDates: Date[]) => {
    // If not multiSelect, only keep the last selected date
    if (!multiSelect && newDates.length > 1) {
      setDates([newDates[newDates.length - 1]]);
    } else {
      setDates(newDates);
    }
    if (newDates.length > 0) {
      onClose(true, newDates);
    }
  };

  const handleConfirm = () => {
    onClose(true, dates);
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      showCloseButton
      onClose={handleCancel}
      title={title}
      confirmLabel={confirmLabel}
      onConfirm={handleConfirm}
      cancelLabel={cancelLabel}
      onCancel={handleCancel}
      hideButtons
    >
      <Typography variant="body">{description}</Typography>
      <DatePicker
        show={show}
        setShow={setShow}
        dates={dates}
        setDates={handleDatesChange}
        options={{
          onlySingleDate: !multiSelect,
        }}
        showFooter={false}
      />
    </Dialog>
  );
};

export default DialogDatePicker;
