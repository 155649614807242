// @ts-nocheck
/* eslint-disable */

import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import snackbar from '@modules/Core/util/snackbar';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_ProfileExercise} from '@modules/Profile/types/exercises.model';

export const revokeSharedResultAction = async (id: string): Promise<boolean> => {
  try {
    const response = await performFlowAction(
      {
        method: 'share.result.revoke',
        params: {
          id,
        },
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    debugger;

    if (responseData?.status === ACTION_STATUS_SUCCESS) {
      if (responseData.message) {
        snackbar.success(responseData.message);
      }
      reloadContentEngine();
      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
};
