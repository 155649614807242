// @ts-nocheck
/* eslint-disable */

import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {sessionConfig} from '@app/_core/util/util';
import {getCountryList} from '@modules/Core/services/api/country';
import {loadFeatureFlags} from '@modules/Core/services/api/featureFlagsApi';
import {setFeatureFlags} from '@modules/Core/state/featureFlagsSlice';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {getInstitutionData} from '@modules/Institution/services/InstitutionApi';
import {setCurrentInstitution} from '@modules/Institution/state/InstitutionSlice';
import {PROFILE_RELOADED_APP_EVENT} from '@modules/Navigation/hooks/navigationInit';
import {
  getProfile,
  trackProfile,
  getProfileData,
  impersonateUser,
  stopImpersonating,
} from '@modules/Profile/services/profileApi';
import {_Profile} from '@modules/Profile/types/profile.model';
import {getCurrentTeams} from '@modules/Team/services/teamsApi';
import {setCurrentTeams} from '@modules/Team/state/TeamsSlice';
import {loadExercisesJSON} from '../services/exercisesApi';
import {getAllCategories} from '../services/journalsApi';
import {currentProfile} from '../util/profileUtil';
import {setExercises} from './exercisesSlice';

interface ProfileState {
  currentProfile: _Profile | null;
  originalProfile: _Profile | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ProfileState = {
  currentProfile: null,
  originalProfile: null,
  status: 'idle',
  error: null,
};

export const fetchProfileData = createAsyncThunk<_Profile | undefined, boolean, {rejectValue: string}>(
  'profile/fetchData',
  async (loadingIndicator: boolean = true, {dispatch, rejectWithValue}) => {
    try {
      logger.info('[ProfileSlice] Fetching profile.');
      const authToken = sessionConfig('authToken');
      const userType = sessionConfig('userType');

      if (!authToken || userType !== 'user') {
        return;
      }

      const [profileResponse, teamsResponse, institutionsResponse, exercisesResponse, featureFlagsResponse] =
        await Promise.all([
          getProfile(loadingIndicator),
          getCurrentTeams(loadingIndicator),
          getInstitutionData(loadingIndicator),
          loadExercisesJSON(loadingIndicator, currentProfile()?.id),
          loadFeatureFlags(loadingIndicator),
        ]);

      if (profileResponse?.data?.profile && profileResponse.status === 200) {
        void trackProfile(profileResponse.data.profile);
      }

      if (teamsResponse?.data && teamsResponse.status === 200) {
        dispatch(setCurrentTeams(teamsResponse.data));
      }

      if (exercisesResponse?.data && exercisesResponse.status === 200) {
        dispatch(setExercises(exercisesResponse.data));
      }

      if (institutionsResponse?.data && institutionsResponse.status === 200) {
        dispatch(setCurrentInstitution(institutionsResponse.data));
      }

      if (featureFlagsResponse?.data && featureFlagsResponse.status === 200) {
        dispatch(setFeatureFlags(featureFlagsResponse.data));
      }

      // Pre-fetch static data
      void Promise.all([getAllCategories(), getCountryList()]);

      dispatchReactEvent('reload.step');
      dispatchReactEvent(PROFILE_RELOADED_APP_EVENT);

      return profileResponse?.data?.profile;
    } catch (error) {
      logger.error('[ProfileSlice] Error fetching profile.', error);
      return rejectWithValue(error instanceof Error ? error.message : 'Failed to fetch profile data');
    }
  }
);

export const startImpersonation = createAsyncThunk<
  _Profile,
  string,
  {rejectValue: string; state: {profile: ProfileState}}
>('profile/startImpersonation', async (profileId: string, {getState, rejectWithValue}) => {
  try {
    logger.info(`[ProfileSlice] Starting impersonation for profile ID: ${profileId}`);
    const state = getState();

    // Only proceed if not already impersonating
    if (state.profile.currentProfile?.impersonating) {
      throw new Error('Already impersonating a user');
    }

    const response = await impersonateUser(profileId);

    if (response?.data?.profile && response.status === 200) {
      return response.data.profile;
    }
    throw new Error('Failed to fetch profile data for impersonation');
  } catch (error) {
    logger.error('[ProfileSlice] Error starting impersonation.', error);
    return rejectWithValue(error instanceof Error ? error.message : 'Failed to start impersonation');
  }
});

export const endImpersonation = createAsyncThunk<_Profile, void, {rejectValue: string; state: {profile: ProfileState}}>(
  'profile/endImpersonation',
  async (_, {getState, rejectWithValue}) => {
    try {
      logger.info('[ProfileSlice] Ending impersonation');
      const state = getState();

      // Only proceed if currently impersonating
      if (!state.profile.currentProfile?.impersonating) {
        throw new Error('Not currently impersonating any user');
      }

      const response = await stopImpersonating();

      if (response?.data?.profile && response.status === 200) {
        return response.data.profile;
      }
      throw new Error('Failed to stop impersonation');
    } catch (error) {
      logger.error('[ProfileSlice] Error ending impersonation.', error);
      return rejectWithValue(error instanceof Error ? error.message : 'Failed to end impersonation');
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchProfileData.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload) {
          state.currentProfile = action.payload;

          // Only update the original profile if we're not impersonating
          if (!state.currentProfile.impersonating) {
            state.originalProfile = action.payload;
          }
        }
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ?? 'Unknown error occurred';
      })
      .addCase(startImpersonation.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(startImpersonation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.originalProfile = state.currentProfile;
        state.currentProfile = action.payload;
      })
      .addCase(startImpersonation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ?? 'Failed to start impersonation';
      })
      .addCase(endImpersonation.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(endImpersonation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentProfile = action.payload;
        state.originalProfile = null;
      })
      .addCase(endImpersonation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ?? 'Failed to end impersonation';
      });
  },
});

export default profileSlice.reducer;
