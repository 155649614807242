// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {SendNewEmailDialogBody} from '@modules/Core/components/platform/dialogs/SendNewEmailDialogBody';
import {logger} from '@modules/Core/util/Logger';
import {createProjectAction, inviteMembersAction} from '@modules/Core/util/thirdPersonActionsUtil';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {_ScheduledEmail} from '@modules/Profile/types/emails.model';
import {currentProfile} from '@modules/Profile/util/profileUtil';
import {Emails} from '@modules/Team/components/invitations/Emails';
import {trans} from '@modules/Translations/util/i18n';
import {BaseStep} from '../Base/BaseStep';

export const EmailsStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [emails, setEmails] = useState<string[]>(options.emails ?? []);
  const {withScheduling} = options ?? {};

  const getValuesCallback = (): Record<string, any> => ({
    emails,
  });

  logger.debug('[TextEmailsStep] options', {
    options,
    emails,
  });

  const emailContent = {
    subject: options.subject,
    body: options.body,
    recipients: emails,
    actionText: options.actionLabel ?? trans('email.to_invite'),
    actionLink: options.actionLink,
  };

  const onCloseDialog = () => {
    options.onComplete();
  };

  // this is dirty and works only for 360, works for now though
  const onBeforeSave = async (): Promise<Partial<_ScheduledEmail>> => {
    if (withScheduling) {
      const createdFeedback = options.entityId
        ? {id: options.entityId}
        : await createProjectAction(options.projectName);

      logger.debug('[TextEmailsStep] createdFeedback', createdFeedback);
      if (createdFeedback?.id) {
        const result = await inviteMembersAction(createdFeedback.id, emails);
        logger.debug('[TextEmailsStep] inviteMembersAction result', result);
        if (result?.links) {
          return {
            action_link: result.links.join(','),
          };
        }
      }
    }
    return {};
  };

  return (
    <BaseStep getValueCallbackFn={getValuesCallback} inputs={[emails]} options={options}>
      <Section
        title={options.title}
        titleVariant={options.titleVaraint || 'h5'}
        image={options.image}
        description={options.description}
        descriptionVariant={options.descriptionVariant}
        gap={5}
      >
        {!options.hideEmails && !withScheduling && (
          <Emails
            emails={emails}
            setEmails={setEmails}
            placeholder={options.placeholder}
            label={options.label}
            consentLabel={options.consentLabel}
            helpLabel={options.helpLabel}
          />
        )}
        {withScheduling && (
          <SendNewEmailDialogBody
            onBeforeSave={onBeforeSave}
            profile={currentProfile() ?? undefined}
            subject={emailContent?.subject}
            body={emailContent?.body}
            placeholder={options.placeholder}
            label={options.label}
            recipients={emailContent?.recipients}
            onRecipientsChange={setEmails}
            actionLink={emailContent?.actionLink}
            actionText={emailContent?.actionText}
            consentLabel={options.consentLabel}
            status="draft"
            onClosed={onCloseDialog}
            readOnly={options.readOnly}
            helpLabel={options.helpLabel}
            withVariables={options.withVariables || false}
          />
        )}
      </Section>
    </BaseStep>
  );
};
