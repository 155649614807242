// @ts-nocheck
/* eslint-disable */

import {useSelector} from 'react-redux';
import {navigateToUsingWindow} from '@/_core/util/util';
import {useAuthToken} from '@modules/Core/hooks/state/authToken';
import {_Profile} from '@modules/Profile/types/profile.model';
import {_AppState} from '@modules/State/types/appState.model';
import {trans} from '@modules/Translations/util/i18n';

export function useProfile() {
  const currentProfile = useSelector((state: _AppState) => state.profile?.currentProfile);
  const originalProfile = useSelector((state: _AppState) => state.profile?.originalProfile);
  const {fetchToken, resetToken} = useAuthToken();

  const onLogin = async (email: string, password: string, rememberMe = false) => {
    return await fetchToken(email, password, rememberMe);
  };

  const onLogout = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    await resetToken();

    const params = new URLSearchParams();
    const redirectTo = searchParams.get('redirectTo');
    if (redirectTo) params.set('redirectTo', redirectTo);
    params.set('message', searchParams.get('message') ?? trans('login.logout_success'));

    navigateToUsingWindow(`/login${params.toString() ? `?${params}` : ''}`);
  };

  return {currentProfile, originalProfile, onLogin, onLogout} as const;
}
