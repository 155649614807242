// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {flowRoute, navigateToFlow} from '@app/_core/util/util';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {Accordion} from '@modules/Core/components/base/accordion/Accordion';
import {StatisticLabelBadge} from '@modules/Core/components/base/badge/StatisticLabelBadge';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Chip} from '@modules/Core/components/base/Chip';
import {IconOpenNew, IconTeamAnalysis} from '@modules/Core/components/base/Icons/Icons';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {
  _ProfileExerciseStatus,
  EXERCISE_STATUS_DONE,
  EXERCISE_STATUS_LOCKED,
  EXERCISE_STATUS_OPEN,
  EXERCISE_STATUS_STARTED,
} from '@modules/Profile/types/exercises.model';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {getServiceIcon, getSurveyFlow} from '@modules/Profile/util/profileUtil';
import {_ShortTeam} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';

export const statusLangProps: Record<string, string> = {
  [`${EXERCISE_STATUS_STARTED}`]: 'profile_modules.status.in_progress',
  [`${EXERCISE_STATUS_DONE}`]: 'profile_modules.status.done',
  [`${EXERCISE_STATUS_OPEN}`]: 'profile_modules.status.open',
  default: 'profile_modules.status.open',
};

interface _Props {
  service: string;
  xpMetric: _ProfileTrackingStatisticEntry;
  data: {
    progress: number;
    initialSurveyStatus: 'done' | 'open';
    initialSurveyStatusAggregated: 'done' | 'open';
    rounds: Array<{
      progress: number;
      status: _ProfileExerciseStatus;
      exercises: Array<{
        profiles?: Array<{
          status: _ProfileExerciseStatus;
          profile: _ShortProfile;
        }>;
        teams?: Array<{
          status: _ProfileExerciseStatus;
          team: _ShortTeam;
        }>;
        status: _ProfileExerciseStatus;
      }>;
    }>;
  };
  onOpenTeamBreakdown: (service: string, roundIndex: number | string) => void;
  type?: 'teams' | 'profiles';
}
export const TeamSoftfactsRow: React.FC<_Props> = ({
  type = 'profiles',
  onOpenTeamBreakdown,
  data,
  xpMetric,
  service,
}) => {
  const Icon = getServiceIcon(service);

  logger.debug('TeamSoftfactsRow', {service, xpMetric, data});

  const onBreakdownClick = (roundIndex: number | string) => {
    onOpenTeamBreakdown?.(service, roundIndex);
  };

  if (!data?.rounds?.length) {
    return null;
  }

  const titleContent = (
    <div className="flex flex-row items-center justify-between gap-4">
      <div className="flex flex-row items-center gap-1">
        {Icon && <Icon />}
        <Typography variant="body1">{trans(PROFILE_SERVICES_TITLES[service])}</Typography>
      </div>
      <div className="flex items-center justify-end gap-2 min-w-[200px]">
        {!!xpMetric?.trend && (
          <StatisticLabelBadge
            change={xpMetric?.trend}
            changeType={xpMetric?.trend > 0 ? '+' : xpMetric?.trend < 0 ? '-' : 'N'}
          />
        )}
        <div className="flex items-center justify-end gap-2">
          <div className="w-20">
            <ProgressBar value={data?.progress} color="success" size="xs" />
          </div>
          <ToolTip txt={trans('base.open_soft_fact')}>
            <Button
              minified
              variant="light"
              size="xs"
              icon={IconOpenNew}
              onClick={() => navigateToFlow(getSurveyFlow(service)?.name)}
            />
          </ToolTip>
        </div>
      </div>
    </div>
  );

  const roundEntries = Object.entries(data?.rounds ?? {}).filter(([roundIndex, roundData]) =>
    [EXERCISE_STATUS_OPEN, EXERCISE_STATUS_DONE, EXERCISE_STATUS_STARTED].includes(roundData?.status)
  );

  return (
    <Accordion titleContent={titleContent} headerGap="gap-0">
      <div className="flex flex-col gap-3 w-full">
        {roundEntries.length === 0 && !data.initialSurveyStatus && (
          <Typography variant="body">{trans('profile_exercises.softfact_not_started')}</Typography>
        )}
        {roundEntries.length > 0 ||
          (data.initialSurveyStatus && (
            <AccordionEntry
              title={trans('profile_modules.initial_survey')}
              status={type === 'single_profile' ? data.initialSurveyStatus : data.initialSurveyStatusAggregated}
            />
          ))}
        {roundEntries.map(([roundIndex, roundData]) => (
          <AccordionEntry
            title={trans('profile_exercises.round', {
              roundIndex: Number(roundIndex) + 1,
            })}
            roundIndex={roundIndex}
            status={roundData.status}
            onBreakdownClick={onBreakdownClick}
          />
        ))}
      </div>
    </Accordion>
  );
};

const AccordionEntry: React.FC<{
  roundIndex?: number | string;
  status: _ProfileExerciseStatus;
  onBreakdownClick?: (roundIndex: number | string) => void;
  type?: 'single_profile' | 'teams';
  title?: string;
}> = ({roundIndex, status, onBreakdownClick, type = 'single_profile', title}) => {
  return (
    <div key={roundIndex} className="flex flex-col gap-3">
      <div className="flex flex-row w-full items-center justify-between">
        <div className="flex flex-row w-full items-center gap-0">
          <Typography variant="body-bold">{title}</Typography>

          {![EXERCISE_STATUS_LOCKED].includes(status) && (
            <Typography
              variant="body"
              className={
                status === EXERCISE_STATUS_STARTED
                  ? 'text-orange700'
                  : status === EXERCISE_STATUS_DONE
                    ? 'text-green700'
                    : 'text-grey500'
              }
            >
              {' - '}
              {trans(statusLangProps[status] ?? statusLangProps.default)}
            </Typography>
          )}
        </div>
        {onBreakdownClick && (
          <Chip
            iconPosition="left"
            className="w-full max-w-fit"
            label={
              type !== 'single_profile'
                ? trans('profile_modules.show_team_breakdown')
                : trans('profile_modules.show_profile_breakdown')
            }
            icon={IconTeamAnalysis}
            onClick={() => onBreakdownClick(Number(roundIndex))}
          />
        )}
      </div>
    </div>
  );
};
