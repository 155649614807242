// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {_Institution} from '@modules/Institution/types/institution.model';
import {appState, dispatchAction} from '@modules/State/util/util';
import {changeCurrentTeam} from '@modules/Team/state/TeamSlice';
import {_Team} from '../types/team.model';

export function currentTeam(): _Team | null {
  let currentTeam = appState().team?.currentTeam;
  if (!currentTeam) {
    const teams = appState().teams?.currentTeams;
    currentTeam = teams.length > 0 ? teams[0] : null;
  }
  return currentTeam;
}

export function team(teamId: string) {
  return appState().teams?.currentTeams?.find(team => team.id === teamId);
}

export function setCurrentTeam(teamId: string) {
  dispatchAction(changeCurrentTeam(teamId));
}
