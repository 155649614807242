// @ts-nocheck
/* eslint-disable */

// add another one without ns
import {isArray} from '@modules/Core/util/typesUtil';
import {appState, getStore} from '@modules/State/util/util';

type TranslationParams = Record<string, string | number>;

export function trans(word?: string | null, params: TranslationParams = {}, returnPlaceholder = true): string {
  const t = appState().i18n.translations;
  const defaultValue = returnPlaceholder ? `NA__${word}__NA` : '';
  try {
    if (!word || !t) {
      return defaultValue;
    }
    const langKeySegments = word.split('.');
    const section = t[langKeySegments[0]];
    if (!section) return defaultValue;

    const rawResult = section[langKeySegments.slice(1).join('.')] as string;
    if (!rawResult) return defaultValue;

    const result = replaceParams(rawResult, params);

    if (isArray(result)) {
      return result;
    }

    return result || defaultValue;
  } catch (e) {
    return defaultValue;
  }
}

export function replaceParams(text: string, params: TranslationParams): string {
  if (!text || !params) {
    return text;
  }

  // Handle pluralization if the text contains a pipe
  if (text.includes('|')) {
    // Find the first numeric parameter that appears in the text
    const [singular, plural] = text.split('|').map(form => form.trim());
    for (const [name, value] of Object.entries(params)) {
      if (typeof value === 'number' && text.includes(`:${name}`)) {
        text = value === 1 ? singular : plural;
        break;
      }
    }
  }

  // Replace all parameters
  for (const [name, value] of Object.entries(params)) {
    text = text.replaceAll(`:${name}`, String(value));
  }
  return text;
}

export function currentLanguage(): string {
  const store = getStore();
  return store?.getState().i18n.language ?? 'en';
}

export function isLangPropKey(key: string): boolean {
  // Will consist of section1.section2. ..., has to have at least 1 dot 2 segments
  // also, no spaces
  return key.split('.').length > 1 && !key.includes(' ');
}
