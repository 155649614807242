// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  SHARED_WITH_OTHERS_TYPE,
  SHARED_WITH_YOU,
  SHARED_WITH_YOU_TYPE,
} from '@modules/ContentEngine/components/items/profile/result/sharedResults/ProfileSharedResultsItem';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Section} from '@modules/Core/components/layout/Section';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  type: SHARED_WITH_YOU_TYPE | SHARED_WITH_OTHERS_TYPE;
}

export const ThirdPersonFeedbackPlaceholder: React.FC<_Props> = ({type}) => {
  const navigateToFlow = useNavigateToFlow();

  const description =
    type === SHARED_WITH_YOU
      ? trans('profile.third_person_feedback.empty_card.free_text')
      : trans('profile.third_person_feedback.empty_card.free_text_shared_with_others');
  return (
    <Section image="/assets/img/design_images/360 feedback.png" description={description} descriptionVariant="body1" />
  );
};
