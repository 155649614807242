// @ts-nocheck
/* eslint-disable */

import React, {useRef, useState, useCallback, useEffect} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconTrash, IconCycle, IconPhoto, IconAdd} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {Typography} from '@modules/Core/components/base/Typography';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {uploadMultipleImages, deleteImage} from '@modules/Core/services/imageUploadApi';
import {trans} from '@modules/Translations/util/i18n';
import {IconButton} from '../buttons/IconButton';

export interface UploadedImage {
  id: string;
  original: string;
  sizes: {
    small: string; // 200x200
    medium: string; // 300x300
    large: string; // 1024x1024
  };
}

interface MultiImageUploadProps {
  onImagesUploaded: (images: UploadedImage[]) => void;
  maxFiles?: number;
  acceptedFileTypes?: string;
  uploadEndpoint?: string;
  showPreview?: boolean;
  previewSize?: 'small' | 'medium' | 'large';
  buttonLabel?: string;
  className?: string;
  dragDropLabel?: string;
  initialImages?: UploadedImage[];
}

export const MultiImageUpload: React.FC<MultiImageUploadProps> = ({
  onImagesUploaded,
  maxFiles = 10,
  acceptedFileTypes = 'image/*',
  uploadEndpoint = 'images/upload-multiple',
  showPreview = true,
  previewSize = 'medium',
  buttonLabel,
  className = '',
  dragDropLabel,
  initialImages = [],
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>(initialImages);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const snackbar = useSnackbar();

  // Initialize with initial images
  useEffect(() => {
    if (initialImages && initialImages.length > 0) {
      setUploadedImages(initialImages);
    }
  }, [initialImages]);

  const openFilePicker = (): void => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const processFiles = useCallback(
    async (files: File[]): Promise<void> => {
      if (files.length === 0) return;

      if (files.length + uploadedImages.length > maxFiles) {
        snackbar.warning(trans('upload.max_files_exceeded', {count: maxFiles}));
        return;
      }

      // Create local previews
      const previewPromises = files.map(async file => {
        return await new Promise<string>(resolve => {
          const reader = new FileReader();
          reader.onload = e => {
            if (e.target?.result) {
              resolve(e.target.result as string);
            }
          };
          reader.readAsDataURL(file);
        });
      });

      const previews = await Promise.all(previewPromises);
      setPreviewImages(prev => [...prev, ...previews]);

      // Upload files
      setUploading(true);
      setUploadProgress(0);
      try {
        const formData = new FormData();
        files.forEach(file => {
          formData.append('images[]', file);
        });

        const result = await uploadMultipleImages(formData);

        if (result.success) {
          const newImages = result.images;
          setUploadedImages(prev => [...prev, ...newImages]);
          onImagesUploaded([...uploadedImages, ...newImages]);
          snackbar.success(trans('upload.success'));
          setUploadProgress(100);
        } else {
          throw new Error(result.message || 'Upload failed');
        }
      } catch (error) {
        console.error('Upload error:', error);
        snackbar.danger(trans('upload.error'));
        // Remove the previews that failed to upload
        setPreviewImages(prev => prev.slice(0, prev.length - files.length));
      } finally {
        setUploading(false);
        // Clear the input to allow uploading the same file again
        if (inputFileRef.current) {
          inputFileRef.current.value = '';
        }
      }
    },
    [maxFiles, uploadedImages, onImagesUploaded, snackbar]
  );

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const files = Array.from(event.target.files);
    await processFiles(files);
  };

  const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const files = Array.from(e.dataTransfer.files);
        // Filter only accepted file types
        const acceptedFiles = files.filter(file => {
          if (acceptedFileTypes === '*') return true;
          return file.type.match(acceptedFileTypes);
        });

        if (acceptedFiles.length > 0) {
          await processFiles(acceptedFiles);
        }
      }
    },
    [acceptedFileTypes, uploadedImages, maxFiles, processFiles]
  );

  const removeImage = async (index: number): Promise<void> => {
    const imageToRemove = uploadedImages[index];

    if (imageToRemove) {
      try {
        // Only call the delete API if the image was uploaded in this session
        // For initial images, we just remove them from the state
        const result = initialImages.some(img => img.id === imageToRemove.id)
          ? {success: true}
          : await deleteImage(imageToRemove.id);

        if (result.success) {
          const newUploadedImages = [...uploadedImages];
          const newPreviewImages = [...previewImages];

          newUploadedImages.splice(index, 1);
          newPreviewImages.splice(index, 1);

          setUploadedImages(newUploadedImages);
          setPreviewImages(newPreviewImages);

          // Notify parent component
          onImagesUploaded(newUploadedImages);

          snackbar.success(trans('upload.image_removed'));
        } else {
          throw new Error(result.message || 'Failed to remove image');
        }
      } catch (error) {
        console.error('Error removing image:', error);
        snackbar.danger(trans('upload.remove_error'));
      }
    }
  };

  return (
    <div className={`flex flex-col gap-0 ${className}`}>
      <div className="flex items-center gap-0 h-3 mb-1">
        <Typography variant="body2" bold>
          {trans('upload.journal_attached_images')}
        </Typography>

        <Input
          hidden
          id="multi-image-upload"
          dusk="multi-image-upload"
          type="file"
          accept={acceptedFileTypes}
          multiple
          elRef={inputFileRef}
          onChange={async (_, e) => await (e && handleFileChange(e))}
        />

        <div
          className={`flex items-center justify-center ${dragActive ? 'border-blue700 bg-blue100' : 'border-grey300'}`}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
        >
          <IconButton
            onClick={openFilePicker}
            icon={IconAdd}
            disabled={uploading || uploadedImages.length >= maxFiles}
          />
        </div>
      </div>

      {uploading && (
        <div>
          <Typography className="text-blue700 mb-1">{trans('upload.uploading')}...</Typography>
          <div className="w-full bg-grey200 rounded-full h-2.5">
            <div
              className="bg-blue700 h-2.5 rounded-full transition-all duration-300"
              style={{width: `${uploadProgress}%`}}
            />
          </div>
        </div>
      )}

      {showPreview && uploadedImages.length > 0 && (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {uploadedImages.map((image, index) => (
            <div key={image.id} className="relative group">
              <img
                src={image.sizes[previewSize] || image.original}
                alt={`Uploaded image ${index + 1}`}
                className="w-full h-40 object-cover rounded-md border border-grey300"
              />
              <button
                type="button"
                onClick={async () => await removeImage(index)}
                className="absolute top-2 right-2 bg-red700 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                aria-label={trans('upload.remove_image')}
              >
                <IconTrash size="sm" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
