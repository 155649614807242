// @ts-nocheck
/* eslint-disable */

import React, {createRef, useState, useMemo} from 'react';
import {createPortal} from 'react-dom';
import {twMerge} from 'tailwind-merge';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {Chip} from '@modules/Core/components/base/Chip';
import {Divider} from '@modules/Core/components/base/Divider';
import {IconCancel, IconTeam} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {ListItem} from '@modules/Core/components/base/inputs/ListItem';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {extractProfilesInfo, extractTeamsInfo} from '@modules/Core/util/profileUtils';
import {currentInstitution} from '@modules/Institution/util/institutionUtil';
import {trans} from '@modules/Translations/util/i18n';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

interface Profile {
  id: string;
  email: string;
  fullName: string;
  profilePicture: string | null;
}

interface Team {
  name: string;
  profiles: Profile[];
}

interface _Props {
  emails: string[];
  setEmails?: (emails: string[]) => void;
  helpLabel?: string;
  consentLabel?: string;
  placeholder?: string;
  label?: string;
  errorEmails?: Record<string, any>;
  disabled?: boolean;
  teams?: Team[];
  users?: Profile[];
  readOnly?: boolean;
}

interface AutocompleteItem {
  type: 'team' | 'user' | 'email';
  email?: string;
  fullName?: string;
  profilePicture?: string | null;
  profiles?: Profile[];
  name?: string;
}

export const Emails: React.FC<_Props> = ({
  emails,
  setEmails,
  helpLabel = trans('invite.email_description'),
  consentLabel,
  placeholder,
  label,
  errorEmails,
  disabled,
  readOnly,
}) => {
  const ref = createRef<HTMLInputElement>();
  const [emailError, setEmailError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({top: 0, left: 0, width: 0});
  const inputRef = createRef<HTMLInputElement>();

  const institution = currentInstitution();
  const users = extractProfilesInfo(institution);
  const teams = extractTeamsInfo(institution);

  const suggestions = useMemo(() => {
    const items: AutocompleteItem[] = [];
    const searchTerm = inputValue.toLowerCase();

    // Add matching teams
    if (!searchTerm) {
      // Show at least 2 teams when no filter
      teams.slice(0, 2).forEach(team => {
        items.push({
          type: 'team',
          name: team.name,
          profiles: team.profiles,
        });
      });
    } else {
      teams.forEach(team => {
        if (team.name.toLowerCase().includes(searchTerm)) {
          items.push({
            type: 'team',
            name: team.name,
            profiles: team.profiles,
          });
        }
      });
    }

    // Add matching users
    if (!searchTerm) {
      // Show at least 3 users when no filter
      users.slice(0, 3).forEach(user => {
        if (!emails.includes(user.email)) {
          items.push({
            type: 'user',
            email: user.email,
            fullName: user.fullName,
            profilePicture: user.profilePicture,
          });
        }
      });
    } else {
      users.forEach(user => {
        if (
          (user.email.toLowerCase().includes(searchTerm) || user.fullName?.toLowerCase().includes(searchTerm)) &&
          !emails.includes(user.email)
        ) {
          items.push({
            type: 'user',
            email: user.email,
            fullName: user.fullName,
            profilePicture: user.profilePicture,
          });
        }
      });
    }

    // If input looks like an email and doesn't match any existing user
    if (inputValue && !items.some(item => item.type === 'user' && item.email === inputValue)) {
      items.push({
        type: 'email',
        email: inputValue,
      });
    }

    return items;
  }, [inputValue, teams, users, emails]);

  const addUniqueEmails = (newEmails: string[]) => {
    const uniqueEmails = Array.from(new Set([...emails, ...newEmails]));
    setEmails?.(uniqueEmails);
    setShowSuggestions(false);
  };

  const handleSuggestionClick = (item: AutocompleteItem) => {
    if (item.type === 'team' && item.profiles) {
      const teamEmails = item.profiles.map(profile => profile.email);
      addUniqueEmails(teamEmails);
      setInputValue('');
    } else if (item.type === 'user' && item.email) {
      addUniqueEmails([item.email]);
      setInputValue('');
    } else if (item.type === 'email' && item.email) {
      if (checkEmail(item.email)) {
        addUniqueEmails([item.email]);
        setInputValue('');
      }
    }
  };

  const updateDropdownPosition = () => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  };

  const handleInputFocus = () => {
    setShowSuggestions(true);
    setSelectedSuggestionIndex(-1);
    updateDropdownPosition();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (disabled) return;

    if (showSuggestions && suggestions.length > 0) {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setSelectedSuggestionIndex(prev => (prev < suggestions.length - 1 ? prev + 1 : prev));
        return;
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedSuggestionIndex(prev => (prev > 0 ? prev - 1 : -1));
        return;
      }
      if (event.key === 'Enter' && selectedSuggestionIndex >= 0) {
        event.preventDefault();
        handleSuggestionClick(suggestions[selectedSuggestionIndex]);
        setSelectedSuggestionIndex(-1);
        return;
      }
    }

    if (['Enter', 'Tab', ',', ' '].includes(event.key)) {
      event.preventDefault();
      const value = inputValue.trim();

      if (!value) return;

      // If there's an exact match in suggestions, use that
      const exactMatch = suggestions.find(
        item =>
          (item.type === 'user' && item.email?.toLowerCase() === value.toLowerCase()) ||
          (item.type === 'team' && item.name?.toLowerCase() === value.toLowerCase())
      );

      if (exactMatch) {
        handleSuggestionClick(exactMatch);
        return;
      }

      // Otherwise, treat as a regular email
      if (checkEmail(value)) {
        addUniqueEmails([value]);
        setInputValue('');
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (disabled) return;

    event.preventDefault();
    const pasteData = event.clipboardData.getData('text');

    // Split by common separators (comma, semicolon, newline, space)
    const emailList = pasteData
      .split(/[,;\n\s]+/)
      .map(email => email.trim())
      .filter(email => email.length > 0 && emailRegex.test(email));

    if (emailList.length > 0) {
      addUniqueEmails(emailList);
      setInputValue('');
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setShowSuggestions(false);

      const value = inputValue.trim();
      if (value && checkEmail(value)) {
        addUniqueEmails([value]);
        setInputValue('');
      }
    }, 200);
  };

  function hasError(email: string): boolean {
    if (Object.keys(errorEmails ?? {})?.length > 0) {
      return Object.keys(errorEmails ?? {})?.findIndex(e => e === email) > -1;
    }
    return false;
  }

  const handleDeleteChip = (email: string) => {
    setEmails?.(emails ? emails.filter((c: string) => c !== email) : []);
  };

  function checkEmail(email: string): boolean {
    if (emailRegex.test(email.trim())) {
      setEmailError(null);
      setAttemptedSubmit(false);
      return true;
    }
    setEmailError(trans('validation.form_validation.email'));
    setAttemptedSubmit(true);
    setShowSuggestions(false);
    return false;
  }

  const handleInputChange = (value: string) => {
    setInputValue(value);
    setShowSuggestions(!!value);
    setSelectedSuggestionIndex(-1);
    setAttemptedSubmit(false);
  };

  React.useEffect(() => {
    if (showSuggestions) {
      const handlePositionUpdate = () => {
        updateDropdownPosition();
      };

      window.addEventListener('resize', handlePositionUpdate);
      window.addEventListener('scroll', handlePositionUpdate, true);

      return () => {
        window.removeEventListener('resize', handlePositionUpdate);
        window.removeEventListener('scroll', handlePositionUpdate, true);
      };
    }
  }, [showSuggestions]);

  return (
    <Section fullWidth gap={2}>
      <div className="relative">
        {readOnly && <div className="text-body2-bold user-select-none pointer-events-none">{label}</div>}
        {!readOnly && (
          <Input
            disabled={disabled}
            elRef={inputRef}
            value={inputValue}
            label={label ?? ''}
            placeholder={placeholder ?? ''}
            onChange={(value: string) => handleInputChange(value)}
            onFocus={handleInputFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            helpLabel={helpLabel}
          />
        )}

        {showSuggestions &&
          suggestions.length > 0 &&
          createPortal(
            <div
              className="fixed z-[10000] flex flex-col items-start rounded-b-sm border-1 border-grey300 overflow-hidden shadow-2 bg-white500 max-h-[300px] overflow-y-auto"
              style={{
                top: `${dropdownPosition.top}px`,
                left: `${dropdownPosition.left}px`,
                width: `${dropdownPosition.width}px`,
              }}
            >
              {suggestions.map((item, index) => {
                const isLastTeam = item.type === 'team' && suggestions[index + 1]?.type !== 'team';

                return (
                  <React.Fragment key={`${item.type}-${item.email || item.fullName}-${index}`}>
                    <div
                      className={twMerge(
                        'w-full hover:bg-grey100 cursor-pointer p-2',
                        index === selectedSuggestionIndex && 'bg-grey100'
                      )}
                      onClick={() => handleSuggestionClick(item)}
                    >
                      <div className="flex items-center gap-2">
                        {item.type === 'team' && (
                          <>
                            <div className="bg-primary500 rounded-full p-1 text-white500">
                              <IconTeam size="sm" />
                            </div>
                            <div>
                              <div className="font-medium">{item.name}</div>
                              <div className="text-sm text-grey600">{item.profiles?.length} members</div>
                            </div>
                          </>
                        )}
                        {item.type === 'user' && (
                          <>
                            {item.profilePicture ? (
                              <Avatar size="sm" image={item.profilePicture} />
                            ) : (
                              <Avatar size="sm" initials={item.fullName?.[0] || item.email?.[0]} />
                            )}
                            <div>
                              {item.fullName && <div className="font-medium">{item.fullName}</div>}
                              <div className="text-sm text-grey600">{item.email}</div>
                            </div>
                          </>
                        )}
                        {item.type === 'email' && (
                          <div className="text-grey600">
                            Add new email: <span className="font-medium">{item.email}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {isLastTeam && <Divider />}
                  </React.Fragment>
                );
              })}
            </div>,
            document.body
          )}
      </div>

      {emails.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {emails.map(email => {
            const emailHasError = hasError(email);
            const user = users.find(u => u.email === email);

            const chipComponent = (
              <div key={email} className="flex items-center gap-2">
                <Chip
                  size="sm"
                  label={user?.fullName || email}
                  onClick={() => handleDeleteChip(email)}
                  className="hover:bg-brand500"
                >
                  {user?.profilePicture && <Avatar size="sm" image={user.profilePicture} />}
                </Chip>
              </div>
            );

            if (emailHasError) {
              return (
                <ToolTip key={`${email}_tooltip`} txt={errorEmails?.[email]?.message} plc="top">
                  {chipComponent}
                </ToolTip>
              );
            }
            return chipComponent;
          })}
        </div>
      )}

      {emailError && inputValue && attemptedSubmit && (
        <Typography variant="body" className="text-typography-danger">
          {emailError}
        </Typography>
      )}

      {consentLabel && (
        <Typography variant="caption">
          <div dangerouslySetInnerHTML={{__html: consentLabel}} />
        </Typography>
      )}
    </Section>
  );
};
