// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {useDispatch} from 'react-redux';
import {absoluteUrl, flowRoute} from '@app/_core/util/util';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconShow, IconTrash, IconUnlock} from '@modules/Core/components/base/Icons/Icons';
import {_TableColumns, _TableDataItem, Table} from '@modules/Core/components/base/table/Table';
import {Typography} from '@modules/Core/components/base/Typography';
import {formatDate} from '@modules/Core/util/dates';
import {fireConfirmationDialog} from '@modules/Core/util/dialogUtil';
import {isSuperAdmin} from '@modules/Core/util/rolesAndPermissionsUtil';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {flowPopup} from '@modules/FlowEngine/util/dialogFlowsUtil';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {startImpersonation} from '@modules/Profile/state/profileSlice';
import {_ProfileSharedResult} from '@modules/Profile/types/shared-results.model';
import {currentProfile, getProfilePic} from '@modules/Profile/util/profileUtil';
import {revokeSharedResultAction} from '@modules/Profile/util/sharedResultsActionsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileSharedResultsWithYouTableProps {
  data: _ProfileSharedResult[];
}

export const ProfileSharedResultsWithYouTable: React.FC<_ProfileSharedResultsWithYouTableProps> = ({data}) => {
  const dispatch = useDispatch();
  const navigateToFlow = useNavigateToFlow();

  const columns: _TableColumns = [
    {label: trans('base.name'), key: 'name', width: 'relative-col-width-4-plus-gap'},
    {label: trans('base.shared_at'), key: 'created_at', width: 'relative-col-width-2-plus-gap'},
    {label: trans('base.status'), key: 'status', width: 'relative-col-width-3-plus-gap'},
    {label: trans('base.actions'), key: 'action', width: 'relative-col-width-1', align: 'center'},
  ];

  const handleTakeALook = async (row: _ProfileSharedResult) => {
    // @ts-expect-error - Redux Toolkit's createAsyncThunk returns a complex type that TypeScript struggles with
    await dispatch(startImpersonation(row.profile.id));
    navigateToFlow(FLOWS.ProfileDashboard);
  };

  const handleRevoke = async (row: _ProfileSharedResult): Promise<void> => {
    fireConfirmationDialog({
      // title: trans('base.confirm_revoke_title'),
      description: trans('shared_results.confirm_revoke_description'),
      onConfirm: async () => {
        await revokeSharedResultAction(row.id);
      },
    });
  };

  const handleRequestResult = (row: _ProfileSharedResult): void => {
    flowPopup(FLOWS.RequestResult, {
      emails: [row.profile.email],
      acceptLink: absoluteUrl(flowRoute(FLOWS.Softfacts, null, {shareWith: currentProfile()?.email})),
    });
  };

  const translateStatus = (status: string): string => {
    const statusMap: Record<string, string> = {
      legacy: trans('shared_results.legacy_status'),
      accepted: trans('base.shared'),
      requested: trans('base.requested'),
    };
    return statusMap[status] ?? trans('base.pending');
  };

  const renderRow = (row: _ProfileSharedResult): _TableDataItem => {
    return {
      name: <AvatarUser label={row.profile?.fullName} image={getProfilePic(row.profile)} />,
      created_at: <Typography>{formatDate(new Date(row.created_at))}</Typography>,
      status: <Typography>{translateStatus(row.status)}</Typography>,
      action: (
        <div className="flex justify-end gap-2">
          {row.status === 'accepted' && (
            <IconButton
              icon={IconShow}
              onClick={async () => await handleTakeALook(row)}
              label={trans('base.take_a_look')}
              color="light"
            />
          )}
          {row.status !== 'accepted' && (
            <IconButton
              icon={IconUnlock}
              onClick={() => handleRequestResult(row)}
              label={trans('base.request_access')}
              color="light"
            />
          )}
          <IconButton
            icon={IconTrash}
            onClick={async () => await handleRevoke(row)}
            label={trans('base.delete')}
            color="light"
          />
        </div>
      ),
    };
  };

  return (
    <Table
      data={data}
      columns={columns}
      renderRow={renderRow}
      filterOptions={{
        enabled: true,
        searchAttributes: ['profile.fullName', 'created_at'],
      }}
    />
  );
};
