// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {Action} from '@modules/Core/components/layout/Action';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import {isImpersonating} from '@modules/Core/util/rolesAndPermissionsUtil';

interface _ServiceInformationProps {
  actions: _UIAction[];
  alignActions?: 'center' | 'start';
  actionsDirection?: 'row' | 'column';
}

export const ServiceInformationActions: React.FC<_ServiceInformationProps> = ({
  alignActions = 'start',
  actionsDirection = 'row',
  actions,
}) => {
  if (isImpersonating()) {
    return null;
  }
  return (
    <div
      className={addCN(
        'flex',
        actionsDirection === 'column' ? 'gap-2' : 'gap-3',
        actionsDirection === 'column' ? 'flex-col' : 'flex-row',
        actionsDirection === 'column' ? `justify-center` : `items-${alignActions}`,
        actionsDirection === 'column' ? `items-${alignActions}` : `justify-center`
      )}
    >
      {actions?.map(action => {
        return (
          <Action
            key={action.uuid}
            type={action.type}
            onClick={action.onClick}
            label={action.label}
            width={action.width}
          />
        );
      })}
    </div>
  );
};
