// @ts-nocheck
/* eslint-disable */

import React, {JSX} from 'react';
import {ActionsItem} from '@modules/ContentEngine/components/items/ActionsItem';
import {InstitutionLeaderboardItem} from '@modules/ContentEngine/components/items/gamification/InstitutionLeaderboardItem';
import {LeaderboardItem} from '@modules/ContentEngine/components/items/gamification/LeaderboardItem';
import {MetricPeriodReportItem} from '@modules/ContentEngine/components/items/gamification/MetricPeriodReportItem';
import {ProfileLevelProgressionItem} from '@modules/ContentEngine/components/items/gamification/ProfileLevelProgressionItem';
import {XPItem} from '@modules/ContentEngine/components/items/gamification/XPItem';
import {CandidateAnalysisOverviewItem} from '@modules/ContentEngine/components/items/general/candidate-analysis/CandidateAnalysisOverviewItem';
import {CandidateAnalysisResultItem} from '@modules/ContentEngine/components/items/general/candidate-analysis/CandidateAnalysisResultItem';
import {DebugItem} from '@modules/ContentEngine/components/items/general/DebugItem';
import {EmailsOverviewItem} from '@modules/ContentEngine/components/items/general/emails/EmailsOverviewItem';
import {ErrorItem} from '@modules/ContentEngine/components/items/general/ErrorItem';
import {FeedbackAnalysisListItem} from '@modules/ContentEngine/components/items/general/feedback-analysis/FeedbackAnalysisListItem';
import {SidebarPromptsItem} from '@modules/ContentEngine/components/items/general/SidebarPromptsItem';
import {SoftfactionaryItem} from '@modules/ContentEngine/components/items/general/SoftfactionaryItem';
import {AnswerCustomSurveyItem} from '@modules/ContentEngine/components/items/general/surveys/AnswerCustomSurveyItem';
import {CustomSurveyResultItem} from '@modules/ContentEngine/components/items/general/surveys/CustomSurveyResultItem';
import {CustomSurveysOverviewItem} from '@modules/ContentEngine/components/items/general/surveys/CustomSurveysOverviewItem';
import {SurveyCreatorItem} from '@modules/ContentEngine/components/items/general/surveys/SurveyCreatorItem';
import {TutorialsItem} from '@modules/ContentEngine/components/items/general/TutorialsItem';
import {GraphItem} from '@modules/ContentEngine/components/items/GraphItem';
import {GridItem} from '@modules/ContentEngine/components/items/layout/GridItem';
import {OrganisationExercisesItem} from '@modules/ContentEngine/components/items/organisation/OrganisationExercisesItem';
import {OrganisationGraphItem} from '@modules/ContentEngine/components/items/organisation/OrganisationGraphItem';
import {OrganisationMembersItem} from '@modules/ContentEngine/components/items/organisation/OrganisationMembersItem';
import {OrganisationSoftfactsOverviewItem} from '@modules/ContentEngine/components/items/organisation/OrganisationSoftfactsOverviewItem';
import OrganisationTeamDevelopmentItem from '@modules/ContentEngine/components/items/organisation/OrganisationTeamDevelopmentItem';
import {OrganisationTeamsOverviewItem} from '@modules/ContentEngine/components/items/organisation/OrganisationTeamsOverviewItem';
import {OrganisationTopPerformersItem} from '@modules/ContentEngine/components/items/organisation/OrganisationTopPerformersItem';
import {PaymentItem} from '@modules/ContentEngine/components/items/payment/PaymentItem';
import {ProfileFeedbackItem} from '@modules/ContentEngine/components/items/profile/common/ProfileFeedbackItem';
import {ProfileGamificationInfoItem} from '@modules/ContentEngine/components/items/profile/common/ProfileGamificationInfoItem';
import {ProfileModulesItem} from '@modules/ContentEngine/components/items/profile/common/ProfileModulesItem';
import {ProfileSoftfactionaryItem} from '@modules/ContentEngine/components/items/profile/common/ProfileSoftfactionaryItem';
import {ProfileSoftfactsOverviewItem} from '@modules/ContentEngine/components/items/profile/common/ProfileSoftfactsOverviewItem';
import {PublicProfileItem} from '@modules/ContentEngine/components/items/profile/common/PublicProfileItem';
import {HowAreYouItem} from '@modules/ContentEngine/components/items/profile/dashboard/HowAreYouItem';
import {ProfileDashboardSoftfactsOverviewItem} from '@modules/ContentEngine/components/items/profile/dashboard/ProfileDashboardSoftfactsOverviewItem';
import {ProfileExercisesItem} from '@modules/ContentEngine/components/items/profile/dashboard/ProfileExercisesItem';
import {ProfileSatisfactionGraphItem} from '@modules/ContentEngine/components/items/profile/dashboard/ProfileSatisfactionGraphItem';
import {ProfileLockedExercisesItem} from '@modules/ContentEngine/components/items/profile/exercises/ProfileLockedExercisesItem';
import {ProfileJournalItem} from '@modules/ContentEngine/components/items/profile/journals/ProfileJournalItem';
import {ProfileSoftFactsHeatmapItem} from '@modules/ContentEngine/components/items/profile/ProfileSoftFactsHeatmapItem';
import {ProfileOldServiceChallengesGoalsReady} from '@modules/ContentEngine/components/items/profile/result/common/ProfileOldServiceChallengesGoalsReady';
import {ProfileResultItem} from '@modules/ContentEngine/components/items/profile/result/common/ProfileResultItem';
import {ProfileServiceChallengesItem} from '@modules/ContentEngine/components/items/profile/result/common/ProfileServiceChallengesItem';
import {ProfileServiceExercisesItem} from '@modules/ContentEngine/components/items/profile/result/common/ProfileServiceExercisesItem';
import {ProfileServiceFacetsItem} from '@modules/ContentEngine/components/items/profile/result/common/ProfileServiceFacetsItem';
import {ProfileServiceGraphItem} from '@modules/ContentEngine/components/items/profile/result/common/ProfileServiceGraphItem';
import {ThirdPersonFeedbackHeaderItem} from '@modules/ContentEngine/components/items/profile/result/feedback360/ThirdPersonFeedbackHeadertem';
import {ThirdPersonFeedbackOverviewItem} from '@modules/ContentEngine/components/items/profile/result/feedback360/ThirdPersonFeedbackOverviewItem';
import {ThirdPersonFeedbackPersonalityTraitsItem} from '@modules/ContentEngine/components/items/profile/result/feedback360/ThirdPersonFeedbackPersonalityTraitsItem';
import {ThirdPersonFeedbackQualitativeFeedbackItem} from '@modules/ContentEngine/components/items/profile/result/feedback360/ThirdPersonFeedbackQualitativeFeedbackItem';
import {ThirdPersonFeedbackRolesItem} from '@modules/ContentEngine/components/items/profile/result/feedback360/ThirdPersonFeedbackRolesItem';
import {ProfileMotivesItem} from '@modules/ContentEngine/components/items/profile/result/msa/ProfileMotivesItem';
import {ProfilePersonalityTraitsDetailsItem} from '@modules/ContentEngine/components/items/profile/result/personality/ProfilePersonalityTraitsDetailsItem';
import {ProfilePersonalityTraitsItem} from '@modules/ContentEngine/components/items/profile/result/personality/ProfilePersonalityTraitsItem';
import {ProfileRolesItem} from '@modules/ContentEngine/components/items/profile/result/roles/ProfileRolesItem';
import {ProfileSharedResultsItem} from '@modules/ContentEngine/components/items/profile/result/sharedResults/ProfileSharedResultsItem';
import {ProfileValuesItem} from '@modules/ContentEngine/components/items/profile/result/values/ProfileValuesItem';
import {ProfileWayOfWorkingCommunicationChannelsItem} from '@modules/ContentEngine/components/items/profile/result/way_of_working/ProfileWayOfWorkingCommunicationChannelsItem';
import {ProfileWayOfWorkingDimensionsItem} from '@modules/ContentEngine/components/items/profile/result/way_of_working/ProfileWayOfWorkingDimensionsItem';
import {ProfileWayOfWorkingTimeManagementItem} from '@modules/ContentEngine/components/items/profile/result/way_of_working/ProfileWayOfWorkingTimeManagementItem';
import ProfileTeamDevelopmentItem from '@modules/ContentEngine/components/items/profile/teams/ProfileTeamDevelopmentItem';
import ProfileTeamSelectionItem from '@modules/ContentEngine/components/items/profile/teams/ProfileTeamSelectionItem';
import {CustomizableSectionContentItem} from '@modules/ContentEngine/components/items/sections/CustomizableSectionContentItem';
import {CustomizableSectionItem} from '@modules/ContentEngine/components/items/sections/CustomizableSectionItem';
import {CustomizableStatisticSectionItem} from '@modules/ContentEngine/components/items/sections/CustomizableStatisticSectionItem';
import {SectionItem} from '@modules/ContentEngine/components/items/sections/SectionItem';
import {TabsItem} from '@modules/ContentEngine/components/items/TabsItem';
import {TeamExercisesItem} from '@modules/ContentEngine/components/items/teams/dashboard/TeamExercisesItem';
import {TeamSoftfactsOverviewItem} from '@modules/ContentEngine/components/items/teams/dashboard/TeamSoftfactsOverviewItem';
import {TeamInfoItem} from '@modules/ContentEngine/components/items/teams/invitations/TeamInfoItem';
import {TeamProfilesOverviewItem} from '@modules/ContentEngine/components/items/teams/statistics/TeamProfilesOverviewItem';
import {TeamTopPerformersItem} from '@modules/ContentEngine/components/items/teams/statistics/TeamTopPerformersItem';
import {TeamMotivesItem} from '@modules/ContentEngine/components/items/teams/team_analysis/motives/TeamMotivesItem';
import {TeamPersonalityTraitsItem} from '@modules/ContentEngine/components/items/teams/team_analysis/personality/TeamPersonalityTraitsResultItem';
import {TeamPersonalityWordsCloudItem} from '@modules/ContentEngine/components/items/teams/team_analysis/personality/TeamPersonalityWordsCloudItem';
import {TeamRolesItem} from '@modules/ContentEngine/components/items/teams/team_analysis/roles/TeamRolesItem';
import {TeamAnalysisNavigationItem} from '@modules/ContentEngine/components/items/teams/team_analysis/TeamAnalysisNavigationItem';
import {TeamAnalysisResultItem} from '@modules/ContentEngine/components/items/teams/team_analysis/TeamAnalysisResultItem';
import {TeamValuesItem} from '@modules/ContentEngine/components/items/teams/team_analysis/values/TeamValuesItem';
import {TeamWayOfWorkingDimensionsItem} from '@modules/ContentEngine/components/items/teams/team_analysis/way_of_working/TeamWayOfWorkingDimensionsItem';
import {TeamWayOfWorkingTimeManagementItem} from '@modules/ContentEngine/components/items/teams/team_analysis/way_of_working/TeamWayOfWorkingTimeManagementItem';
import {TeamDevelopmentAdminWaitItem} from '@modules/ContentEngine/components/items/teams/team_development/TeamDevelopmentAdminWaitItem';
import TeamDevelopmentOverviewItem from '@modules/ContentEngine/components/items/teams/team_development/TeamDevelopmentItem';
import {TeamDevelopmentResultItem} from '@modules/ContentEngine/components/items/teams/team_development/TeamDevelopmentResultItem';
import {TeamMembersItem} from '@modules/ContentEngine/components/items/teams/TeamMembersItem';
import {TeamMembersminiItem} from '@modules/ContentEngine/components/items/teams/TeamMembersMiniItem';
import {TeamSectionItem} from '@modules/ContentEngine/components/items/TeamSectionItem';
import {TextItem} from '@modules/ContentEngine/components/items/TextItem';
import SliderListItem from '@modules/Core/components/base/SliderList/SliderListItem';
import {logger} from '@modules/Core/util/Logger';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';
import AnswersTableItem from '../components/items/general/AnswersTableItem';
import CandidateAnswersTableItem from '../components/items/general/CandidateAnswersTableItem';
import {CardListItem} from '../components/items/general/CardListItem';
import SelectEntriesItem from '../components/items/general/SelectEntriesItem';
import {CustomSurveysListItem} from '../components/items/general/surveys/CustomSurveysListItem';
import {HtmlItem} from '../components/items/HtmlItem';
import {FormItem} from '../components/items/teams/FormItem';
import {VideoItem} from '../components/items/VideoItem';
import {_ContentEngineContext, _ContentEngineItem} from '../types/contentEngineItem.model';

export const createDynamicItemComponent = (
  item: _ContentEngineItem,
  tutorials: Record<string, _Tutorial>,
  dynamicParams: Record<string, any> = {}
): JSX.Element => {
  const RegisteredItems: Record<string, React.FC<any>> = {
    tabs: TabsItem,
    text: TextItem,
    html: HtmlItem,
    video: VideoItem,
    graph: GraphItem,
    section: SectionItem,
    customizableSection: CustomizableSectionItem,
    customizableSectionContent: CustomizableSectionContentItem,
    customizableStatisticSection: CustomizableStatisticSectionItem,
    actions: ActionsItem,
    error: ErrorItem,
    grid: GridItem,
    teamSection: TeamSectionItem,
    tutorials: TutorialsItem,
    metricPeriodReport: MetricPeriodReportItem,
    form: FormItem,
    // Profile Dashboard
    profileDashboardSoftfactsOverview: ProfileDashboardSoftfactsOverviewItem,
    profileSoftfactsOverview: ProfileSoftfactsOverviewItem,
    profileHowAreYou: HowAreYouItem,
    profileModules: ProfileModulesItem,
    profileExercises: ProfileExercisesItem,
    profileSatisfactionGraph: ProfileSatisfactionGraphItem,
    // Profile Results
    profileServiceFacets: ProfileServiceFacetsItem,
    profileServiceGraph: ProfileServiceGraphItem,
    profileServiceChallenges: ProfileServiceChallengesItem,
    profileServiceExercises: ProfileServiceExercisesItem,
    profileLockedExercises: ProfileLockedExercisesItem,
    profileResult: ProfileResultItem,
    profileOldServiceChallengesGoalsReady: ProfileOldServiceChallengesGoalsReady,
    // Profile old results
    // PA
    profilePersonalityTraits: ProfilePersonalityTraitsItem,
    profilePersonalityTraitsDetails: ProfilePersonalityTraitsDetailsItem,
    // WoW
    profileWayOfWorkingDimensions: ProfileWayOfWorkingDimensionsItem,
    profileWayOfWorkingCommunicationChannels: ProfileWayOfWorkingCommunicationChannelsItem,
    profileWayOfWorkingTimeManagement: ProfileWayOfWorkingTimeManagementItem,
    // Roles
    profileRoles: ProfileRolesItem,
    profileMotives: ProfileMotivesItem,
    // Values
    profileValues: ProfileValuesItem,
    profileJournals: ProfileJournalItem,
    profileFeedback: ProfileFeedbackItem,
    profileSoftfactionary: ProfileSoftfactionaryItem,
    publicProfile: PublicProfileItem,
    // Shared Results
    profileSharedResults: ProfileSharedResultsItem,
    thirdPersonFeedbackOverview: ThirdPersonFeedbackOverviewItem,
    thirdPersonFeedbackPersonalityTraits: ThirdPersonFeedbackPersonalityTraitsItem,
    thirdPersonFeedbackRoles: ThirdPersonFeedbackRolesItem,
    thirdPersonFeedbackQualitativeFeedback: ThirdPersonFeedbackQualitativeFeedbackItem,
    thirdPersonFeedbackHeader: ThirdPersonFeedbackHeaderItem,

    // Teams
    teamInfo: TeamInfoItem,
    teamMembers: TeamMembersItem,
    teamMembersMini: TeamMembersminiItem,
    teamAnalysisNavigation: TeamAnalysisNavigationItem,
    teamAnalysisResult: TeamAnalysisResultItem,
    teamRoles: TeamRolesItem,
    teamWayOfWorkingDimensions: TeamWayOfWorkingDimensionsItem,
    teamPersonalityTraits: TeamPersonalityTraitsItem,
    teamPersonalityWordsCloud: TeamPersonalityWordsCloudItem,
    teamDevelopmentOverview: TeamDevelopmentOverviewItem,
    teamWayOfWorkingTimeManagement: TeamWayOfWorkingTimeManagementItem,
    teamValues: TeamValuesItem,
    teamDevelopmentResult: TeamDevelopmentResultItem,
    teamDevelopmentAdminWait: TeamDevelopmentAdminWaitItem,
    teamTopPerformers: TeamTopPerformersItem,
    teamProfilesOverview: TeamProfilesOverviewItem,
    teamExercises: TeamExercisesItem,
    teamSoftfactsOverview: TeamSoftfactsOverviewItem,
    profileTeamDevelopment: ProfileTeamDevelopmentItem,
    profileTeamSelection: ProfileTeamSelectionItem,
    organisationTeamDevelopment: OrganisationTeamDevelopmentItem,
    teamMotives: TeamMotivesItem,

    // Payment
    payment: PaymentItem,
    surveyCreator: SurveyCreatorItem,
    customSurveysOverview: CustomSurveysOverviewItem,
    customSurveysList: CustomSurveysListItem,
    answerCustomSurvey: AnswerCustomSurveyItem,
    customSurveyResult: CustomSurveyResultItem,

    // Organisation
    organisationMembers: OrganisationMembersItem,
    organisationGraph: OrganisationGraphItem,
    organisationTopPerformers: OrganisationTopPerformersItem,
    organisationTeamsOverview: OrganisationTeamsOverviewItem,
    organisationExercises: OrganisationExercisesItem,
    organisationSoftfactsOverview: OrganisationSoftfactsOverviewItem,
    // General
    softfactionary: SoftfactionaryItem,
    sidebarPrompts: SidebarPromptsItem,
    // Gamification
    profileLevelProgression: ProfileLevelProgressionItem,
    profileGamificationInfo: ProfileGamificationInfoItem,
    xp: XPItem,
    leaderboard: LeaderboardItem,
    institutionLeaderboard: InstitutionLeaderboardItem,
    emailsOverview: EmailsOverviewItem,
    // Features
    candidateAnalysisOverview: CandidateAnalysisOverviewItem,
    candidateAnalysisResult: CandidateAnalysisResultItem,
    feedbackAnalysisList: FeedbackAnalysisListItem,
    // General Purpose
    selectEntries: SelectEntriesItem,
    cardList: CardListItem,
    sliderList: SliderListItem,
    debug: DebugItem,
    profileSoftFactsHeatmap: ProfileSoftFactsHeatmapItem,
    candidateAnswersTable: CandidateAnswersTableItem,
    answersTable: AnswersTableItem,
  };

  const {type, uuid, ...props} = item;

  logger.debug('ContentEngineContainer: Rendering', {
    tutorials,
    type,
  });
  logger.info({
    description: 'createDynamicItemComponent',
    type,
    key: uuid,
    data: {
      type,
      props,
      Component: RegisteredItems[type],
      RegisteredItems,
      tutorials,
      dynamicParams,
    },
  });
  const Component = RegisteredItems[type];

  // Dynamic params are runtime params, that are frontend related and should NOT be passed to the backend request
  // todo@sherif: We need to pass a key. But the key currently gets generated everytime the component is rendered and changes. It needs to be stable and passed from the outside
  return Component ? (
    <Component {...props} {...dynamicParams} context={createContext(tutorials)} />
  ) : (
    <div>Unsupported component type {type}</div>
  );
};

// This will pass any needed fields to the context of any item
function createContext(tutorials: Record<string, _Tutorial>): _ContentEngineContext {
  return {
    tutorials,
  };
}
