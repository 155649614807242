// @ts-nocheck
/* eslint-disable */

import React from 'react';
import type {RouteValue} from '../types/routes';

export const isActiveRoute = (pathname: string, pattern: string | string[] | null | undefined): boolean => {
  if (!pattern) return false;

  if (Array.isArray(pattern)) {
    // list of regex patterns
    // try to match each regex pattern
    return pattern.some(p => pathname.match(p));
  }

  return Boolean(pathname.match(pattern));
};

type RouteIconMap = {
  [K in RouteValue]: React.ElementType;
};
