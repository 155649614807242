// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {trans} from '@modules/Translations/util/i18n';

interface Answer {
  title: {
    default: string;
  };
  type: string;
  value: string | number | null | Array<string | number>;
}

interface TableRow {
  fullName: string;
  identifier: string;
  isFavorite?: boolean;
  answers: Answer[];
}

interface Props {
  columns: TableRow[];
  emptyStateComponent?: React.ReactNode;
  title?: string;
  description?: string;
  questions: Answer[];
}

export const AnswersTableItem: React.FC<Props> = ({columns, emptyStateComponent, title, description, questions}) => {
  if (!columns?.length || !questions?.length) {
    return emptyStateComponent || <div>No data to display</div>;
  }

  return (
    <Section title={title} description={description} gap={5}>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-grey100">
              <th className="p-3 text-left border-b border-grey200">{trans('base.question')}</th>
              {columns.map(col => (
                <th key={col.identifier} className="p-3 text-left border-b border-grey200">
                  {col.fullName}
                  {col.isFavorite && <span className="ml-2 text-success">★</span>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {questions.map((question, index) => (
              <tr key={index} className="border-b border-grey200 hover:bg-grey50">
                <td className="p-3">{question.title.default}</td>
                {columns.map(col => {
                  const answer = col.answers[index];
                  return (
                    <td key={col.identifier} className="p-3">
                      {answer?.type === 'rating' ? (
                        <div className="flex items-center">
                          {answer.value !== null ? (
                            <span className="px-2 py-1 bg-grey100 rounded">{answer.value}/5</span>
                          ) : (
                            <span className="text-grey400">N/A</span>
                          )}
                        </div>
                      ) : answer?.value ? (
                        Array.isArray(answer.value) ? (
                          <ul className="list-none p-[0] m-[0]">
                            {answer.value.map((item, idx) => (
                              <li key={idx}>{item}</li>
                            ))}
                          </ul>
                        ) : (
                          <span>{answer.value}</span>
                        )
                      ) : (
                        <span className="text-grey400">N/A</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Section>
  );
};

export default AnswersTableItem;
