// @ts-nocheck
/* eslint-disable */

import React, {JSX, useState} from 'react';
import {Tag} from '@modules/Core/components/base/Tag';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import CreateJournalForm from '@modules/Profile/components/journals/CreateJournalForm';
import {FilterJournals} from '@modules/Profile/components/journals/FilterJournals';
import {JournalContent} from '@modules/Profile/components/journals/JournalContent';
import {JournalEmptyContent} from '@modules/Profile/components/journals/JournalEmptyContent';
import {JournalTabs} from '@modules/Profile/components/journals/JournalTabs';
import {JOURNAL_EXERCISES_TYPE} from '@modules/Profile/config/journalsConstants';
import {_Journal, _JournalFilterParams, _JournalGroup} from '@modules/Profile/types/journal.model';
import {trans} from '@modules/Translations/util/i18n';

interface _ListJournalsProps {
  journals: Record<string, Record<string, _Journal[]>> | null;
  activeTab: string;
  journalGroups: _JournalGroup[] | null;
  filterCategory: string | null;
  filterTeamId: string | null;
  setActiveTab: (tab: string) => void;
  refresh: () => void;
  getJournalsList: (filterParams: _JournalFilterParams) => Promise<void>;
  exerciseView?: boolean;
  view?: string;
  showEntriesForOthers?: boolean;
}

export const ListJournals: React.FC<_ListJournalsProps> = ({
  journals,
  activeTab,
  journalGroups,
  filterCategory,
  filterTeamId,
  setActiveTab,
  refresh,
  getJournalsList,
  exerciseView = false,
  view = 'normal',
  showEntriesForOthers = false,
}) => {
  const [isJournalFormOpen, setIsJournalFormOpen] = useState<boolean>(false);
  const [selectedJournal, setSelectedJournal] = useState<_Journal | null>(null);

  const handleCloseForm = (confirmed?: boolean): void => {
    if (confirmed) {
      refresh?.();
    }
    setIsJournalFormOpen(false);
  };

  // Helper function to add shared tag to journal entries
  const addSharedTagToJournal = (journal: _Journal): _Journal => {
    // Create a copy of the journal object to avoid modifying the original
    const journalWithTag = {...journal};

    // Add a "sharedWithTeam" property if the journal has the shareWithTeam flag in metadata
    if (journal.metadata?.shareWithTeam === true) {
      journalWithTag.sharedWithTeam = true;
    }

    return journalWithTag;
  };

  function listJournalsComponent(group: string): JSX.Element {
    // Special handling for "all" tab
    if (group === 'all') {
      if (!journals || Object.keys(journals).length === 0) {
        if (view === 'dashboard') {
          return <></>;
        }
        return (
          <JournalEmptyContent
            activeTab={activeTab}
            filterCategory={filterCategory}
            filterTeamId={filterTeamId}
            onUpdateJournals={category => {
              if (category) {
                setActiveTab(category);
                refresh();
              }
            }}
          />
        );
      }

      const allJournals = Object.entries(journals)
        .flatMap(([groupKey, groupData]) =>
          Object.entries(groupData).flatMap(([category, journalList]) =>
            journalList.map(journal => {
              const journalWithTags = addSharedTagToJournal(journal);
              return {
                ...journalWithTags,
                title:
                  journal?.metadata?.type === JOURNAL_EXERCISES_TYPE && !exerciseView && journal.metadata.exerciseTitle
                    ? `${trans('profile_exercises.round', {
                        roundIndex: parseInt(journal.metadata.roundIndex, 10) + 1,
                      })}: ${trans(journal.metadata.exerciseTitle as string)}`
                    : journal.title,
                categoryLabel: trans(`journal.categories.${groupKey}.${category}`),
                groupLabel: ['soft_facts', 'team_analysis', 'team_development'].includes(groupKey)
                  ? trans(`journal.groups.${groupKey}`)
                  : undefined,
              };
            })
          )
        )
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

      return (
        <div className="flex flex-col gap-3">
          <JournalContent
            onEditJournal={journal => {
              setSelectedJournal(journal);
              setIsJournalFormOpen(true);
            }}
            onUpdate={refresh}
            data={allJournals}
            view={view}
          />
        </div>
      );
    }

    // Regular tab handling
    if (!Object.keys(journals?.[group] ?? {})?.length) {
      return (
        <JournalEmptyContent
          activeTab={activeTab}
          filterCategory={filterCategory}
          filterTeamId={filterTeamId}
          onUpdateJournals={category => {
            if (category) {
              setActiveTab(category);
              refresh();
            }
          }}
        />
      );
    }

    // Flatten all journals for the current group
    const groupJournals = Object.entries(journals?.[group] ?? {})
      .flatMap(([category, journalList]) =>
        journalList.map(journal => {
          const journalWithTags = addSharedTagToJournal(journal);
          return {
            ...journalWithTags,
            title:
              journal?.metadata?.type === JOURNAL_EXERCISES_TYPE && !exerciseView && journal.metadata.exerciseTitle
                ? `${trans('profile_exercises.round', {
                    roundIndex: parseInt(journal.metadata.roundIndex, 10) + 1,
                  })}: ${trans(journal.metadata.exerciseTitle as string)}`
                : journal.title,
            categoryLabel: trans(`journal.categories.${group}.${category}`),
            groupLabel: ['soft_facts', 'team_analysis', 'team_development'].includes(group)
              ? trans(`journal.groups.${group}`)
              : undefined,
          };
        })
      )
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    return (
      <div className="flex flex-col gap-3">
        <JournalContent
          onEditJournal={journal => {
            setSelectedJournal(journal);
            setIsJournalFormOpen(true);
          }}
          onUpdate={refresh}
          data={groupJournals}
          view={view}
        />
      </div>
    );
  }

  const allTab = {key: 'all', label: trans('base.all')};
  const tabs = [
    allTab,
    ...(journalGroups?.map(group => ({
      key: group.key,
      label: trans(group.title),
    })) ?? []),
  ];

  const tabsComponent = null; // <JournalTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />;

  return (
    <Section gap={5}>
      {view === 'normal' && (
        <>
          {tabsComponent}
          <FilterJournals
            getJournalsList={getJournalsList}
            selectedCategory={filterCategory}
            selectedCategoryGroup={activeTab}
            showEntriesForOthers={showEntriesForOthers}
          />
        </>
      )}
      {listJournalsComponent(activeTab)}
      {isJournalFormOpen && (
        <CreateJournalForm
          exerciseView={exerciseView}
          isOpen={isJournalFormOpen}
          onClose={handleCloseForm}
          selectedJournal={selectedJournal}
        />
      )}
    </Section>
  );
};
