// @ts-nocheck
/* eslint-disable */

import React, {ReactElement, ReactNode, useEffect, useState} from 'react';
import {ColumnDef} from '@tanstack/react-table';
import {Cell} from '@tanstack/table-core';
import {addCN} from '@/_core/util/cssHelper';
import {_TableFilteringOptions, TableFilter} from '@modules/Core/components/base/table/TableFilter';
import {Divider} from '../Divider';
import {ReactTable} from './ReactTable';

const thContent = 'flex items-center gap-1 w-full pr-3';

type ColumnAlign = 'left' | 'right' | 'center';

interface _TableCell {
  columnData: Record<string, string | React.ElementType>;
  cellData: Cell<TData, TValue>;
  align?: ColumnAlign;
}

type TData = any;
type TValue = any;

const TableCell: React.FC<_TableCell> = ({columnData, cellData, align}) => {
  const currentCellData = cellData?.getValue();
  if (!currentCellData) return null;
  const dataToColumnData = {
    label: currentCellData,
    key: columnData.key,
    disabled: columnData.disabled,
    width: columnData.width,
  };
  return currentCellData ? <TableColumn data={dataToColumnData} align={align} /> : null;
};

interface _TableColumn {
  data: Record<string, string | ReactElement>;
  isHeader?: boolean;
  showTopBorder?: boolean;
  align?: ColumnAlign;
}

// can be both - regular cell or header cell
const TableColumn: React.FC<_TableColumn> = ({data, isHeader = false, showTopBorder = false, align}) => {
  const isDisabled = data?.disabled;
  const {Icon} = data;

  const alignmentClass = align === 'right' ? 'justify-end' : align === 'center' ? 'justify-center' : 'justify-start';

  return (
    <div className={addCN('flex flex-col items-center gap-1 transition w-full')}>
      <div className={addCN(thContent, 'mt-2 mb-1 min-h-[40px]', alignmentClass)}>
        {Icon ? <Icon color={isDisabled && 'fill-white500'} /> : null}
        {data?.label ? (
          <span className={addCN(isHeader ? '' : 'w-full', isDisabled && 'text-typography-white')}>{data.label}</span>
        ) : null}
      </div>
    </div>
  );
};

const getColumns = (columns: _TableColumns, showTopBorder?: boolean): Array<ColumnDef<TData, TValue>> =>
  columns.map(column => ({
    accessorKey: column.key as string,
    header: () => (
      <TableColumn data={column} isHeader showTopBorder={showTopBorder} align={column.align as ColumnAlign} />
    ),
    cell: (cell: Cell<TData, TValue>) => (
      <TableCell cellData={cell} columnData={column} align={column.align as ColumnAlign} />
    ),
    enableSorting: column.enableSorting || false,
    sortingFn: column.sortingFn,
    width: column?.width,
  }));

interface _Table {
  data: _TableData;
  columns: _TableColumns;
  showTopBorder?: boolean;
  renderRow: (row: any) => _TableDataItem;
  filterOptions?: _TableFilteringOptions;
  header?: ReactNode;
  withHeaders?: boolean;
}

export type _TableData = Array<Record<string, string | number | object>>;
export type _TableDataItem = Record<string, string | number | object>;
export type _TableColumns = Array<Record<string, string | React.ElementType>>;

export const Table: React.FC<_Table> = ({
  data,
  columns,
  showTopBorder,
  renderRow,
  filterOptions,
  header,
  withHeaders = true,
}) => {
  const [processedData, setProcessedData] = useState<_TableData>([]);

  // Render row of each table and enrich it with the data
  useEffect(() => {
    const newData = data?.map(row => renderRow(row));

    setProcessedData(newData ?? []);
  }, [data]);

  const mapData = (data: any[]): _TableDataItem[] => data.map(row => renderRow(row));
  const onFilter = (filteredData: any[]): void => {
    setProcessedData(mapData(filteredData));
  };

  if (!processedData) return null;

  return (
    <>
      <TableFilter data={data} setFilteredData={onFilter} options={filterOptions} />
      {Boolean(header) && header}
      <ReactTable
        withHeaders={withHeaders}
        showTopBorder={showTopBorder}
        {...{data: processedData, columns: getColumns(columns, showTopBorder)}}
      />
    </>
  );
};
