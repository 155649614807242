// @ts-nocheck
/* eslint-disable */

import {
  SERVICE_CUSTOM_SURVEY,
  SERVICE_PROFILE_ANALYSIS,
  SERVICE_PROFILE_BASE_AND_ANALYSIS,
  SERVICE_PROFILE_COMMUNICATION,
  SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE,
  SERVICE_PROFILE_LEARNING,
  SERVICE_PROFILE_MINDFULNESS,
  SERVICE_PROFILE_MOTIVATION,
  SERVICE_PROFILE_MOTIVE_STRUCTURE,
  SERVICE_PROFILE_PROACTIVITY,
  SERVICE_PROFILE_RESILIENCE,
  SERVICE_PROFILE_RESPONSIBILITY,
  SERVICE_PROFILE_ROLES,
  SERVICE_PROFILE_SELF_EFFICACY,
  SERVICE_PROFILE_TRUST,
  SERVICE_PROFILE_VALUES,
  SERVICE_PROFILE_WAY_OF_WORKING,
  THIRD_PERSON_FEEDBACK,
} from '@modules/Core/config/services';

/**
 * Content engine types/templates
 */
export const CE_SERVICES_TEMPLATES: Record<string, string> = {
  [`${SERVICE_PROFILE_LEARNING}`]: 'profile/result/learning',
  [`${SERVICE_PROFILE_MINDFULNESS}`]: 'profile/result/mindfulness',
  [`${SERVICE_PROFILE_RESILIENCE}`]: 'profile/result/resilience',
  [`${SERVICE_PROFILE_SELF_EFFICACY}`]: 'profile/result/selfEfficacy',
  [`${SERVICE_PROFILE_PROACTIVITY}`]: 'profile/result/proactivity',
  [`${SERVICE_PROFILE_RESPONSIBILITY}`]: 'profile/result/responsibility',
  [`${SERVICE_PROFILE_TRUST}`]: 'profile/result/trust',
  [`${SERVICE_PROFILE_COMMUNICATION}`]: 'profile/result/communication',
  [`${SERVICE_PROFILE_WAY_OF_WORKING}`]: 'profile/result/wayOfWorking',
  [`${SERVICE_PROFILE_VALUES}`]: 'profile/result/values',
  [`${SERVICE_PROFILE_ROLES}`]: 'profile/result/roles',
  [`${SERVICE_PROFILE_MOTIVE_STRUCTURE}`]: 'profile/result/motiveStructure',
  [`${SERVICE_PROFILE_MOTIVATION}`]: 'profile/result/motivation',
  [`${SERVICE_PROFILE_BASE_AND_ANALYSIS}`]: 'profile/result/personality',
  [`${SERVICE_PROFILE_ANALYSIS}`]: 'profile/result/personality',
  [`${SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE}`]: 'profile/result/emotionalIntelligence',
  [`${SERVICE_CUSTOM_SURVEY}`]: 'profile/result/customSoftFact',
};
export const CE_SERVICES_SHARED_RESULTS_TEMPLATES: Record<string, string> = {
  [`${SERVICE_PROFILE_LEARNING}`]: 'profile/result/shared/learning',
  [`${SERVICE_PROFILE_MINDFULNESS}`]: 'profile/result/shared/mindfulness',
  [`${SERVICE_PROFILE_RESILIENCE}`]: 'profile/result/shared/resilience',
  [`${SERVICE_PROFILE_SELF_EFFICACY}`]: 'profile/result/shared/selfEfficacy',
  [`${SERVICE_PROFILE_PROACTIVITY}`]: 'profile/result/shared/proactivity',
  [`${SERVICE_PROFILE_RESPONSIBILITY}`]: 'profile/result/shared/responsibility',
  [`${SERVICE_PROFILE_TRUST}`]: 'profile/result/shared/trust',
  [`${SERVICE_PROFILE_COMMUNICATION}`]: 'profile/result/shared/communication',
  [`${SERVICE_PROFILE_WAY_OF_WORKING}`]: 'profile/result/shared/wayOfWorking',
  [`${SERVICE_PROFILE_VALUES}`]: 'profile/result/shared/values',
  [`${SERVICE_PROFILE_ROLES}`]: 'profile/result/shared/roles',
  [`${SERVICE_PROFILE_MOTIVE_STRUCTURE}`]: 'profile/result/shared/motiveStructure',
  [`${SERVICE_PROFILE_MOTIVATION}`]: 'profile/result/shared/motivation',
  [`${SERVICE_PROFILE_BASE_AND_ANALYSIS}`]: 'profile/result/shared/personality',
  [`${SERVICE_PROFILE_ANALYSIS}`]: 'profile/result/shared/personality',
  [`${SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE}`]: 'profile/result/shared/emotionalIntelligence',
  [`${THIRD_PERSON_FEEDBACK}`]: 'profile/result/shared/feedback360',
};

export const CE_TEMPLATE_SURVEY_XP = 'surveys/surveyXP';
export const CE_TEMPLATE_TEAM_DEV_SUBMODULE_XP = 'team/teamDevSubmoduleXP';
export const CE_TEMPLATE_XP = 'xp';
export const CE_TEMPLATE_ALERTS = 'alerts';
