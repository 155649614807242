// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProfileResult} from '@modules/Profile/components/services/result/ProfileResult';
import {useProfile} from '@modules/Profile/hooks/profile';
import {_SurveyParams} from '@modules/SurveyEngine/types/survey.model';

interface _ServiceResultProps {
  service: string;
  surveyParams: _SurveyParams;
  teamId: string;
  surveyDoneBefore: boolean;
  preview: boolean;
}
// @Deprecated
export const ServiceResult: React.FC<_ServiceResultProps> = ({
  service,
  surveyParams,
  teamId,
  surveyDoneBefore,
  preview,
}) => {
  const {currentProfile} = useProfile();
  // TODO @Sherif after implementing team
  // const [currentTeam] = useTeam();

  if (!service) {
    return null;
  }

  if (teamId) {
    return 'TODO TEAM';
    // return (
    //   <TeamResult team={currentTeam} service={service} surveyParams={surveyParams} preview={preview}></TeamResult>
    // );
  }

  return <ProfileResult profile={currentProfile} service={service} surveyDoneBefore={surveyDoneBefore} />;
};
