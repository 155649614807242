// @ts-nocheck
/* eslint-disable */

import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Divider} from '@modules/Core/components/base/Divider';
import {IconProfile} from '@modules/Core/components/base/Icons/Icons';
import {Tabs} from '@modules/Core/components/base/Tabs';
import {Section} from '@modules/Core/components/layout/Section';
import {getStatisticImage} from '@modules/Core/util/imagesUtil';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {About} from '@modules/Profile/components/info/About';
import {Photo} from '@modules/Profile/components/info/Photo';
import {ProfileDescription} from '@modules/Profile/components/info/ProfileDescription';
import {_Gender, _Profile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';
import {BaseStep} from '../Base/BaseStep';

export const ProfileInfoStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const tabs = [
    {
      title: 'base.basic',
      key: 'basic',
      iconElement: <IconProfile />,
    },
    {
      title: 'base.gamification',
      key: 'gamification',
      iconElement: <img className="w-2" src={getStatisticImage('experiencelogo')} />,
    },
  ];
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);
  const [gender, setGender] = useState<_Gender | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [photo, setPhoto] = useState<string | null>(null);
  const [position, setPosition] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'basic' | 'gamification'>('basic');
  const originalDataLoaded = useRef<boolean>(false);
  const edited = useRef<boolean>(false);
  const memoizedContentEngine = useRef<ReactNode>(null);

  const loadedInitialTab = useRef<boolean>(false);

  useEffect(() => {
    if (!!options?.initialTab && !loadedInitialTab.current) {
      setActiveTab(options.initialTab);
      loadedInitialTab.current = true;
    }
  }, [options]);
  useEffect(() => {
    memoizedContentEngine.current = <ContentEngineContainer type="profile/gamification" />;
  }, []);
  useEffect(() => {
    if (originalDataLoaded.current || !options.profile) {
      return;
    }

    const profile = options.profile as _Profile;
    const date = profile.day_of_birth ? new Date(profile.day_of_birth).toISOString().split('T')[0] : null;
    setFirstName(profile.first_name);
    setLastName(profile.last_name);
    setDateOfBirth(date);
    setGender(profile.gender);
    setPhone(profile.phone);
    setDescription(profile.description);
    setPosition(profile.position);
    setPhoto(getProfilePic(profile));
    originalDataLoaded.current = true;
  }, [options]);

  function getValues(): Record<string, any> {
    return {
      firstName,
      lastName,
      dateOfBirth,
      gender,
      phone,
      description,
      position,
    };
  }

  const onEvent = (actionName: string): void => {
    options.fireEvent(actionName);
  };

  const onUpdateProfileAbout = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onEvent('updateProfileAbout');
  };

  return (
    <BaseStep
      getValueCallbackFn={getValues}
      inputs={[firstName, lastName, dateOfBirth, gender, phone, description, position]}
      options={options}
    >
      <Tabs
        tabs={tabs.map(tab => ({
          key: tab.key,
          displayValue: trans(tab.title),
          iconElement: tab.iconElement,
        }))}
        activeTab={activeTab}
        setActiveTab={(group: 'basic' | 'gamification') => setActiveTab(group)}
      />
      <form onSubmit={onUpdateProfileAbout}>
        {activeTab === 'basic' && (
          <Section>
            <Photo photo={photo} setPhoto={setPhoto} />
            <About
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              phone={phone}
              setPhone={setPhone}
              gender={gender}
              setGender={setGender}
              position={position}
              setPosition={setPosition}
              onChanges={() => {
                edited.current = true;
              }}
            />
            <ProfileDescription
              onChanges={() => {
                edited.current = true;
              }}
              description={description}
              setDescription={setDescription}
            />

            <div className="flex flex-row justify-end w-full items-center">
              <div className="relative-col-width-2">
                <Button disabled={!edited.current} label={trans('base.save')} type="submit" />
              </div>
            </div>
          </Section>
        )}
        <div className={activeTab !== 'gamification' ? 'hidden' : ''}>
          <ContentEngineContainer type="profile/gamification" />
        </div>
      </form>
    </BaseStep>
  );
};
