// @ts-nocheck
/* eslint-disable */

import React from 'react';
import SendEmail from './SendEmail';
import './SendReminder.scss';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {Typography} from '@modules/Core/components/base/Typography';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {useProfile} from '@modules/Profile/hooks/profile';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface SendReminderProps {
  teamId?: string;
  notificationType: string;
  finishedCount?: number;
  totalCount?: number;
  recipients?: string[];
  subject?: string;
  body?: string;
  actionLink?: string;
  actionText?: string;
}

const SendReminder: React.FC<SendReminderProps> = ({
  teamId,
  notificationType,
  finishedCount,
  totalCount,
  recipients,
  subject,
  body,
  actionLink,
  actionText,
}) => {
  const canSendReminder =
    notificationType === 'TPF'
      ? true // Always allow reminders for third person feedback
      : can('team.membership:remind', {team_id: teamId});

  const showIcon = notificationType !== 'TPF';

  const {currentProfile} = useProfile();

  return (
    <Typography>
      <div className="send-reminder p-1 gap-1 flex flex-row">
        {canSendReminder && (finishedCount === undefined || totalCount === undefined || finishedCount < totalCount) ? (
          <SendEmail
            label={trans('base.send_reminder')}
            showIcon={showIcon}
            emailContent={{
              subject: subject || trans('base.friendly_reminder'),
              body:
                body ||
                trans('emails.reminder_body', {
                  yourName: currentProfile?.first_name,
                }),
              recipients,
              actionLink,
              actionText,
            }}
          />
        ) : null}

        {finishedCount !== undefined && totalCount !== undefined && (
          <span
            className={`${finishedCount === totalCount ? 'finished-counter' : 'pending-counter'} flex flex-row gap-1 items-center`}
          >
            <Avatar size="sm" image={getProfilePic(null)} />
            {`${finishedCount}/${totalCount}`}
          </span>
        )}
      </div>
    </Typography>
  );
};

export default SendReminder;
