// @ts-nocheck
/* eslint-disable */

import React, {useState, useEffect} from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {IconSend} from '@modules/Core/components/base/Icons/Icons';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {formatDateTime} from '@modules/Core/util/dates';
import {ReactEventJournalUpdated, dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {ADD_JOURNAL_COMMENT_XP_DUMMY_KEY} from '@modules/Gamification/config/constants';
import {useProfile} from '@modules/Profile/hooks/profile';
import {_Journal, _JournalComment} from '@modules/Profile/types/journal.model';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {addJournalComment, processJournalHtml} from '@modules/Profile/util/journalUtil';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import ExperienceKeyCount from '@modules/Statistics/components/general/ExperienceKeyCount';
import {trans} from '@modules/Translations/util/i18n';

interface _JournalCommentDialogProps {
  journal: _Journal;
  isOpen: boolean;
  onClose: () => void;
}

export const JournalCommentDialog: React.FC<_JournalCommentDialogProps> = ({journal, isOpen, onClose}) => {
  const {currentProfile, originalProfile} = useProfile();
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState<_JournalComment[]>(journal.comments || []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const snackbar = useSnackbar();

  // Check if user is impersonating
  const isImpersonating = !!originalProfile;

  // Update comments when journal changes
  useEffect(() => {
    setComments(journal.comments || []);
  }, [journal]);

  const handleSubmit = async () => {
    if (!newComment.trim() || isSubmitting) return;

    setIsSubmitting(true);
    const success = await addJournalComment(journal.id, newComment);

    if (success) {
      // Optimistically add the comment to the UI
      const newCommentObj: _JournalComment = {
        id: `temp-${Date.now()}`, // Temporary ID until refresh
        content: newComment,
        created_at: new Date().toISOString(),
        author: {
          id: originalProfile?.id || '',
          fullName: originalProfile?.fullName || '',
          profilePicture: originalProfile?.profilePicture,
        },
      };

      setComments(prevComments => [...prevComments, newCommentObj]);
      setNewComment('');
      snackbar.success(trans('journal.comment_added'));

      // Trigger a refresh of journals
      dispatchReactEvent(ReactEventJournalUpdated);
    } else {
      snackbar.warning(trans('journal.comment_error'));
    }

    setIsSubmitting(false);
  };

  if (!currentProfile) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      title={journal.title || trans('journal.entry')}
      titleSuffix={<ExperienceKeyCount xpKey={ADD_JOURNAL_COMMENT_XP_DUMMY_KEY} />}
      showCloseButton
      size="lg"
    >
      <Section gap={4}>
        {/* Journal Content */}
        <div className="flex flex-col gap-2 p-4 bg-grey50 rounded-sm max-h-[200px] overflow-y-auto">
          <Typography dangerouslySetInnerHTML={{__html: processJournalHtml(journal.journal)}} />
          <Typography variant="caption" className="text-grey500">
            {formatDateTime(new Date(journal.date))}
          </Typography>
        </div>

        {/* Comments Section */}
        <div className="flex flex-col gap-2">
          <Typography variant="h6">{trans('journal.comments')}</Typography>

          {/* Existing Comments */}
          {comments.length > 0 ? (
            comments.map((comment: _JournalComment) => (
              <div key={comment.id} className="flex gap-3 p-3 border-1 border-grey100 rounded-sm">
                <div className="flex flex-col gap-1">
                  <AvatarUser
                    size="sm"
                    label={comment.author.fullName}
                    image={getProfilePic({
                      id: comment.author.id,
                      fullName: comment.author.fullName,
                      profilePicture: comment.author.profilePicture,
                    } as _ShortProfile)}
                  />
                  <Typography variant="caption" className="text-grey500">
                    {formatDateTime(new Date(comment.created_at))}
                  </Typography>
                </div>
                <div className="flex flex-col flex-grow">
                  <Typography>{comment.content}</Typography>
                </div>
              </div>
            ))
          ) : (
            <Typography className="text-grey500">{trans('journal.no_comments')}</Typography>
          )}

          {/* New Comment Input */}
          <div className="flex gap-2 mt-2 pl-1 items-end border-t border-grey100 items-center">
            <div className="flex flex-col gap-1">
              <AvatarUser size="sm" label={originalProfile?.fullName} image={getProfilePic(originalProfile)} />
              <Typography variant="caption" className="text-grey500">
                {formatDateTime(new Date())}
              </Typography>
            </div>
            <div className="flex-grow">
              <TextArea
                rows={2}
                value={newComment}
                onChange={e => setNewComment(e.target.value)}
                placeholder={trans('journal.write_comment')}
              />
            </div>
            <IconButton
              icon={IconSend}
              onClick={handleSubmit}
              disabled={!newComment.trim() || isSubmitting}
              size="lg"
            />
          </div>
        </div>
      </Section>
    </Dialog>
  );
};
