// @ts-nocheck
/* eslint-disable */

import React, {JSX, useEffect, useRef, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconCheck} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {getStatisticImage} from '@modules/Core/util/imagesUtil';
import {useUiStore} from '@modules/Core/util/logic/zustand';
import {isImpersonating} from '@modules/Core/util/rolesAndPermissionsUtil';
import {copyObject} from '@modules/Core/util/util';
import {_FlowActionResult} from '@modules/FlowEngine/types/flow-action.model';
import {ExerciseImage} from '@modules/Profile/components/exercises/common/ExerciseImage';
import {ChecklistTask} from '@modules/Profile/components/exercises/tasks/checklist/ChecklistTask';
import {
  _ExerciseData,
  _ProfileExercise,
  _ProfileExerciseStatus,
  _ProfileExerciseTask,
  _TaskData,
  EXERCISE_STATUS_DONE,
  EXERCISE_STATUS_OPEN,
  EXERCISE_STATUS_STARTED,
} from '@modules/Profile/types/exercises.model';
import {useLanguage} from '@modules/Translations/hooks/language';
import './ProfileExercise.scss';
import {RichTextTask} from '@modules/Profile/components/exercises/tasks/richtext/RichTextTask';
import {JournalContent} from '@modules/Profile/components/journals/JournalContent';
import {statusLangProps} from '@modules/Profile/config/exercisesConstants';
import {_Journal} from '@modules/Profile/types/journal.model';
import {
  finishExerciseAction,
  restartExerciseAction,
  submitExerciseAction,
} from '@modules/Profile/util/exercisesActionsUtil';
import {extractProfileTask} from '@modules/Profile/util/exercisesUtil';
import {getJournalsList} from '@modules/Profile/util/journalUtil';
import {trans} from '@modules/Translations/util/i18n';
import {Divider} from '@modules/Core/components/base/Divider';
import {ProfileFeedbackItem} from '@modules/ContentEngine/components/items/profile/common/ProfileFeedbackItem';
import ExperienceCount from '@modules/Statistics/components/general/ExperienceCount';

interface _ExerciseAnswer {
  date: string;
  tasks: _ProfileExerciseTask[];
}

interface _ProfileExerciseProps {
  onExerciseFinished: (result: _FlowActionResult, exerciseIndex: number) => Promise<void>;
  onExerciseStartClick: (exerciseIndex: number) => Promise<void>;
  currentProfileExercises: _ProfileExercise[] | null;
  setCurrentProfileExercises: (exercises: _ProfileExercise[]) => void;
  service: string;
  round: number;
  exerciseIndex: number;
  exercise: _ExerciseData;
  mode: 'view' | 'edit';
  setMode: (mode: 'view' | 'edit') => void;
  latestJournalUpdate?: string;

  isDevMode: boolean;
}

const numToString = (num: number): string => {
  if (num === 1) {
    return trans('base.first');
  }
  if (num === 2) {
    return trans('base.second');
  }
  if (num === 3) {
    return trans('base.third');
  }
  return trans('base.nth', {n: num});
};

export const ProfileRoundExercise: React.FC<_ProfileExerciseProps> = ({
  onExerciseStartClick,
  onExerciseFinished,
  currentProfileExercises,
  setCurrentProfileExercises,
  service,
  round,
  exerciseIndex,
  exercise,
  mode,
  setMode,
  latestJournalUpdate,
  isDevMode,
}) => {
  const {currentLanguage} = useLanguage();
  const snackbar = useSnackbar();

  // Component State
  const [changed, setChanged] = useState<boolean>(false);
  const isLoading = useRef<boolean>(false);
  const [available, setAvailable] = useState<boolean>(false);

  const [previousAnswers, setPreviousAnswers] = useState<_ExerciseAnswer[]>([]);
  const [journals, setJournals] = useState<_Journal[]>([]);

  useEffect(() => {
    void fetchJournals(service, exerciseIndex, round);
  }, [service, exerciseIndex, round, latestJournalUpdate]);

  useEffect(() => {
    if (!currentProfileExercises?.[exerciseIndex]?.previous_answers) {
      setPreviousAnswers([]);
      setAvailable(!!currentProfileExercises?.[exerciseIndex]?.available);
      return;
    }
    const newPreviousAnswers: _ExerciseAnswer[] = [];
    // key -> date, value->data
    for (const [date, answer] of Object.entries(currentProfileExercises?.[exerciseIndex]?.previous_answers)) {
      // if is object
      if (answer?.constructor === Object && answer?.tasks) {
        newPreviousAnswers.push({
          date,
          tasks: answer.tasks,
        });
      }
    }

    // sort by date
    newPreviousAnswers.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    setPreviousAnswers(newPreviousAnswers);
    setAvailable(currentProfileExercises?.[exerciseIndex]?.available);
  }, [currentProfileExercises, exerciseIndex]);

  const fetchJournals = async (
    service?: string,
    exerciseIndex?: number | null,
    roundIndex?: number | null
  ): Promise<void> => {
    if (!service || exerciseIndex === undefined || roundIndex === undefined) {
      return;
    }
    const newJournals = (await getJournalsList({
      category: service,
      metadata: {
        exerciseIndex,
        roundIndex,
      },
    })) as _Journal[];
    setJournals(newJournals || []);
  };

  const updateExerciseEntry = (exerciseIndex: number, newExercise: _ProfileExercise, update = true): void => {
    const newProfileExercises = copyObject(currentProfileExercises);
    if (!newProfileExercises) {
      return;
    }
    newProfileExercises[exerciseIndex] = newExercise;
    setCurrentProfileExercises(newProfileExercises);

    void (update && reloadContentEngine());
  };

  const onConfirm = async (isSave = false): Promise<void> => {
    if (isLoading.current || !currentProfileExercises) {
      return;
    }
    isLoading.current = true;
    const newExercise = await submitExerciseAction(
      service,
      round,
      exerciseIndex,
      currentProfileExercises[exerciseIndex]?.data?.tasks
    );

    isLoading.current = false;
    if (!newExercise) {
      return;
    }
    updateExerciseEntry(exerciseIndex, newExercise);
    if (isSave) {
      snackbar.success(trans('profile_exercises.saved_successfully'));
    }
    setMode('view');
    setChanged(false);
  };

  const onDataChange = async (taskIndex: number, result: Record<string, any> | string | string[]): Promise<void> => {
    // update current profile exercises task with NO API CALLS
    const newExercise = copyObject(currentProfileExercises);

    // Find current exercise.tasks.taskIndex
    const changedTask = newExercise?.[exerciseIndex]?.data?.tasks?.[taskIndex];
    if (!changedTask) {
      return;
    }
    changedTask.data = result;

    setChanged(true);
    updateExerciseEntry(exerciseIndex, newExercise[exerciseIndex], false);
  };

  const onExerciseFinish = async (exerciseIndex: number): Promise<void> => {
    if (changed) {
      await onConfirm();
    }

    if (isLoading.current) {
      return;
    }
    isLoading.current = true;

    const newExerciseResult = await finishExerciseAction(service, round, exerciseIndex);

    isLoading.current = false;
    if (!newExerciseResult) {
      return;
    }

    updateExerciseEntry(exerciseIndex, newExerciseResult.data as _ProfileExercise);
    void onExerciseFinished(newExerciseResult, exerciseIndex);
  };

  const onExerciseRestart = async (exerciseIndex: number): Promise<void> => {
    if (isLoading.current) {
      return;
    }
    isLoading.current = true;
    const newExercise = await restartExerciseAction(service, round, exerciseIndex);

    isLoading.current = false;
    if (!newExercise) {
      return;
    }
    updateExerciseEntry(exerciseIndex, newExercise);
    setMode('edit');
  };

  const shouldShowExerciseTasks = (exercise?: _ProfileExercise): boolean =>
    [EXERCISE_STATUS_STARTED, EXERCISE_STATUS_DONE].includes(exercise?.status ?? '');

  const getImageSrc = (image: any): string | null => {
    if (!image) {
      return null;
    }

    // Image can be string or dict, if dict => language specific images

    let imagePath = null;
    // Is object
    if (image?.constructor === Object) {
      imagePath = image[currentLanguage];
    } else {
      imagePath = image;
    }

    return `/assets/img/design_images/exercises/${imagePath}`;
  };

  const getPreviousTaskComponent = (answer: _ProfileExerciseTask, task: _TaskData, isLast: boolean): JSX.Element => {
    switch (task?.type) {
      case 'text':
        return (
          <RichTextTask
            showCorrectAnswer={isLast}
            exerciseStatus={EXERCISE_STATUS_DONE}
            mode="view"
            profileAnswer={answer}
            task={task}
          />
        );
      case 'checklist':
        return (
          <ChecklistTask
            showCorrectAnswer={isLast}
            exerciseStatus={EXERCISE_STATUS_DONE}
            mode="view"
            profileAnswer={answer}
            task={task}
          />
        );
      default:
        break;
    }
  };

  return (
    <Section gap={5}>
      <Section
        title={trans(exercise?.intro?.title)}
        titlePrefix={
          <div className="flex flex-row items-center gap-3">
            <ExperienceCount experience={exercise.totalExperience} />
            <StatusBadge status={currentProfileExercises?.[exerciseIndex]?.status} />
          </div>
        }
      >
        <Typography variant="body1">{trans(exercise?.intro?.goal)}</Typography>
        {exercise?.intro?.image && (
          <ExerciseImage
            src={getImageSrc(exercise?.intro?.image)}
            fullScreenClick={exercise?.intro?.allowFullScreen}
            style={{
              width: 'fit-content',
              maxWidth: '100%',
            }}
          />
        )}
        <Typography variant="body">
          <div
            dangerouslySetInnerHTML={{
              __html: trans(exercise?.intro?.description),
            }}
          />
        </Typography>
        {shouldShowExerciseTasks(currentProfileExercises?.[exerciseIndex]) && (
          <>
            <Typography variant="body1">{trans('profile_exercises.answer')}</Typography>
            {(exercise?.tasks || []).map((task, taskIndex) => {
              if (!task) {
                return null;
              }
              const showPrevious = previousAnswers?.length && mode === 'view';
              let content: JSX.Element | null = null;

              if (!showPrevious || currentProfileExercises?.[exerciseIndex]?.status !== EXERCISE_STATUS_DONE) {
                switch (task?.type) {
                  case 'text':
                    content = (
                      <RichTextTask
                        mode={mode}
                        exerciseStatus={currentProfileExercises?.[exerciseIndex]?.status}
                        profileAnswer={extractProfileTask(currentProfileExercises?.[exerciseIndex], taskIndex)}
                        onChange={result => {
                          void onDataChange(taskIndex, result);
                        }}
                        task={task}
                      />
                    );
                    break;
                  case 'checklist':
                    content = (
                      <ChecklistTask
                        mode={mode}
                        exerciseStatus={currentProfileExercises?.[exerciseIndex]?.status}
                        onChange={result => {
                          void onDataChange(taskIndex, result);
                        }}
                        profileAnswer={extractProfileTask(currentProfileExercises?.[exerciseIndex], taskIndex)}
                        task={task}
                      />
                    );
                    break;
                  default:
                    break;
                }
                content = showPrevious ? (
                  <div className="flex flex-col gap-2">
                    <Typography variant="body1">{trans('profile_exercises.latest_answer')}</Typography>
                    <Typography variant="body">
                      <div className="flex flex-col gap-2">{content}</div>
                    </Typography>
                  </div>
                ) : (
                  content
                );
              }

              if (!task) {
                return null;
              }

              if (previousAnswers?.length > 0 && mode === 'view') {
                // Above code with better adhering to eslint rules
                previousAnswers.forEach((previousAnswer, index) => {
                  const taskAnswer = previousAnswer?.tasks?.find(
                    (task: _ProfileExerciseTask) => task.position === taskIndex
                  );
                  if (taskAnswer) {
                    // Sorted by date, so last item is first
                    const displayIndex = previousAnswers.length - index;
                    const isLast = index === 0;
                    content = (
                      <>
                        <div className="flex flex-col gap-2">
                          <Typography variant="body">
                            <div className="flex flex-row gap-3">
                              {previousAnswers.length > 1 && (
                                <div className="flex relative-col-width-1-5">
                                  {trans('profile_exercises.answer_index', {
                                    index: numToString(displayIndex),
                                  })}
                                </div>
                              )}
                              <div className="flex flex-col flex-grow gap-2">
                                <div className="flex">{getPreviousTaskComponent(taskAnswer, task)}</div>
                                {/*! isLast && <Divider light fill={false} width={16} /> */}
                              </div>
                            </div>
                          </Typography>
                          {isLast && <div className="flex">{getPreviousTaskComponent(null, task, isLast)}</div>}
                        </div>
                        {content}
                      </>
                    );
                  }
                });
              }

              return (
                <div className="flex flex-col gap-2" key={task.title}>
                  {task.title && (
                    <Typography variant="body" bold dangerouslySetInnerHTML={{__html: trans(task.title)}} />
                  )}
                  {content}
                </div>
              );
            })}
          </>
        )}
        {Boolean(!isDevMode && available && !isImpersonating()) && (
          <div className="flex flex-row justify-end gap-3">
            {/* Started but not done, show only save if changes exist */}

            {/* Done, show edit and save buttons */}

            {Boolean(
              exercise?.tasks?.length > 0 &&
                currentProfileExercises?.[exerciseIndex]?.status === EXERCISE_STATUS_STARTED
            ) &&
              (mode === 'view' ? (
                <div className="relative-col-width-2">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setMode('edit');
                    }}
                  >
                    {trans('profile_exercises.edit')}
                  </Button>
                </div>
              ) : (
                <div className="relative-col-width-2">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      void onConfirm(true);
                    }}
                  >
                    {trans('profile_exercises.save')}
                  </Button>
                </div>
              ))}

            {/* Started, show edit and save buttons */}

            {currentProfileExercises?.[exerciseIndex]?.status === EXERCISE_STATUS_STARTED && (
              <div className="relative-col-width-2">
                <Button
                  onClick={() => {
                    void onExerciseFinish(exerciseIndex);
                  }}
                >
                  {trans('profile_exercises.complete_exercise')}
                </Button>
              </div>
            )}

            {currentProfileExercises?.[exerciseIndex]?.status === EXERCISE_STATUS_DONE && mode === 'view' && (
              <div className="relative-col-width-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    void onExerciseRestart(exerciseIndex);
                  }}
                >
                  {trans('profile_exercises.restart_exercise')}
                </Button>
              </div>
            )}

            {currentProfileExercises?.[exerciseIndex]?.status === EXERCISE_STATUS_OPEN && (
              <div className="relative-col-width-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    void onExerciseStartClick(exerciseIndex);
                  }}
                >
                  {trans('profile_exercises.start_exercise')}
                </Button>
              </div>
            )}
          </div>
        )}
      </Section>

      {journals?.length > 0 && (
        <>
          <Divider />
          <Section title={trans('profile_exercises.your_notes')} titleVariant="h6">
            <JournalContent data={journals} />
          </Section>
        </>
      )}

      <MissingExercisesComponent service={service} />
    </Section>
  );
};

export const StatusBadge: React.FC<{status?: _ProfileExerciseStatus}> = ({status}) => {
  if (!status) {
    return null;
  }
  const label = statusLangProps[status];
  let background;

  if (status === EXERCISE_STATUS_OPEN) {
    background = '#C1CCDA';
  }

  if (status === EXERCISE_STATUS_STARTED) {
    background = '#132E3E';
  }

  if (status === EXERCISE_STATUS_DONE) {
    return (
      <div className="exercise-status-badge-circle">
        <IconCheck color="fill-green700" size="sm" />
      </div>
    );
  }

  return (
    <div className="exercise-status-badge" style={{background}}>
      <Typography variant="caption" className="text-white">
        {trans(label)}
      </Typography>
    </div>
  );
};

export const MissingExercisesComponent = ({service}: {service: string}) => {
  const showDevMenu = useUiStore(state => state.showDevMenu);
  const exercise = [
    'profile_exercises.profile_base_and_analysis.0.0',
    'profile_exercises.profile_base_and_analysis.0.1',
    'profile_exercises.profile_base_and_analysis.0.2',
    'profile_exercises.profile_base_and_analysis.1.0',
    'profile_exercises.profile_base_and_analysis.1.1',
    'profile_exercises.profile_base_and_analysis.1.2',
    'profile_exercises.profile_base_and_analysis.2.0',
    'profile_exercises.profile_base_and_analysis.2.1',
    'profile_exercises.profile_base_and_analysis.2.2',
    'profile_exercises.profile_communication.0.0',
    'profile_exercises.profile_communication.0.1',
    'profile_exercises.profile_communication.0.2',
    'profile_exercises.profile_communication.1.0',
    'profile_exercises.profile_communication.1.1',
    'profile_exercises.profile_communication.1.2',
    'profile_exercises.profile_communication.2.0',
    'profile_exercises.profile_communication.2.1',
    'profile_exercises.profile_communication.2.2',
    'profile_exercises.profile_emotional_intelligence.0.0',
    'profile_exercises.profile_emotional_intelligence.0.1',
    'profile_exercises.profile_emotional_intelligence.0.2',
    'profile_exercises.profile_emotional_intelligence.1.0',
    'profile_exercises.profile_emotional_intelligence.1.1',
    'profile_exercises.profile_emotional_intelligence.1.2',
    'profile_exercises.profile_emotional_intelligence.2.0',
    'profile_exercises.profile_emotional_intelligence.2.1',
    'profile_exercises.profile_emotional_intelligence.2.2',
    'profile_exercises.profile_learning.0.0',
    'profile_exercises.profile_learning.0.1',
    'profile_exercises.profile_learning.0.2',
    'profile_exercises.profile_learning.1.0',
    'profile_exercises.profile_learning.1.1',
    'profile_exercises.profile_learning.1.2',
    'profile_exercises.profile_learning.2.0',
    'profile_exercises.profile_learning.2.1',
    'profile_exercises.profile_learning.2.2',
    'profile_exercises.profile_mindfulness.0.0',
    'profile_exercises.profile_mindfulness.0.1',
    'profile_exercises.profile_mindfulness.0.2',
    'profile_exercises.profile_mindfulness.1.0',
    'profile_exercises.profile_mindfulness.1.1',
    'profile_exercises.profile_mindfulness.1.2',
    'profile_exercises.profile_mindfulness.2.0',
    'profile_exercises.profile_mindfulness.2.1',
    'profile_exercises.profile_mindfulness.2.2',
    'profile_exercises.profile_motivation.0.0',
    'profile_exercises.profile_motivation.0.1',
    'profile_exercises.profile_motivation.0.2',
    'profile_exercises.profile_motivation.1.0',
    'profile_exercises.profile_motivation.1.1',
    'profile_exercises.profile_motivation.1.2',
    'profile_exercises.profile_motivation.2.0',
    'profile_exercises.profile_motivation.2.1',
    'profile_exercises.profile_motivation.2.2',
    'profile_exercises.profile_proactivity.0.0',
    'profile_exercises.profile_proactivity.0.1',
    'profile_exercises.profile_proactivity.0.2',
    'profile_exercises.profile_proactivity.1.0',
    'profile_exercises.profile_proactivity.1.1',
    'profile_exercises.profile_proactivity.1.2',
    'profile_exercises.profile_proactivity.2.0',
    'profile_exercises.profile_proactivity.2.1',
    'profile_exercises.profile_proactivity.2.2',
    'profile_exercises.profile_resilience.0.0',
    'profile_exercises.profile_resilience.0.1',
    'profile_exercises.profile_resilience.0.2',
    'profile_exercises.profile_resilience.1.0',
    'profile_exercises.profile_resilience.1.1',
    'profile_exercises.profile_resilience.1.2',
    'profile_exercises.profile_resilience.2.0',
    'profile_exercises.profile_resilience.2.1',
    'profile_exercises.profile_resilience.2.2',
    'profile_exercises.profile_responsibility.0.0',
    'profile_exercises.profile_responsibility.0.1',
    'profile_exercises.profile_responsibility.0.2',
    'profile_exercises.profile_responsibility.1.0',
    'profile_exercises.profile_responsibility.1.1',
    'profile_exercises.profile_responsibility.1.2',
    'profile_exercises.profile_responsibility.2.0',
    'profile_exercises.profile_responsibility.2.1',
    'profile_exercises.profile_responsibility.2.2',
    'profile_exercises.profile_self_efficacy.0.0',
    'profile_exercises.profile_self_efficacy.0.1',
    'profile_exercises.profile_self_efficacy.0.2',
    'profile_exercises.profile_self_efficacy.1.0',
    'profile_exercises.profile_self_efficacy.1.1',
    'profile_exercises.profile_self_efficacy.1.2',
    'profile_exercises.profile_self_efficacy.2.0',
    'profile_exercises.profile_self_efficacy.2.1',
    'profile_exercises.profile_self_efficacy.2.2',
    'profile_exercises.profile_trust.0.0',
    'profile_exercises.profile_trust.0.1',
    'profile_exercises.profile_trust.0.2',
    'profile_exercises.profile_trust.1.0',
    'profile_exercises.profile_trust.1.1',
    'profile_exercises.profile_trust.1.2',
    'profile_exercises.profile_trust.2.0',
    'profile_exercises.profile_trust.2.1',
    'profile_exercises.profile_trust.2.2',
    'profile_exercises.profile_values.0.0',
    'profile_exercises.profile_values.0.1',
    'profile_exercises.profile_values.0.2',
    'profile_exercises.profile_values.1.0',
    'profile_exercises.profile_values.1.1',
    'profile_exercises.profile_values.1.2',
    'profile_exercises.profile_values.2.0',
    'profile_exercises.profile_values.2.1',
    'profile_exercises.profile_values.2.2',
    'profile_exercises.profile_way_of_working.0.0',
    'profile_exercises.profile_way_of_working.0.1',
    'profile_exercises.profile_way_of_working.0.2',
    'profile_exercises.profile_way_of_working.1.0',
    'profile_exercises.profile_way_of_working.1.1',
    'profile_exercises.profile_way_of_working.1.2',
    'profile_exercises.profile_way_of_working.2.0',
    'profile_exercises.profile_way_of_working.2.1',
    'profile_exercises.profile_way_of_working.2.2',
    'profile_exercises.motive_structure.acceptance.0',
    'profile_exercises.motive_structure.acceptance.1',
    'profile_exercises.motive_structure.belonging.0',
    'profile_exercises.motive_structure.belonging.1',
    'profile_exercises.motive_structure.career.0',
    'profile_exercises.motive_structure.career.1',
    'profile_exercises.motive_structure.development.0',
    'profile_exercises.motive_structure.development.1',
    'profile_exercises.motive_structure.fairness.0',
    'profile_exercises.motive_structure.fairness.1',
    'profile_exercises.motive_structure.fun.0',
    'profile_exercises.motive_structure.fun.1',
    'profile_exercises.motive_structure.idealism.0',
    'profile_exercises.motive_structure.idealism.1',
    'profile_exercises.motive_structure.independence.0',
    'profile_exercises.motive_structure.independence.1',
    'profile_exercises.motive_structure.performance.0',
    'profile_exercises.motive_structure.performance.1',
    'profile_exercises.motive_structure.power.0',
    'profile_exercises.motive_structure.power.1',
    'profile_exercises.motive_structure.responsibility.0',
    'profile_exercises.motive_structure.responsibility.1',
    'profile_exercises.motive_structure.security.0',
    'profile_exercises.motive_structure.security.1',
    'profile_exercises.motive_structure.self-optimization.0',
    'profile_exercises.motive_structure.self-optimization.1',
    'profile_exercises.motive_structure.self-realization.0',
    'profile_exercises.motive_structure.self-realization.1',
    'profile_exercises.motive_structure.status.0',
    'profile_exercises.motive_structure.status.1',
    'profile_exercises.motive_structure.target_identification.0',
    'profile_exercises.motive_structure.target_identification.1',
  ];
  return (
    <Section gap={2}>
      {/* <Typography variant="body1">{trans('profile_exercises.missing_exercises_title')}</Typography> */}
      {showDevMenu && (
        <>
          <Divider />
          <h1>All Exercises (Dev Only)</h1>
          {exercise.map((prefix, index) => (
            <Section
              key={index}
              title={`${prefix
                .split('.')
                .filter((s, i) => i >= 1 && i <= 3)
                .join('_')
                .replace('profile_', '')}: ${trans(`${prefix}.title`)}`}
              description={trans(`${prefix}.goal`)}
              descriptionVariant="body1"
            >
              <Typography dangerouslySetInnerHTML={{__html: trans(`${prefix}.description`)}} />
            </Section>
          ))}
        </>
      )}
    </Section>
  );
};
