// @ts-nocheck
/* eslint-disable */

import React from 'react';

type StyleFunction<P> = (props: P) => Record<string, any>;
type StyleObject = Record<string, any>;
type StyleInput<P> = StyleFunction<P> | StyleObject;

/**
 * A styled helper that provides similar functionality to MUI's styled API
 * @param Component The base component to style
 * @returns A function that takes a style object or function and returns a styled component
 */
export const styled = <C extends React.ComponentType<any>>(Component: C) => {
  return <P extends React.ComponentProps<C>>(styles: StyleInput<P>) => {
    return React.forwardRef<C, P>((props, ref) => {
      const className = React.useMemo(() => {
        const styleObj = typeof styles === 'function' ? styles(props) : styles;
        const cssString = Object.entries(styleObj)
          .map(([key, value]) => {
            if (value && typeof value === 'object') {
              const nestedStyles = Object.entries(value as Record<string, unknown>)
                .map(([k, v]) => `${k}: ${v};`)
                .join(' ');
              return `${key} { ${nestedStyles} }`;
            }
            return `${key.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`)}: ${value};`;
          })
          .join(' ');

        const styleElement = document.createElement('style');
        const uniqueClassName = `c${Math.random().toString(36).slice(2)}`;
        styleElement.textContent = `.${uniqueClassName} { ${cssString} }`;
        document.head.appendChild(styleElement);
        return uniqueClassName;
      }, [props]);

      const combinedProps = {
        ...props,
        className: props.className ? `${props.className} ${className}` : className,
        ref,
      };

      return React.createElement(Component, combinedProps);
    });
  };
};

export default styled;
