// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {useClickOutOfBoundsHelper} from '@/_core/util/cssHelper';
import {Divider} from '@modules/Core/components/base/Divider';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Section} from '@modules/Core/components/layout/Section';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {useUiStore, zustandApi} from '@modules/Core/util/logic/zustand';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {NotificationBadge} from '@modules/Notifications/components/NotificationBadge';
import {useNotifications} from '@modules/Notifications/hooks/notifications';
import {_Notification} from '@modules/Notifications/util/notification.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {trans} from '@modules/Translations/util/i18n';

export const initNotifications: _Notification[] = [];

// required for the click outside helper
const buttonId = 'notificationsButton';

export const TopBarNotifications: React.FC<{profile: _Profile | null}> = ({profile}) => {
  const showNotificationsMenu = useUiStore(state => state.showNotificationsMenu);

  const {notifications, unreadNotifications, onSetNotificationRead} = useNotifications();

  // const unreadNotifications = initNotifications;
  // const notifications = initNotifications;

  return (
    <>
      <button
        type="button"
        id={buttonId}
        onClick={(): void => zustandApi.handleShowExclusiveUi({showNotificationsMenu: !showNotificationsMenu})}
      >
        <NotificationBadge value={unreadNotifications.length} />
      </button>

      {showNotificationsMenu ? (
        <NotificationDropDown onNotificationRead={onSetNotificationRead} notifications={notifications} />
      ) : null}
    </>
  );
};

interface _NotificationDropDown {
  notifications: _Notification[];
  onNotificationRead: (id?: string) => void;
}

const NotificationDropDown: React.FC<_NotificationDropDown> = ({onNotificationRead, notifications}) => {
  const {navigate} = useAppNavigate();

  const handleOpenNotificationsPage = (): void => {
    zustandApi.updateUiStore({showNotificationsMenu: false});
    // navigate(userMenuRoutes.notifications.to);
  };

  const handleClickOutside = (): void => zustandApi.updateUiStore({showNotificationsMenu: false});
  const ref = useClickOutOfBoundsHelper(handleClickOutside, buttonId);

  const showPlaceholder = !notifications?.length;
  return (
    <div
      ref={ref}
      className={twMerge(
        'absolute z-[10000] right-[0px] top-[70px] relative-col-width-4 rounded-sm shadow-2 bg-white500',
        showPlaceholder ? 'flex flex-row justify-center items-center p-3' : ''
      )}
    >
      {showPlaceholder ? (
        <NoNotificationsPlaceholder />
      ) : (
        <div className={twMerge('overflow-auto max-h-[360px] min-h-0')}>
          <div className="flex py-3 px-2 flex-col items-start gap-3 w-full">
            {/* topnav */}
            <div className="flex flex-row justify-between w-full">
              <div />
              {/*
            <TextLink label="View all" onClick={handleOpenNotificationsPage} />
            */}
              <TextLink
                label={trans('notifications.mark_all_as_read')}
                color="default"
                onClick={() => onNotificationRead(undefined)}
                // todo@Sherif: implement
                // onClick={() => zustandApi.updateUiStore({globalNotificationsLastRead: Date.now()})}
              />
            </div>

            <NotificationsArea notifications={notifications} />
          </div>
        </div>
      )}
    </div>
  );
};

interface _NotificationsArea {
  notifications: _Notification[];
}

const NotificationsArea: React.FC<_NotificationsArea> = ({notifications}) => {
  return (
    <div className="flex flex-col items-start gap-3 w-full">
      {notifications?.map((notification, i) => (
        <>
          {i > 0 && <Divider />}
          <NotificationItem key={notification.id} data={notification} />
        </>
      ))}
    </div>
  );
};

interface _NotificationProps {
  data: _Notification;
}

const NotificationItem: React.FC<_NotificationProps> = ({data}) => {
  const {navigate} = useAppNavigate();

  const {onSetNotificationRead} = useNotifications();

  // Content might have links or buttons, this catches them and stops the propagation
  function onContentClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void {
    if (!data) {
      return;
    }
    void onSetNotificationRead(data.id);
    const {tagName} = e.target.tagName;
    if (tagName === 'A' || tagName === 'BUTTON') {
      e.stopPropagation();
    }
  }

  function onClick(): void {
    if (!data) {
      return;
    }
    void onSetNotificationRead(data.id);
    let {link} = data;
    if (link) {
      if (link.startsWith('event:')) {
        const event = link.replace('event:', '');
        dispatchReactEvent(event);
      } else {
        if (!link.startsWith('/') && !link.includes('://')) {
          link = `/${link}`;
        }
        navigate(link);
      }
      zustandApi.handleShowExclusiveUi({showNotificationsMenu: false});
    }
  }

  if (!data) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'flex flex-col items-start p-1 gap-2 w-full rounded-sm hover:bg-grey100 cursor-pointer',
        !data.read_at ? 'bg-grey100' : ''
      )}
      onClick={onClick}
    >
      <span className="font-bold">{data.title}</span>
      <span onClick={onContentClick} dangerouslySetInnerHTML={{__html: data.message}} />
      <span className="self-end">
        {data.timePassed} {trans(`notifications.time_passed.${data.timePassedLangProperty}`)}
      </span>
    </div>
  );
};

const NoNotificationsPlaceholder: React.FC = () => {
  return (
    <Section
      imageHeight="h-[300px]"
      imageWidth="w-[300px]"
      imageViewDirection="vertical"
      justifyContent="center"
      titleVariant="h6"
      image="/assets/img/design_images/bell.png"
      title={trans('notifications.no_notifications.title')}
      description={trans('notifications.no_notifications.description')}
    />
  );
};
