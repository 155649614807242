// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {generate} from '@modules/Core/components/base/helper';
import {ServiceInformationCard} from '@modules/Core/components/platform/services/ServiceInformationCard';
import {SERVICE_PROFILE_BASE_AND_ANALYSIS, SERVICE_PROFILE_ROLES} from '@modules/Core/config/services';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {useProfile} from '@modules/Profile/hooks/profile';
import {getServiceImage, getSurveyFlow, isInitialProfileSurvey} from '@modules/Profile/util/profileUtil';
import {
  getUnfulfilledServicePreconditions,
  isServiceAvailable,
  isServiceConsented,
  isServiceReady,
} from '@modules/Profile/util/serviceUtil';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';

interface _ServiceInformationProps {
  service: string;
  labelNextButton?: string;
  onNextClicked?: () => void;
  isCoach?: boolean;
}

export const ProfileServiceInformation: React.FC<_ServiceInformationProps> = ({
  service,
  labelNextButton = null,
  onNextClicked = null,
  isCoach = false,
}) => {
  const {currentProfile} = useProfile();
  const {currentLanguage} = useLanguage();
  const navigateToFlow = useNavigateToFlow();
  // Service Data
  const [title, setTitle] = useState<string | null>('');
  const [content, setContent] = useState<string | null>('');
  const [time, setTime] = useState<string | null>('');
  const [image, setImage] = useState<string | null>('');
  const [actions, setActions] = useState<_UIAction[]>([]);

  const [showConsentDialog, setShowConsentDialog] = useState<boolean>(false);

  /**
   * Set service title, content, image & actions
   */
  useEffect(() => {
    if (!service) {
      return;
    }
    const newActions: _UIAction[] = [];
    const unfulfilledServicePreconditions = getUnfulfilledServicePreconditions(service);
    const legacyConsent = currentProfile?.legacy_consent?.includes('consent');

    setTitle(trans(`products.${service}.title`));
    setContent(trans(`products.${service}.content`));
    setImage(getServiceImage(service));

    // Ideally, this should come from a backend json config, for now, we do this

    // if (SOFTFACT_SERVICES.includes(service)) {
    setTime(trans(`products.${service}.time`, null, false));
    // }

    if (isInitialProfileSurvey(service)) {
      newActions.push({
        label: trans('products.initial_status_quo.start'),
        width: 'relative-col-width-3',
        onClick: () =>
          isServiceConsented('profile_base_and_analysis') ? onNextClicked?.() : setShowConsentDialog(true),
      });
    } else if (!isServiceAvailable(service)) {
      newActions.push({
        type: 'upgrade_button',
        source: 'profile',
      });
    } else if (!isServiceConsented(service)) {
      newActions.push({
        label: labelNextButton ?? trans('base.next'),
        type: 'button',
        onClick: legacyConsent ? handleConsent : () => setShowConsentDialog(true),
      });
    } else if (unfulfilledServicePreconditions?.length > 0 && service === SERVICE_PROFILE_ROLES) {
      const paStarted = isServiceReady('profile_base_and_analysis');
      newActions.push({
        label: paStarted
          ? trans('profile_result.roles_new.not_ready_pa_started.button')
          : trans('profile_result.roles_new.not_ready.button'),
        type: 'button',
        width: paStarted ? 'relative-col-width-3' : 'relative-col-width-2',
        onClick: () => navigateToFlow(getSurveyFlow(SERVICE_PROFILE_BASE_AND_ANALYSIS).name),
      });
      setContent(
        paStarted
          ? trans('profile_result.roles_new.not_ready_pa_started.description')
          : trans('profile_result.roles_new.not_ready.description')
      );
    } else {
      newActions.push({
        label: labelNextButton ?? trans('survey.lets_go'),
        type: 'button',
        onClick: () => onNextClicked?.(),
      });
    }

    // add uuids to actions
    newActions.forEach(action => {
      action.uuid = generate();
    });

    setActions(newActions);
  }, [service, currentProfile, currentLanguage]);

  async function handleConsent(): Promise<void> {
    try {
      const method = 'service.consent';
      const params = {
        profileId: currentProfile?.id,
        service,
      };

      const response = await performFlowAction({method, params});

      if (response?.data?.status === 'success') {
        setShowConsentDialog?.(false);
        dispatchReactEvent('navigate.start');
        onNextClicked?.();
      }
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
    }
  }

  return (
    <ServiceInformationCard
      showConsentDialog={showConsentDialog}
      setShowConsentDialog={setShowConsentDialog}
      isCoach={isCoach}
      onNextClicked={onNextClicked}
      service={service}
      title={title}
      content={content}
      time={time}
      image={image}
      actions={actions}
      imageViewDirection="horizontal"
      alignActions="start"
    />
  );
};
